import { Box, Dialog, DialogContent, DialogTitle, Divider, Link, Stack, Typography } from "@mui/material"
import { DBT_ANALYTICS_EXPOSURE_URL, DBT_STATSBOMB_EXPOSURE_URL, DBT_STS_EXPOSURE_URL, DBT_TABLEAU_EXPOSURE_URL, DBT_TM_EXPOSURE_URL } from "../constants";
import { Dispatch, SetStateAction } from "react";

import CloseButton from "../components/CloseButton";
import Iframe from "react-iframe";

interface ExposuresDialogProps {
    exposuresDialogOpen: boolean
    setExposuresDialogOpen: Dispatch<SetStateAction<boolean>>
}

export const ExposuresDialog = (props: ExposuresDialogProps) => {
    return (
        <Dialog
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullScreen
            open={props.exposuresDialogOpen}
            onClose={() => props.setExposuresDialogOpen(false)}
        >
        
            <CloseButton
                position="absolute"
                top={5}
                left={5}
                onClick={() => props.setExposuresDialogOpen(false)}
            />

            <DialogTitle textAlign="center">Data Freshness</DialogTitle>

            <Divider />

            <DialogContent id="alert-dialog-content">
                <Stack 
                    direction={{ xs: 'column' }}
                >
                    <Box>
                        <Link href="https://cloud.getdbt.com/deploy/19811/projects/203488/jobs/191502" target="_blank">
                            <Typography textAlign="center" variant="body1">
                                Analytics Data
                            </Typography>
                        </Link>
                        <Iframe
                            url={DBT_ANALYTICS_EXPOSURE_URL}
                            styles={{ border: "none", minHeight: "350px" }}
                            width="100%"
                            height="100%"
                            id="dbt_analytics_exposure"
                            className=""
                            display="block"
                            position="relative"
                            title="Analytics Data"
                        />
                    </Box>

                    <Box>
                        <Link href="https://cloud.getdbt.com/deploy/19811/projects/335219/jobs/498986" target="_blank">
                            <Typography textAlign="center" variant="body1">
                                Coaches Corner
                            </Typography>
                        </Link>
                        <Iframe
                            url={DBT_TABLEAU_EXPOSURE_URL}
                            styles={{ border: "none", minHeight: "350px" }}
                            width="100%"
                            height="100%"
                            id="coaches_corner_exposure"
                            className=""
                            display="block"
                            position="relative"
                            title="Coaches Corner"
                        />
                    </Box>

                    <Box>
                        <Link href="https://cloud.getdbt.com/deploy/19811/projects/203485/jobs/191499" target="_blank">
                            <Typography textAlign="center" variant="body1">
                                Statsbomb Data
                            </Typography>
                        </Link>                    
                        <Iframe
                            url={DBT_STATSBOMB_EXPOSURE_URL}
                            styles={{ border: "none", minHeight: "350px" }}
                            width="100%"
                            height="100%"
                            id="dbt_statsbomb_exposure"
                            className=""
                            display="block"
                            position="relative"
                            title="Statsbomb Data"
                        />
                    </Box>

                    <Box>
                        <Link href="https://cloud.getdbt.com/deploy/19811/projects/356923/jobs/548236" target="_blank">
                            <Typography textAlign="center" variant="body1">
                                STS Data
                            </Typography>
                        </Link>                    
                        <Iframe
                            url={DBT_STS_EXPOSURE_URL}
                            styles={{ border: "none", minHeight: "350px" }}
                            width="100%"
                            height="100%"
                            id="sts_exposure"
                            className=""
                            display="block"
                            position="relative"
                            title="STS Data"
                        />
                    </Box>

                    <Box>
                        <Link href="https://cloud.getdbt.com/deploy/19811/projects/358015/jobs/550621" target="_blank">
                            <Typography textAlign="center" variant="body1">
                                Transfermarkt Data
                            </Typography>
                        </Link>                    
                        <Iframe
                            url={DBT_TM_EXPOSURE_URL}
                            styles={{ border: "none", minHeight: "350px" }}
                            width="100%"
                            height="100%"
                            id="tm_exposure"
                            className=""
                            display="block"
                            position="relative"
                            title="Transfermarkt Data"
                        />
                    </Box>
                </Stack>
            </DialogContent>
        </Dialog>
    )
}
