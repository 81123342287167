import { Grid, Typography } from "@mui/material";

export interface EmptyStateOverlayProps {
  uniqueKeyPrefix: string;
  content?: string;
  width?: string;
  height?: string;
}

export default function EmptyStateOverlay(
  props: EmptyStateOverlayProps
): JSX.Element {
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      sx={{
        width: props.width ? props.width : "100%",
        height: props.height ? props.height : "100vh",
      }}
      key={`${props.uniqueKeyPrefix}-${props.content}-empty-state`}
    >
      <Typography
        alignItems="center"
        gutterBottom
        variant="h4"
        component="div"
        key={`${props.uniqueKeyPrefix}-${props.content}-empty-state-content`}
      >
        {props?.content}
      </Typography>
    </Grid>
  );
}
