import { Dispatch, SetStateAction, useState } from "react";

import { Box } from "@mui/material";
import Button from "@mui/material/Button";
import CloseButton from "./CloseButton";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import { useWindowSizeContext } from "../contexts/WindowSizeContext";

export type CreateDialogProps = {
  open: boolean;
  rows: number;
  title: string;
  label: string;
  setOpen: Dispatch<SetStateAction<boolean>>;
  yesAction: (value: string) => void;
};

export default function CreateDialog(props: CreateDialogProps) {
  const [value, setValue] = useState("");
  const { height } = useWindowSizeContext();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  // console.log(props);

  return (
    <Box
      key={"create-dialog-outer-box"}
      sx={{ zIndex: (theme) => theme.zIndex.modal + 2 }}
    >
      <Dialog
        open={props.open}
        key={`${props.title}-dialog`}
        sx={{ height: height * 0.75 }}
      >
        <CloseButton
          key={"create-dialog-box-close-btn"}
          position="absolute"
          top={5}
          left={5}
          onClick={() => props.setOpen(false)}
        />
        <DialogTitle
          key={`${props.title}-dialog-title`}
          justifyContent="center"
          alignItems="center"
          display="flex"
        >
          {props.title}
        </DialogTitle>

        <DialogContent key={`${props.title}-dialog-content`}>
          <Box
            key={`${props.title}-dialog-box`}
            sx={{
              width: 500,
              maxWidth: "100%",
            }}
          >
            <TextField
              key={`${props.title}-dialog-textfield`}
              margin="dense"
              id="value"
              label={props.label}
              type="text"
              fullWidth
              variant="standard"
              multiline
              rows={props.rows}
              value={value}
              onChange={handleChange}
            />
          </Box>
        </DialogContent>

        <DialogActions key={`${props.title}-dialog-actions`}>
          <Button
            key={`${props.title}-dialog-actions-button`}
            onClick={() => {
              setValue("");
              props.yesAction(value);
            }}
          >
            Create
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
