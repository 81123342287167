import { createContext, useContext, useState } from "react";

import { TotwWeek } from "../api/types";

const defaultState = {
  selectedWeek: {
    id: -1,
    club: "",
    team: "",
    group: "",
    label: "",
    date: new Date(),
    players: [],
  },
  setSelectedWeek: (week: TotwWeek) => {},
};

interface TotwContextInterface {
  selectedWeek: TotwWeek | undefined;
  setSelectedWeek: (week: TotwWeek) => void;
}

export const TotwContext = createContext<TotwContextInterface>(defaultState);

export const TotwProvider = ({children}: {children: React.ReactNode}) => {
  const [selectedWeek, setSelectedWeek] = useState<TotwWeek>();

  const value = { selectedWeek, setSelectedWeek };

  return <TotwContext.Provider value={value}>{children}</TotwContext.Provider>;
};

export const useTotwContext = () => useContext(TotwContext);
