import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
} from "@mui/material";
import { DateTimePicker, DateTimeValidationError, PickerChangeHandlerContext } from "@mui/x-date-pickers";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { SchedulingGame, User } from "../../../api/types";
import {
  useCreateSchedulingGameMutation,
  useUpdateSchedulingGameMutation,
} from "../../../api/mutations";

import { useConstantsContext } from "../../../contexts/ConstantsContext";
import { useSchedulingContext } from "../../../contexts/SchedulingContext";
import { useThemeContext } from "../../../contexts/CustomThemeContext";

export interface AddGameDialogProps {
  showMutateGameDialog: boolean;
  setShowMutateGameDialog: Dispatch<SetStateAction<boolean>>;
}

export default function AddGameDialog(props: AddGameDialogProps) {
  // CONTEXTS
  const { theme } = useThemeContext();
  const { schools, users } = useConstantsContext();
  const { selectedWeek, selectedGame, setSelectedGame } =
    useSchedulingContext();

  // STATES
  const [allSchools, setAllSchools] = useState<string[]>([]);
  const [allScouts, setAllScouts] = useState<User[]>([]);
  const [dateTime, setDateTime] = useState<Date | null>(null);
  const [homeTeam, setHomeTeam] = useState<string | null>(null);
  const [homeTeamRank, setHomeTeamRank] = useState<number | null>(null);
  const [awayTeam, setAwayTeam] = useState<string | null>(null);
  const [awayTeamRank, setAwayTeamRank] = useState<number | null>(null);
  const [scout, setScout] = useState<string>("");
  const [network, setNetwork] = useState<string>("");
  const [disabled, setDisabled] = useState<boolean>(true);

  // MUTATIONS
  const createSchedulingGame = useCreateSchedulingGameMutation();
  const updateSchedulingGame = useUpdateSchedulingGameMutation();

  // FUNCTIONS
  const closeDialog = () => {
    clearStates();
    props.setShowMutateGameDialog(false);
    setSelectedGame(null);
  };

  const clearStates = () => {
    setDateTime(null);
    setHomeTeam(null);
    setHomeTeamRank(null);
    setAwayTeam(null);
    setAwayTeamRank(null);
    setScout("");
    setNetwork("");
  };

  const handleDateTimeChange = (
    value: Date | null,
    context: PickerChangeHandlerContext<DateTimeValidationError>
  ) => {
    value && setDateTime(value);
  };

  const handleScoutChange = (event: SelectChangeEvent) => {
    if (event.target.value && event.target.value !== "") {
      let s = allScouts?.find((s: User) => {
        return s.email === event.target.value;
      });
      setScout(s?.email || event.target.value);
    }
  };

  const handleSubmit = () => {
    let gameObj: { [key: string]: any } = {
      date_time: dateTime,
      home_team: homeTeam,
      home_team_rank: homeTeamRank,
      away_team: awayTeam,
      away_team_rank: awayTeamRank,
      scout: scout,
      tv_network: network,
    };

    if (selectedWeek?.id) {
      addGameToSchedulingWeek(gameObj as SchedulingGame);
    } else {
      gameObj.id = selectedGame && selectedGame.id;
      updateGame(gameObj as SchedulingGame);
    }
    closeDialog();
  };

  const addGameToSchedulingWeek = (game: SchedulingGame) => {
    createSchedulingGame.mutate({
      weekId: selectedWeek?.id,
      game: game,
    });
  };

  const updateGame = (game: SchedulingGame) => {
    updateSchedulingGame.mutate(game);
  };

  // EFFECTS
  useEffect(() => {
    if (selectedGame) {
      setDateTime(selectedGame.date_time);
      setHomeTeam(selectedGame.home_team);
      setHomeTeamRank(selectedGame.home_team_rank);
      setAwayTeam(selectedGame.away_team);
      setAwayTeamRank(selectedGame.away_team_rank);
      setScout(selectedGame.scout);
      setNetwork(selectedGame.tv_network);
    } else {
      clearStates();
    }
  }, [selectedGame]);

  useEffect(() => {
    if (
      dateTime === null ||
      isNaN(+dateTime) ||
      homeTeam == null ||
      awayTeam == null ||
      scout === "" ||
      network === ""
    ) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [dateTime, homeTeam, awayTeam, scout, network]);

  useEffect(() => {
    if (schools) {
      setAllSchools(schools);
    }
  }, [schools]);

  useEffect(() => {
    if (users) {
      let scouts = users.filter((u) => {
        return (
          u.primary_team === "second_team" &&
          ["operations", "scouting"].includes(u.primary_role) &&
          u.roles.includes("scouting")
        );
      });

      setAllScouts(scouts);
    }
  }, [users]);

  return (
    <Dialog
      open={props.showMutateGameDialog}
      onClose={() => {
        closeDialog();
      }}
      fullWidth
    >
      <DialogTitle>{`New Game`}</DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <DateTimePicker
            sx={{ marginTop: 1 }}
            label={"Date and Time"}
            value={dateTime}
            onChange={handleDateTimeChange}
          />
          <Stack spacing={2} direction={"row"}>
            <Autocomplete
              sx={{
                width: 400,
              }}
              options={allSchools}
              onChange={(event, value) => {
                setHomeTeam(value);
              }}
              value={homeTeam}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                  }}
                  placeholder={!homeTeam ? "Select Home Team" : ""}
                />
              )}
            />
            <TextField
              InputProps={{ inputProps: { type: "number", min: 1 } }}
              label="Rank"
              value={homeTeamRank || ""}
              onChange={(event) => {
                setHomeTeamRank(
                  event.target.value ? Number(event.target.value) : null
                );
              }}
            />
          </Stack>
          <Stack spacing={2} direction={"row"}>
            <Autocomplete
              sx={{
                width: 400,
              }}
              options={allSchools}
              onChange={(event, value) => {
                setAwayTeam(value);
              }}
              value={awayTeam}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                  }}
                  placeholder={!awayTeam ? "Select Away Team" : ""}
                />
              )}
            />
            <TextField
              InputProps={{ inputProps: { type: "number", min: 1 } }}
              label="Rank"
              value={awayTeamRank || ""}
              onChange={(event) => {
                setAwayTeamRank(
                  event.target.value ? Number(event.target.value) : null
                );
              }}
            />
          </Stack>
          <FormControl
            sx={{ m: 2, zIndex: (theme) => theme.zIndex.drawer - 1 }}
            size="small"
          >
            <Select
              id="mutate-dialog-scout-select"
              value={scout}
              onChange={handleScoutChange}
              sx={{
                backgroundColor: "white",
              }}
            >
              <MenuItem
                key={"select-scout-no-scout"}
                value={"Watch on TV"}
                sx={{
                  fontStyle: "italic",
                  borderBottom: `2px solid ${theme.palette.primary.main}`,
                }}
              >
                Watch on TV
              </MenuItem>
              {allScouts.map((scout: User) => {
                return (
                  <MenuItem
                    key={`select-scout-${scout.email}`}
                    value={scout.email}
                  >
                    {scout.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <TextField
            label="TV Network"
            value={network}
            onChange={(event) => {
              setNetwork(event.target.value);
            }}
          />
        </Stack>
      </DialogContent>
      <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
        <Button onClick={closeDialog}>CANCEL</Button>
        <Button disabled={disabled} onClick={handleSubmit}>
          SUBMIT
        </Button>
      </DialogActions>
    </Dialog>
  );
}
