import { Dispatch, SetStateAction } from "react";
import { FIRST_TEAM, SECOND_TEAM, SUPERUSER } from "../constants";
import { ListItemIcon, Menu, MenuItem, Typography } from "@mui/material";

import LooksOneIcon from "@mui/icons-material/LooksOne";
import LooksTwoIcon from "@mui/icons-material/LooksTwo";
import { useTeamContext } from "../contexts/TeamContext";
import { useUserContext } from "../contexts/UserContext";

interface TeamMenuProps {
  teamMenuOpen: boolean;
  teamMenuAnchorEl: HTMLElement | null;
  setTeamMenuAnchorEl: Dispatch<SetStateAction<HTMLElement | null>>;
}

export default function TeamMenu(props: TeamMenuProps) {
  // CONTEXT
  const { teams } = useUserContext();
  const { setTeam } = useTeamContext();

  // FUNCTIONS
  const handleTeamMenuClose = () => {
    props.setTeamMenuAnchorEl(null);
  };

  return (
    <Menu
      anchorEl={props.teamMenuAnchorEl}
      id="team-menu"
      open={props.teamMenuOpen}
      onClose={handleTeamMenuClose}
      transformOrigin={{ horizontal: "left", vertical: "top" }}
      anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
    >
      {(teams.includes(SUPERUSER) || teams.includes(FIRST_TEAM)) && (
        <MenuItem
          key={FIRST_TEAM}
          value={FIRST_TEAM}
          onClick={() => {
            setTeam(FIRST_TEAM);
            handleTeamMenuClose();
          }}
        >
          <ListItemIcon>
            <LooksOneIcon fontSize="small" color="primary" />
          </ListItemIcon>
          <Typography variant="inherit">FIRST TEAM</Typography>
        </MenuItem>
      )}

      {(teams.includes(SUPERUSER) || teams.includes(SECOND_TEAM)) && (
        <MenuItem
          key={SECOND_TEAM}
          value={SECOND_TEAM}
          onClick={() => {
            setTeam(SECOND_TEAM);
            handleTeamMenuClose();
          }}
        >
          <ListItemIcon>
            <LooksTwoIcon fontSize="small" color="secondary" />
          </ListItemIcon>
          <Typography variant="inherit">SECOND TEAM</Typography>
        </MenuItem>
      )}

      {/* {(teams.includes(SUPERUSER) || teams.includes(ACADEMY_TEAM)) && (
        <MenuItem
          key={ACADEMY_TEAM}
          value={ACADEMY_TEAM}
          onClick={() => {
            setTeam(ACADEMY_TEAM);
          }}
        >
          <ListItemIcon>
            <FontDownloadIcon
              fontSize="small"
              color="info"
              sx={{ ml: "2px", width: 22, height: 22 }}
            />
          </ListItemIcon>
          <Typography variant="inherit">ACADEMY</Typography>
        </MenuItem>
      )} */}
    </Menu>
  );
}
