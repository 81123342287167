import { createContext, useContext, useEffect, useState } from "react";
import {
  useConstantCountries,
  useConstantSchools,
  useConstantUsers,
} from "../api/queries";

import { User } from "../api/types";

const defaultState = {
  schools: [],
  users: [],
  countries: [],
};

interface ConstantsContextInterface {
  schools: string[] | undefined;
  users: User[] | undefined;
  countries: string[] | undefined;
}

export const ConstantsContext =
  createContext<ConstantsContextInterface>(defaultState);

export const ConstantsProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [schools, setSchools] = useState<string[] | undefined>(undefined);
  const [users, setUsers] = useState<User[] | undefined>(undefined);
  const [countries, setCountries] = useState<string[] | undefined>(undefined);

  const allSchools = useConstantSchools().data;
  const allUsers = useConstantUsers().data;
  const allCountries = useConstantCountries().data;

  useEffect(() => {
    if (allSchools) {
      setSchools(allSchools);
    }
  }, [allSchools]);

  useEffect(() => {
    if (allUsers) {
      setUsers(allUsers);
    }
  }, [allUsers]);

  useEffect(() => {
    if (allCountries) {
      setCountries(allCountries);
    }
  }, [allCountries]);

  const value = { schools, users, countries };

  return (
    <ConstantsContext.Provider value={value}>
      {children}
    </ConstantsContext.Provider>
  );
};

export const useConstantsContext = () => useContext(ConstantsContext);
