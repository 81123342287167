import { ConnectDragSource, DragSourceMonitor, useDrag } from "react-dnd";
import { TreeItem as MuiTreeItem, TreeItemProps, treeItemClasses } from '@mui/x-tree-view/TreeItem';
import { alpha, styled } from "@mui/material";
import { forwardRef, useCallback, useRef } from 'react';

import { LIST } from "./ItemTypes";

const DraggableTreeItem = forwardRef<
    HTMLLIElement,
    TreeItemProps
>(({ ...props }, outerRef) => {
    const ref = useRef(null);
  

    const [{ isDragging }, drag] = useDrag({
      type: LIST,
      item: props.itemProp,
      collect: (monitor: DragSourceMonitor) => ({
        isDragging: monitor.isDragging()
      })
    });
  
    drag(ref);

    const dragRef = useCallback((elt: Element) => {
        elt?.addEventListener('focusin', (e) => {
          // Disable Treeview focus system which make draggable on TreeIten unusable
          // see https://github.com/mui-org/material-ui/issues/29518
        //   console.debug('stop propagation');
          e.stopImmediatePropagation();
        })
        drag(elt);
    }, [drag]);
  
    return (
      <MuiTreeItem
        ref={dragRef as ConnectDragSource}
        draggable={true}
        // onDragStart={() => { console.debug('drag start')}}
        // onDragEnd={() => { console.debug('drag end')}}
        style={{
          ...props.style,
          opacity: isDragging ? 0 : 1
        }}
        {...props}
      />
    );
});

export const TreeItem = styled(DraggableTreeItem)(({ theme }) => ({
  color: theme.palette.mode === 'light'
            ? theme.palette.grey[800]
            : theme.palette.grey[400],
  position: 'relative',
  [`& .${treeItemClasses.content}`]: {
      flexDirection: 'row-reverse',
      borderRadius: theme.spacing(0.7),
      marginBottom: theme.spacing(0.5),
      marginTop: theme.spacing(0.5),
      padding: theme.spacing(0.5),
      paddingLeft: theme.spacing(.7),
      paddingRight: theme.spacing(.7),
      fontWeight: 500,
    [`& .${treeItemClasses.label}`]: {
      fontWeight: 'inherit',
    },
    [`& .${treeItemClasses.iconContainer}`]: {
      marginRight: 0,
    },
    [`&.Mui-expanded `]: {
      '&:not(.Mui-focused, .Mui-selected, .Mui-selected.Mui-focused) .labelIcon': {
        color:
          theme.palette.mode === 'light'
            ? theme.palette.primary.main
            : theme.palette.secondary.dark,
      },
      '&::before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        left: '16px',
        top: '44px',
        height: 'calc(100% - 48px)',
        width: '1.5px',
        backgroundColor:
          theme.palette.mode === 'light'
            ? theme.palette.grey[300]
            : theme.palette.grey[700],
      },
    },
    '&:hover': {
      backgroundColor: alpha(theme.palette.secondary.main, 0.1),
      color: theme.palette.mode === 'light' ? theme.palette.secondary.main : 'white',
    },
    [`&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused`]: {
      backgroundColor:
        theme.palette.mode === 'light'
          ? theme.palette.info.main
          : theme.palette.secondary.dark,
      color: theme.palette.secondary.dark,
    },
  },
  [`& .${treeItemClasses.groupTransition}`]: {
    marginLeft: theme.spacing(2),
    [`& .${treeItemClasses.content}`]: {
      fontWeight: 500,
    },
  },
}));
