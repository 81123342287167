import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  LinearProgress,
  Link,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  MenuList,
  Tooltip as MuiTooltip,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { Player, ScoutedPlayerCreate } from "../../../api/types";
import { SEVERITY_INFO, useAlertContext } from "../../../contexts/AlertContext";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";

import ClearIcon from "@mui/icons-material/Clear";
import CloseButton from "../../../components/CloseButton";
import CreateScoutedPlayerDialog from "./CreateScoutedPlayerDialog";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import { useCreateScoutedPlayerMutation } from "../../../api/mutations";
import { usePlayerSearch } from "../../../api/queries";

export interface AddScoutedPlayerDialogProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  closeDialog: () => void;
  grouping: string;
}

export default function AddScoutedPlayerDialog(
  props: AddScoutedPlayerDialogProps
) {
  // CONTEXTS
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { setAlertOptions } = useAlertContext();

  // STATES
  const [searchForPlayer, setSearchForPlayer] = useState<boolean>(true);

  const [playerId, setPlayerId] = useState<number>();
  const [playerName, setPlayerName] = useState<string>("");
  const [disabled, setDisabled] = useState<boolean>(true);

  const [openCreateScoutedPlayerDialog, setOpenCreateScoutedPlayerDialog] =
    useState<boolean>(false);

  // QUERIES
  const searchPlayerQuery = usePlayerSearch(
    {
      query: playerName,
      by: "name",
    },
    3,
    1
  );

  // MUTATIONS
  const createScoutedPlayer = useCreateScoutedPlayerMutation();

  // FUNCTIONS
  const handleSubmit = () => {
    let obj = {
      player_id: playerId,
      grouping: props.grouping,
    };

    createScoutedPlayer.mutate(obj as ScoutedPlayerCreate);

    props.closeDialog();
    resetForm();
  };

  const resetForm = () => {
    setPlayerId(undefined);
    setPlayerName("");
    setSearchForPlayer(true);
  };

  const onChangePlayerName = (event: any) => {
    event.preventDefault();
    setPlayerId(undefined);
    setPlayerName(event.target.value);
  };

  const onPlayerSearchResultClick = useCallback((player: Player) => {
    setPlayerId(player.id);
    setPlayerName(player.name);
    setSearchForPlayer(false);
  }, []);

  // EFFECTS
  useEffect(() => {
    if (playerId) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [playerId]);

  return (
    <>
      <CreateScoutedPlayerDialog
        name={playerName}
        open={openCreateScoutedPlayerDialog}
        setOpen={setOpenCreateScoutedPlayerDialog}
        closeDialog={() => {
          setOpenCreateScoutedPlayerDialog(false);
          resetForm();
        }}
        grouping={props.grouping}
      />
      <Dialog
        open={props.open}
        fullScreen={fullScreen}
        fullWidth
        onClose={() => {
          props.setOpen(false);
          resetForm();
        }}
      >
        <DialogTitle
          key={"add-player-dialog-title"}
          sx={{ textAlign: "center" }}
        >
          ADD PLAYER
        </DialogTitle>
        <ValidatorForm onSubmit={() => handleSubmit()}>
          <DialogContent key={"add-player-dialog-content"}>
            <CloseButton
              position="absolute"
              top={5}
              left={5}
              onClick={() => {
                props.setOpen(false);
                resetForm();
              }}
            />

            {props.grouping && (
              <Grid container>
                <Grid xs={11} item>
                  <TextValidator
                    errorMessages={["this field is required"]}
                    id="add-player-dialog-player-name-select"
                    label="Player Name"
                    name="player_name"
                    validators={["required"]}
                    value={playerName}
                    variant="outlined"
                    fullWidth
                    onChange={onChangePlayerName}
                  />
                </Grid>

                <Grid xs={1} item>
                  <MuiTooltip title="Clear">
                    <IconButton
                      aria-label="cancel"
                      size="large"
                      onClick={() => {
                        resetForm();
                      }}
                    >
                      <ClearIcon fontSize="inherit" />
                    </IconButton>
                  </MuiTooltip>
                </Grid>
              </Grid>
            )}

            {(searchPlayerQuery.isFetching || searchPlayerQuery.isLoading) && (
              <LinearProgress color="secondary" />
            )}

            {props.grouping && searchPlayerQuery.isFetched && (
              <MenuList disablePadding dense sx={{ pr: 6 }}>
                {searchPlayerQuery.data?.data.map(
                  (player: Player, i: number) => {
                    if (player?.id) {
                      return (
                        <MenuItem
                          key={i}
                          dense
                          onClick={() => {
                            onPlayerSearchResultClick(player);
                          }}
                          selected={playerId === player.id}
                          sx={{ borderRadius: 2 }}
                        >
                          <ListItemAvatar>
                            <Avatar src={player.image} />
                          </ListItemAvatar>

                          <ListItemText
                            primary={
                              <Stack
                                direction="row"
                                display="flex"
                                alignItems="center"
                                justifyContent="space-between"
                              >
                                <Link
                                  noWrap={true}
                                  onClick={() => {
                                    if (player.tm_link) {
                                      window.open(
                                        player.tm_link,
                                        "_blank",
                                        "noopener,noreferrer"
                                      );
                                    } else {
                                      setAlertOptions({
                                        message: `${
                                          player.name
                                            ? player.name
                                            : "this player"
                                        } doesn't have a transfermakrt profile`,
                                        severity: SEVERITY_INFO,
                                      });
                                    }
                                  }}
                                >
                                  {player.name}
                                </Link>

                                {player.age}
                              </Stack>
                            }
                            secondary={
                              <Stack
                                direction="row"
                                display="flex"
                                alignItems="center"
                                justifyContent="space-between"
                              >
                                <Typography variant="caption">
                                  {player.team}
                                </Typography>
                                <Typography variant="caption">
                                  {player.nationality}
                                </Typography>
                              </Stack>
                            }
                          />
                        </MenuItem>
                      );
                    }

                    return null;
                  }
                )}

                {playerId === undefined && searchForPlayer && (
                  <MenuItem
                    dense
                    onClick={() => {
                      setOpenCreateScoutedPlayerDialog(true);
                      props.setOpen(false);
                    }}
                  >
                    <ListItemAvatar sx={{ ml: 1 }}>
                      <PersonAddAlt1Icon />
                    </ListItemAvatar>
                    <ListItemText primary="Create Scouted Player" />
                  </MenuItem>
                )}
                <Divider />
              </MenuList>
            )}
          </DialogContent>
          <DialogActions
            key={"add-player-dialog-actions"}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <Button
              variant="outlined"
              type="submit"
              key="add-player-dialog-button"
              disabled={disabled}
            >
              SUBMIT
            </Button>
          </DialogActions>
        </ValidatorForm>
      </Dialog>
    </>
  );
}
