import { Box, Checkbox, Grid, Stack, Tooltip, Typography } from "@mui/material";
import {
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { KitManagerAction, KitManagerPlayer } from "../../api/types";

import InfoIcon from "@mui/icons-material/Info";
import { useThemeContext } from "../../contexts/CustomThemeContext";

export interface CheckboxActionProps {
  action: KitManagerAction;
  setTotalCredits: Dispatch<SetStateAction<number>>;
  player: KitManagerPlayer | null;
  actions: KitManagerAction[];
  setActions: Dispatch<SetStateAction<KitManagerAction[]>>;
}

export default function CheckBoxAction(props: CheckboxActionProps) {
  // CONTEXTS
  const { theme } = useThemeContext();

  // STATES
  const [checked, setChecked] = useState(false);
  const [credits, setCredits] = useState(0);

  // USEEFFECT
  useEffect(() => {
    let currActions = [...props.actions];
    if (checked) {
      if (
        !currActions.some(
          (action: KitManagerAction) => action.action === props.action.action
        )
      ) {
        currActions?.push(props.action);
        props.setActions(currActions);
      }
    } else {
      currActions = currActions.filter(
        (action: KitManagerAction) => action.action !== props.action.action
      );
      props.setActions(currActions);
    }
  }, [checked, props]);

  return (
    <Grid
      container
      alignItems="center"
      sx={{ paddingTop: "2px", paddingBottom: "2px" }}
    >
      <Grid item xs={6}>
        <Stack direction="row" alignItems="center" spacing={2}>
          <Tooltip
            title={
              <Box>
                <Typography>{props.action.description}</Typography>
                <Typography>Credits: {props.action.credits}</Typography>
              </Box>
            }
            enterTouchDelay={0}
            placement="right"
            componentsProps={{
              tooltip: {
                sx: {
                  backgroundColor: theme.palette.primary.main,
                  maxWidth: "200px",
                },
              },
            }}
          >
            <InfoIcon />
          </Tooltip>
          <Typography variant="h6">{props.action.label}</Typography>
        </Stack>
      </Grid>
      <Grid item xs={4}>
        <Checkbox
          checked={checked}
          onChange={(event) => {
            let value = event.target.checked === true ? 1 : -1;
            setChecked(event.target.checked);
            setCredits(+event.target.checked * props.action.credits);
            props.setTotalCredits(
              (prev) => prev + value * props.action.credits
            );
          }}
        />
      </Grid>
      <Grid item xs={2}>
        <Typography sx={{ fontWeight: "bold" }}>{credits}</Typography>
      </Grid>
    </Grid>
  );
}
