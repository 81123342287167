export const AUTH = "/auth/";
export const FOLDERS = "/folders/";
export const LISTS = "/lists/";
export const NOTES = "/notes/";
export const NOTIFICATIONS = "/notifications/";
export const OPERATIONS = "/operations/dashboard";
export const PLAYERS = "/players/";
export const SCOUTED_PLAYER_ATTRIBUTES = "/scouted_player_attributes/";
export const SCHEDULING_GAMES = "/scheduling/games/";
export const SCHEDULING_WEEKS = "/scheduling/weeks/";
export const SCOUTED_PLAYERS = "/scouted_players/";
export const SCOUTING_REPORTS = "/scouting/reports/";
export const CONSTANTS = "/constants/";
export const SHARES = "/shares/";
export const TOTW_WEEKS = "/totw/weeks/";
export const USERS = "/users/";
export const VISAS = "/visas/";
export const WELCOME = "/welcome/";
