import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
} from "@mui/material";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { PlayerAttributes, ScoutedPlayer } from "../../../api/types";

import { useUpdateScoutedPlayerAttributesMutation } from "../../../api/mutations";

export interface MutateScoutedPlayerAttributesDialogProps {
  showMutateScoutedPlayerAttributesDialog: boolean;
  setShowMutateScoutedPlayerAttributesDialog: Dispatch<SetStateAction<boolean>>;
  scoutedPlayer: ScoutedPlayer;
}

export default function MutateScoutedPlayerAttributesDialog(
  props: MutateScoutedPlayerAttributesDialogProps
) {
  // MUTATIONS
  const updateScoutedPlayerAttributes =
    useUpdateScoutedPlayerAttributesMutation();

  // STATES
  const [comparison, setComparison] = useState<string>(
    props.scoutedPlayer.comparison
  );
  const [strengths, setStrengths] = useState<string>(
    props.scoutedPlayer.strengths
  );
  const [areasToImprove, setAreasToImprove] = useState<string>(
    props.scoutedPlayer.areas_to_improve
  );
  const [disabled, setDisabled] = useState<boolean>(true);

  // FUNCTIONS
  const closeDialog = () => {
    props.setShowMutateScoutedPlayerAttributesDialog(false);
  };

  const handleSubmit = () => {
    let attributeObj: { [key: string]: any } = {
      comparison: comparison,
      strengths: strengths,
      areas_to_improve: areasToImprove,
      scouted_player_id: props.scoutedPlayer.id,
      id: props.scoutedPlayer.attributes_id,
    };

    updateScoutedPlayerAttributes.mutate(attributeObj as PlayerAttributes);

    closeDialog();
  };

  // EFFECTS
  useEffect(() => {
    setComparison(props.scoutedPlayer.comparison);
    setStrengths(props.scoutedPlayer.strengths);
    setAreasToImprove(props.scoutedPlayer.areas_to_improve);
  }, [props.scoutedPlayer, props.showMutateScoutedPlayerAttributesDialog]);

  useEffect(() => {
    if (
      comparison !== props.scoutedPlayer.comparison ||
      strengths !== props.scoutedPlayer.strengths ||
      areasToImprove !== props.scoutedPlayer.areas_to_improve
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [
    comparison,
    strengths,
    areasToImprove,
    props.scoutedPlayer.comparison,
    props.scoutedPlayer.strengths,
    props.scoutedPlayer.areas_to_improve,
  ]);

  return (
    <Dialog
      open={props.showMutateScoutedPlayerAttributesDialog}
      onClose={closeDialog}
      fullWidth
    >
      <DialogTitle>Player Attributes</DialogTitle>
      <DialogContent>
        <Stack spacing={2} padding="8px">
          <TextField
            label="Comparison"
            value={comparison || ""}
            multiline
            onChange={(event) => {
              setComparison(event.target.value);
            }}
          />
          <TextField
            label="Strengths"
            value={strengths || ""}
            multiline
            onChange={(event) => {
              setStrengths(event.target.value);
            }}
          />
          <TextField
            label="Areas to Improve"
            value={areasToImprove || ""}
            multiline
            onChange={(event) => {
              setAreasToImprove(event.target.value);
            }}
          />
        </Stack>
      </DialogContent>
      <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
        <Button onClick={closeDialog}>CANCEL</Button>
        <Button disabled={disabled} onClick={handleSubmit}>
          SUBMIT
        </Button>
      </DialogActions>
    </Dialog>
  );
}
