import { AuthState, OktaAuth } from "@okta/okta-auth-js";

type APIConfig = {
  apiBasePath?: string;
  authState?: AuthState | null | undefined;
  oktaAuth?: OktaAuth | undefined;
  reactAppMode?: string;
};

const config = {
  apiBasePath: "https://localhost:8000/api",
  reactAppMode: "dev",
};

export type { APIConfig };
export default config;
