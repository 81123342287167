import "./App.css";

import CacheBuster, { useCacheBuster } from "react-cache-buster";
import CustomAppBar, {
  APPBAR_HEIGHT,
  APPBAR_HEIGHT_AS_NUM,
} from "./app_bar/Toolbar";
import { ErrorBoundary, Provider as RollbarProvider } from "@rollbar/react"; // <-- Provider imports 'rollbar' for us
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import { Suspense, useState } from "react";
import {
  WindowSizeProvider,
  useWindowSizeContext,
} from "./contexts/WindowSizeContext";
import { oktaConfig, rollbarConfig } from "./config";
import { useLocation, useNavigate } from "react-router-dom";

import { APIProvider } from "./contexts/APIContext";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { AlertProvider } from "./contexts/AlertContext";
import AppRoutes from "./AppRoutes";
import BirthdayDialog from "./components/GifDialog";
import { Box } from "@mui/material";
import { ClubProvider } from "./contexts/ClubContext";
import { ConstantsProvider } from "./contexts/ConstantsContext";
import CustomAlert from "./components/CustomAlert";
import { CustomThemeProvider } from "./contexts/CustomThemeContext";
import { DndProvider } from "react-dnd";
import EmptyStateOverlay from "./components/EmptyStateOverlay";
import { GroupProvider } from "./contexts/GroupContext";
import { HTML5Backend } from "react-dnd-html5-backend";
import Hotkeys from "react-hot-keys";
import { ListProvider } from "./contexts/ListContext";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { NotificationsProvider } from "./contexts/NotificationContext";
import { OktaAuthProvider } from "./contexts/OktaAuthContext";
import { SEARCH_ROUTE } from "./routes";
import { SchedulingProvider } from "./contexts/SchedulingContext";
import { ScoutingReportDialogProvider } from "./contexts/ScoutingReportDialogContext";
import { Security } from "@okta/okta-react";
import SignInPage from "./pages/signin";
import { TeamProvider } from "./contexts/TeamContext";
import { TotwProvider } from "./contexts/TotwContext";
import { UserProvider } from "./contexts/UserContext";
import { WebSocketProvider } from "./contexts/WebSocketContext";
import packageJson from "../package.json";

// console.log(process.env);
const oktaAuth = new OktaAuth(oktaConfig);

const App = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { checkCacheStatus } = useCacheBuster();
  const { height } = useWindowSizeContext();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [authRequiredDialogOpen, setAuthRequiredDialogOpen] =
    useState<boolean>(false);

  // Check to see if there's a new version when the window is refocused
  window.addEventListener("focus", checkCacheStatus);

  const triggerLogin = async () => {
    await oktaAuth.signInWithRedirect();
  };

  const restoreOriginalUri = async (
    _oktaAuth: OktaAuth,
    originalUri: string
  ) => {
    navigate(toRelativeUrl(originalUri || "/", location));
  };

  const customAuthHandler = async () => {
    const previousAuthState = oktaAuth.authStateManager.getPreviousAuthState();
    if (!previousAuthState || !previousAuthState.isAuthenticated) {
      // App initialization stage
      await triggerLogin();
    } else {
      // Ask the user to trigger the login process during token autoRenew process
      setAuthRequiredDialogOpen(true);
    }
  };

  const onKeyDown = (keyName: string, e: Event, handle: any) => {
    navigate(SEARCH_ROUTE);
  };

  return (
    <CacheBuster
      currentVersion={packageJson.version}
      isEnabled={true} // if false, the library is disabled.
      isVerboseMode={false} // if true, the library writes verbose logs to console.
      //   loadingComponent={< />} // if not pass, nothing appears at the time of new version check.
    >
      <RollbarProvider config={rollbarConfig}>
        <Security
          oktaAuth={oktaAuth}
          onAuthRequired={customAuthHandler}
          restoreOriginalUri={restoreOriginalUri}
        >
          <SignInPage triggerLogin={triggerLogin} />
          <OktaAuthProvider>
            <ErrorBoundary
              fallbackUI={({
                error,
                resetError,
              }: {
                error: Error | null;
                resetError: () => void;
              }): JSX.Element => {
                return (
                  <>
                    <CustomAlert
                      message={`An unexpected error occurred, try refreshing | ${error?.toString()}`}
                      severity="error"
                    />
                    <EmptyStateOverlay
                      uniqueKeyPrefix="unknown_error"
                      content="An unexpected error occurred. Try refreshing your page."
                    />
                  </>
                );
              }}
            >
              <APIProvider>
                <ConstantsProvider>
                  <DndProvider debugMode={true} backend={HTML5Backend}>
                    <UserProvider>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <WindowSizeProvider>
                          <WebSocketProvider>
                            <ClubProvider>
                              <TeamProvider>
                                <GroupProvider>
                                  <TotwProvider>
                                    <SchedulingProvider>
                                      <CustomThemeProvider>
                                        <Suspense
                                          fallback={
                                            <EmptyStateOverlay uniqueKeyPrefix="suspense" />
                                          }
                                        >
                                          <NotificationsProvider>
                                            <AlertProvider>
                                              <ScoutingReportDialogProvider>
                                                <ListProvider>
                                                  <Hotkeys
                                                    keyName="command+/"
                                                    onKeyDown={onKeyDown}
                                                  >
                                                    <CustomAppBar />
                                                    <Box
                                                      sx={{
                                                        margin: 0,
                                                        marginTop:
                                                          APPBAR_HEIGHT,
                                                        border: 0,
                                                        padding: 0,
                                                        maxHeight:
                                                          height -
                                                          APPBAR_HEIGHT_AS_NUM,
                                                      }}
                                                    >
                                                      <BirthdayDialog />
                                                      <AppRoutes />
                                                    </Box>
                                                  </Hotkeys>
                                                </ListProvider>
                                              </ScoutingReportDialogProvider>
                                            </AlertProvider>
                                          </NotificationsProvider>
                                        </Suspense>
                                      </CustomThemeProvider>
                                    </SchedulingProvider>
                                  </TotwProvider>
                                </GroupProvider>
                              </TeamProvider>
                            </ClubProvider>
                          </WebSocketProvider>
                        </WindowSizeProvider>
                      </LocalizationProvider>
                    </UserProvider>
                  </DndProvider>
                </ConstantsProvider>
              </APIProvider>
            </ErrorBoundary>
          </OktaAuthProvider>
        </Security>
      </RollbarProvider>
    </CacheBuster>
  );
};

export default App;
