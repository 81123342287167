import { Box, Typography } from "@mui/material";

import { GetRatingLabel } from "../pages/scouting/utils";
import { useGroupContext } from "../contexts/GroupContext";

export interface RatingProps {
    includeLabel?: boolean
    isProjected?: boolean
    team: string
    value: number
    textAlign: string
}

export default function Rating(props: RatingProps) {
    const {group} = useGroupContext();

    // const precision = props.team === ACADEMY_TEAM ? .1 : 1;
    // const max = props.team === ACADEMY_TEAM ? SCOUTING_ACADEMY_PLAYER_RATING_LABELS.length : SCOUTING_PLAYER_RATING_LABELS.length-1;
    // const rating = props.value ? props.value : -1;
 
    return (
      <Box sx={{ width: "50%" }} pb={1} margin='auto'>
        {props.includeLabel && 
          <Typography variant="subtitle2">
            {props.isProjected ? "PROJECTED" : "RATING"}
          </Typography>
        }

        {/* <MuiRating
          disabled={props.value === undefined}
          max={max}
          name="rating"
          precision={precision}
          readOnly
          size="small"
          value={rating}
        /> */}

        <Typography component="legend" variant="caption">
          {GetRatingLabel(props.team, props.value, group)}
        </Typography>
      </Box>
    );
}
