// CREATE
export const CREATE_FOLDER = "create_folder";
export const CREATE_KIT_MANAGER_ACTION_LOGS = "create_kit_manager_action_logs";
export const CREATE_LIST = "create_list";
export const CREATE_NOTE = "create_note";
export const CREATE_PLAYER = "create_player";
export const CREATE_SCOUTED_PLAYER = "create_scouted_player";
export const CREATE_SCOUTING_REPORT = "create_scouting_report";
export const CREATE_SCHEDULING_WEEK = "create_scheduling_week";
export const CREATE_SCHEDULING_GAME = "create_scheduling_game";
export const CREATE_SCHEDULING_WEEK_GAME = "create_scheduling_week_game";
export const CREATE_TOTW_WEEK = "create_totw_week";
export const CREATE_TOTW_PLAYER = "create_totw_player";
export const CREATE_VISA = "create_visa";
export const CREATE_WELCOME = "create_welcome";

// DELETE
export const DELETE_FOLDER = "delete_folder";
export const DELETE_LIST = "delete_list";
export const DELETE_SCOUTING_REPORT = "delete_scouting_report";
export const DELETE_TOTW_WEEK = "delete_totw_week";
export const DELETE_TOTW_PLAYER = "delete_totw_player";
export const DELETE_SCHEDULING_WEEK = "delete_scheduling_week";
export const DELETE_SCHEDULING_GAME = "delete_scheduling_game";
export const DELETE_KIT_MANAGER_ACTION_LOG = "delete_kit_manager_action_log";

// READ
export const GET_AUTH = "get_auth";
export const GET_CONSTANT_SCHOOLS = "get_constant_schools";
export const GET_CONSTANT_COUNTRIES = "get_constant_countries";
export const GET_FOLDERS = "get_folders";
export const GET_KIT_MANAGER_PLAYERS = "get_kit_manager_players";
export const GET_KIT_MANAGER_ACTION_LOGS = "get_kit_manager_action_logs";
export const GET_KIT_MANAGER_ACTIONS = "get_kit_manager_actions";
export const GET_LIST = "get_list";
export const GET_LISTS = "get_lists";
export const GET_NOTIFICATIONS = "get_notifications";
export const GET_OPERATIONS_DATA = "get_operations_data";
export const GET_PLAYER = "get_player";
export const GET_PLAYER_DATABASE_META = "get_player_database_meta";
export const GET_PLAYERS = "get_players";
export const GET_PLAYER_KPIS = "get_player_kpis";
export const GET_PLAYER_NOTES = "get_player_notes";
export const GET_PLAYER_SCOUTING_REPORTS = "get_player_scouting_reports";
export const GET_PLAYER_SEASON_STATS = "get_player_season_stats";
export const GET_PLAYER_WEEKLY_STATS = "get_player_weekly_stats";
export const GET_SCHEDULING_WEEKS = "get_scheduling_weeks";
export const GET_SCHEDULING_GAMES = "get_scheduling_games";
export const GET_SCOUTED_PLAYERS = "get_scouted_players";
export const GET_SCOUTED_PLAYER_SCOUTING_REPORTS =
  "get_scouted_player_scouting_reports";
export const GET_SCOUTING_REPORTS = "get_scouting_reports";
export const GET_SHARES = "get_shares";
export const GET_TABLE_METRICS = "get_table_metrics";
export const GET_TEAM_STATS = "get_team_stats";
export const GET_TOTW_WEEKS = "get_totw_weeks";
export const GET_USERS = "get_users";

// UPDATE
export const UPDATE_LIST = "update_list";
export const UPDATE_LIST_PLAYERS = "update_list_players";
export const UPDATE_PLAYER = "update_player";
export const UPDATE_FOLDER = "update_folder";
export const UPDATE_SCOUTED_PLAYER = "update_scouted_player";
export const UPDATE_SCOUTED_PLAYER_ATTRIBUTES =
  "update_scouted_player_attributes";
export const UPDATE_SCOUTING_REPORT = "update_scouting_report";

// SEARCH
export const IMAGE_SEARCH = "search_for_image";
export const PLAYER_SEARCH = "player_search";
export const SCOUTED_PLAYER_SEARCH = "scouted_player_search";
export const TEAM_SEARCH = "team_search";
