import { Folder, PlayerList } from "../api/types";

import { Box } from "@mui/material";
import FolderIcon from "./FolderIcon";
import FolderLabel from "./FolderLabel";
import FolderOpenTwoToneIcon from "@mui/icons-material/FolderOpenTwoTone";
import FolderSharedTwoToneIcon from "@mui/icons-material/FolderSharedTwoTone";
import NavList from "./NavList";
import { TreeItem } from "./TreeItem";

export default function FolderOfLists(props: { index: number; folder: Folder }) {

  const icon =
    props.folder.id === -1 ? (
      <FolderSharedTwoToneIcon
        key={`${props.folder.id}-${props.index}-folder-off`}
        fontSize="small"
        sx={{
            width: '1.5rem'
        }}
      />
    ) : (
      <FolderOpenTwoToneIcon
        key={`${props.folder.id}-${props.index}-folder-open-icon`}
        fontSize="small"
        sx={{
            width: '1.5rem'
        }}
      />
    );

  return (
    <TreeItem 
      itemId={`${props.index}-${props.folder.id}-folder-treeview`}
      label={
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Box
            component={() => <FolderIcon icon={icon} name={props.folder.name} count={props.folder.lists.length} />}
            color="inherit"
          />
          <FolderLabel index={props.index} folder={props.folder} />
        </Box>
      }
    >
      {props.folder?.lists?.map((list: PlayerList, index) => {
        return <NavList folder={props.folder} folderIndex={props.index} index={index} list={list} />;
      })}
    </TreeItem>
  );
}
