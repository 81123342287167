import { ListItemIcon, MenuItem, Typography } from "@mui/material"

interface ActionsMenuItemProps {
  uniqueKey: string
  icon?: JSX.Element
  text: string
  onClick: (event: any) => void
}

const ActionsMenuItem = (props: ActionsMenuItemProps ) => {
  return (
    <MenuItem
      dense
      key={`${props.uniqueKey}-menu-item`}
      onClick={props.onClick}
    >
      {props.icon && 
        <ListItemIcon
          sx={{ mr: 1 }}
          key={`${props.uniqueKey}-list-item`}
        >
          {props.icon}
        </ListItemIcon>
      }

      <Typography
        noWrap
        variant="caption"
        key={`${props.uniqueKey}-action-text`}
      >
        {props.text}
      </Typography>
    </MenuItem>
  )
}

export default ActionsMenuItem;
