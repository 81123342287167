import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";
import { Dispatch, SetStateAction, useState } from "react";
import {
  KitManagerAction,
  KitManagerActionLog,
  KitManagerPlayer,
} from "../../../api/types";

import UndoIcon from '@mui/icons-material/Undo';
import { useDeleteKitManagerActionLog } from "../../../api/mutations";
import { useThemeContext } from "../../../contexts/CustomThemeContext";

export interface PlayerDialogProps {
  showPlayerDialog: boolean;
  setShowPlayerDialog: Dispatch<SetStateAction<boolean>>;
  player: KitManagerPlayer | null;
  actionLogs: KitManagerActionLog[] | undefined;
  actions: KitManagerAction[] | undefined;
  setPlayer: Dispatch<SetStateAction<KitManagerPlayer | null>>;
}

export default function PlayerDialog(props: PlayerDialogProps) {
  // CONTEXTS
  const { theme } = useThemeContext();

  // MUTATIONS
  const deleteActionLog = useDeleteKitManagerActionLog();
  
  // STATES
  const [showUndoActionDialog, setShowUndoActionDialog] = useState(false);

  // FUNCTIONS
  const openUndoDialog = () => {
    setShowUndoActionDialog(true);
  }

  const closeUndoDialog = () => {
    setShowUndoActionDialog(false);
  }

  const undoJerseyClaim = () => {
    if (props.actionLogs) {
      deleteActionLog.mutate(props.actionLogs[0].id)
      closeUndoDialog()
      closeDialog()
    }
  }

  const closeDialog = () => {
    props.setShowPlayerDialog(false);
  };

  return (
    <>
    <Dialog open={showUndoActionDialog} onClose={closeUndoDialog}>
      <DialogTitle sx={{ textAlign: "center", color: theme.palette.primary.main }}>Are you sure you want to undo a jersey claim?</DialogTitle>
      <DialogActions>
        <Button onClick={closeUndoDialog}>Cancel</Button>
        <Button onClick={undoJerseyClaim}>Confirm</Button>
      </DialogActions>
    </Dialog>
    <Dialog
      open={props.showPlayerDialog}
      onClose={() => {
        closeDialog();
      }}
      fullWidth
    >
      <DialogTitle
        sx={{ textAlign: "center", color: theme.palette.primary.main }}
      >
        <Stack direction="row" justifyContent="space-evenly">
          <Typography sx={{ fontWeight: "bold", fontSize: "18px" }}>
            {props.player?.player_name}
          </Typography>
          { props.actionLogs && props.actionLogs.length > 0 &&
          <Box style={{position: "absolute", top: "12px", right: "12px", cursor: "pointer"}} onClick={openUndoDialog}>
            <UndoIcon/>
          </Box>
        }
        </Stack>
      </DialogTitle>
      <DialogContent sx={{ color: theme.palette.primary.main }}>
        <Stack direction="row" justifyContent="center">
          <Stack key={"goals"} sx={{ padding: "10px" }}>
            <Typography
              sx={{ fontSize: "14px", textDecoration: "underline" }}
            >
              Goals
            </Typography>
            <Stack direction={"row"} justifyContent="center">
              <Typography sx={{fontSize: "14px"}}>
                {props.player?.goals as string}
              </Typography>
              &nbsp;
              <Typography sx={{fontSize: "14px", fontWeight: "bold"}}>
                {"(" + props.player?.goals_credits + ")"}
              </Typography>
            </Stack>
          </Stack>
          <Stack key={"assists"} sx={{ padding: "10px" }}>
            <Typography
              sx={{ fontSize: "14px", textDecoration: "underline" }}
            >
              Assists
            </Typography>
            <Stack direction={"row"} justifyContent="center">
              <Typography sx={{fontSize: "14px"}}>
                {props.player?.assists as string}
              </Typography>
              &nbsp;
              <Typography sx={{fontSize: "14px", fontWeight: "bold"}}>
                {"(" + props.player?.assists_credits + ")"}
              </Typography>
            </Stack>
          </Stack>
          <Stack key={"clean_sheets"} sx={{ padding: "10px" }}>
            <Typography
              sx={{ fontSize: "14px", textDecoration: "underline" }}
            >
              Clean Sheets
            </Typography>
            <Stack direction={"row"} justifyContent="center">
              <Typography sx={{fontSize: "14px"}}>
                {props.player?.clean_sheets as string}
              </Typography>
              &nbsp;
              <Typography sx={{fontSize: "14px", fontWeight: "bold"}}>
                {"(" + props.player?.clean_sheets_credits + ")"}
              </Typography>
            </Stack>
          </Stack>
          <Stack key={"played_matches"} sx={{ padding: "10px" }}>
            <Typography
              sx={{ fontSize: "14px", textDecoration: "underline" }}
            >
              Matches
            </Typography>
            <Stack direction={"row"} justifyContent="center">
              <Typography sx={{fontSize: "14px"}}>
                {props.player?.played_matches as string}
              </Typography>
              &nbsp;
              <Typography sx={{fontSize: "14px", fontWeight: "bold"}}>
                {"(" + props.player?.played_matches_credits + ")"}
              </Typography>
            </Stack>
          </Stack>
          <Stack key={"stat_total"} sx={{ padding: "10px" }}>
            <Typography
              sx={{ fontSize: "14px"}}
            >
              Total
            </Typography>
            <Stack direction={"row"} justifyContent="center">
              <Typography sx={{fontSize: "14px", fontWeight: "bold"}}>
                {
                  Number(props.player?.goals_credits) + 
                  Number(props.player?.assists_credits) + 
                  Number(props.player?.clean_sheets_credits) +
                  Number(props.player?.played_matches_credits)
                }
              </Typography>
            </Stack>
          </Stack>
        </Stack>
        <Stack direction="row" justifyContent="center">
          {props.actions?.map((action) => {
            return (
              <Stack key={action.action} sx={{ padding: "10px" }}>
                <Typography
                  sx={{ fontSize: "14px", textDecoration: "underline" }}
                >
                  {action.label}
                </Typography>
                <Stack direction={"row"} justifyContent="center">
                <Typography sx={{fontSize: "14px"}}>
                  {props.player?.[action.column] as string}
                </Typography>
                &nbsp;
                <Typography sx={{fontSize: "14px", fontWeight: "bold"}}>
                  {"(" + Number(props.player?.[action.column]) * (action.credits) + ")"}
                  </Typography>
                </Stack>
              </Stack>
            )
          })}
        </Stack>
        <Stack direction="row" justifyContent="center" sx={{paddingTop: "16px", borderTop: `1px solid ${theme.palette.primary.main}`}}>
            <Typography sx={{fontSize: "16px"}}>
              Total Credits:
            </Typography>
            &nbsp;
            <Typography sx={{ fontWeight: "bold", fontSize: "16px" }}>
            {props.player?.total_credits}
          </Typography>
        </Stack>
        

        {/* {props.actionLogs && props.actionLogs?.length > 0 && (
          <Box
            sx={{
              border: "1px solid lightgrey",
              borderRadius: "4px",
              marginTop: "8px"
            }}
          >
            <Box sx={{ padding: "2px" }}>
              <Grid
                container
                sx={{
                  fontSize: "12px",
                  fontWeight: "bold",
                  paddingBottom: "4px",
                }}
              >
                <Grid item xs={4} sx={{ textAlign: "center" }}>
                  Date
                </Grid>
                <Grid item xs={4} sx={{ textAlign: "center" }}>
                  Action
                </Grid>
                <Grid item xs={3} sx={{ textAlign: "center" }}>
                  Action Worth
                </Grid>
              </Grid>
            </Box>

            <Box sx={{ maxHeight: "300px", overflow: "scroll" }}>
              {props.actionLogs
                ?.filter((log) => log.kit_manager_player_id === props.player?.id)
                .map((log, i) => {
                  return (
                    <>
                      <Grid
                        key={log.id}
                        container
                        sx={{
                          fontSize: "12px",
                          padding: "4px",
                          borderBottom:
                            props.actionLogs && i < props.actionLogs?.length - 1
                              ? "1px solid lightgrey"
                              : "",
                          alignItems: "center"
                        }}
                      >
                        <Grid item xs={4} sx={{ textAlign: "center"}}>
                          {log.created_at
                            ? formatDateToLocal(log.created_at)
                            : ""}
                        </Grid>
                        <Grid item xs={4} sx={{ textAlign: "center" }}>
                          {log.manager_action}
                        </Grid>
                        <Grid item xs={3} sx={{ textAlign: "center" }}>
                          {log.action_worth}
                        </Grid>
                        <Grid item xs={1}>
                          <Box>
                            <DeleteIcon sx={{height: "20px"}} />
                          </Box>
                        </Grid>
                      </Grid>
                    </>
                  );
                })}
            </Box>
          </Box>
        )} */}
      </DialogContent>
    </Dialog>
    </>
  );
}
