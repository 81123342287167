import {
  ArcElement,
  Chart as ChartJS,
  Legend,
  Tooltip,
} from "chart.js";
import { AxisConfig, DefaultizedPieValueType, LineChart, LineSeriesType, PieChart, pieArcLabelClasses } from '@mui/x-charts';
import {
  Box,
  Container,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { CHICAGO_FIRE, FIRST_TEAM, MLSPA_POSITIONS, SALARY_BY_POSITION_TYPE, SALARY_DP_CATEGORY, SALARY_SUPPLEMENTAL, SALARY_TAM_CATEGORY } from "../../constants";
import { ClubStats, Player } from "../../api/types";
import {
  DataGridPremium,
  DataGridPremiumProps,
  GridRowId,
  GridSortModel,
} from "@mui/x-data-grid-premium";
import { useCallback, useEffect, useState } from "react";

import { APPBAR_HEIGHT_AS_NUM } from "../../app_bar/Toolbar";
import { AxisValueFormatterContext } from "@mui/x-charts/models/axis";
import LeagueTableDashboard from "../../components/dashboards/LeagueTableDashboard";
import { OperationsRosterDataGridCols } from "../../common/DataGridColDefs";
import PlayerMetricLineChart from "../../components/PlayerMetricLineChart";
import Rankings from "./Rankings";
import annotationPlugin from "chartjs-plugin-annotation";
import { formatStringToLocalString } from "../../utils/dates";
import { useClubContext } from "../../contexts/ClubContext";
import { useOperationsData } from "../../api/queries";
import { useTeamContext } from "../../contexts/TeamContext";
import { useWindowSizeContext } from "../../contexts/WindowSizeContext";
import zoomPlugin from "chartjs-plugin-zoom";

ChartJS.register(ArcElement, Tooltip, Legend, annotationPlugin, zoomPlugin);

const OperationsPage = () => {
  // CONTEXT
  const theme = useTheme();
  const { club } = useClubContext();
  const { team } = useTeamContext();
  const { height } = useWindowSizeContext();
  const isScreenSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const isScreenMedium = useMediaQuery(theme.breakpoints.down("md"));

  const pieDims = isScreenMedium? isScreenSmall ? 450 : 550 : 850;

  // QUERIES
  let playerData = [];
  const operationsData = useOperationsData(club).data;

  if (operationsData?.player_data) {
    playerData = operationsData?.player_data;
  }

  // VARIABLES
  const PERIOD = 5;
  const PIE_BREAKDOWN_BY_POSITION = "by_position";
  const PIE_BREAKDOWN_BY_CATEGORY = "by_category";

  // STATE
  const [detailPanelExpandedRowIds, setDetailPanelExpandedRowIds] = useState<GridRowId[]>([]);
  const [pieBreakdown, setPieBreakdown] = useState<string>(PIE_BREAKDOWN_BY_CATEGORY);
  const [salaryByCategory, setSalaryByCategory] = useState<{ id: number, label: string; value: number; }[]>([]);
  const [salaryByPosition, setSalaryByPosition] = useState<SALARY_BY_POSITION_TYPE>(MLSPA_POSITIONS);
  const [totalCompensation, setTotalCompensation] = useState<number>(0);
  const [trendData, setTrendData] = useState<{ xAxis: AxisConfig[], datasets: LineSeriesType[]}>({ xAxis: [], datasets: [] });
  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: 'guaranteed_compensation',
      sort: 'desc',
    },
  ]);

  // EFFECTS
  useEffect(() => {
    if (club === CHICAGO_FIRE && operationsData) {
      //
      // TREND DATA CALCULATIONS
      //

      // calculate xG and xGA rolling averages
      let xAxis: { [key: string]: { date: Date, homeTeamName: string, awayTeamName: string, stadiumName: string } } = {};
      let xGSum = 0;
      let xGRolling: number[] = [];
      let xGASum = 0;
      let xGARolling: number[] = [];
      operationsData.stats.forEach((s: ClubStats, index, arr) => {
        let xGAvg;
        let xGAAvg;

        xGSum += s.xg;
        xGASum += s.xga;

        if (index >= PERIOD) {
          xGSum -= arr[index - PERIOD].xg;
          xGASum -= arr[index - PERIOD].xga;

          xGAvg = xGSum / PERIOD;
          xGAAvg = xGASum / PERIOD;
        } else {
          xGAvg = xGSum / (index + 1);
          xGAAvg = xGASum / (index + 1);
        }

        // console.debug(formatStringToLocalString(s.date));
        xAxis[formatStringToLocalString(s.date)] = { 
            date: new Date(s.date),
            homeTeamName: s.home_team_name,
            awayTeamName: s.away_team_name,
            stadiumName: s.stadium_name 
        };
        // console.debug(xAxis);
        xGRolling.push(Math.round(xGAvg * 100) / 100);
        xGARolling.push(Math.round(xGAAvg * 100) / 100);
      });

      setTrendData({
        xAxis: [
          {
            id: 'date',
            min: new Date("2024-02-24"),
            data: Object.keys(xAxis).map((k) => {
                return xAxis[k].date;
            }),
            scaleType: 'time',
            valueFormatter: (value: any, context: AxisValueFormatterContext) => {
                const dateAsString = formatStringToLocalString(value);
                // console.debug(dateAsString);
                const homeTeamName = xAxis[dateAsString]?.homeTeamName;
                const awayTeamName = xAxis[dateAsString]?.awayTeamName;
                const stadiumName = xAxis[dateAsString]?.stadiumName;
                return homeTeamName && awayTeamName ? `${dateAsString} | ${awayTeamName} vs. ${homeTeamName} @ ${stadiumName}` : dateAsString;
            }
          },
        ],
        datasets: [
          {
            label: `xG ${PERIOD} Game Rolling Average`,
            color: theme.palette.primary.main,
            data: xGRolling,
            showMark: false,
            type: "line",
            valueFormatter: (value) => (value == null ? 'NaN' : value.toString())
          },
          {
            label: `xGA ${PERIOD} Game Rolling Average`,
            color: theme.palette.secondary.main,
            data: xGARolling,
            showMark: false,
            type: "line",
            valueFormatter: (value) => (value == null ? 'NaN' : value.toString())
          },
        //   {
        //     label: `xG`,
        //     color: theme.palette.primary.dark,
        //     data: operationsData.stats.map((s) => {
        //       return Math.round(s.xg * 100) / 100;
        //     }),
        //     showMark: false,
        //     type: "line",
        //     valueFormatter: (value) => (value == null ? 'NaN' : value.toString())
        //   },
        //   {
        //     label: `xGA`,
        //     color: theme.palette.secondary.dark,
        //     data: operationsData.stats.map((s) => {
        //       return Math.round(s.xga * 100) / 100;
        //     }),
        //     showMark: false,
        //     type: "line",
        //     valueFormatter: (value) => (value == null ? 'NaN' : value.toString())
        //   },
        ],
      });

      // PLAYER CALCULATIONS
      let byPosition : SALARY_BY_POSITION_TYPE = MLSPA_POSITIONS;
      let byCategory = [
        { id: 0, label: 'DP', value: 0 },
        { id: 1, label: 'TAM', value: 0 },
        { id: 2, label: isScreenSmall ? 'SQD' : 'SQUAD', value: 0 },
        { id: 3, label: 'SUPP', value: 0 },
      ];

      let totalComp = 0;
      operationsData.player_data.forEach((player: Player, index) => {
        let compensation = Number(
          player.guaranteed_compensation?.slice(1).replace(/,/g, "")
        );

        if (compensation) {
          totalComp += compensation;
        }

        // set salary by position
        // console.debug(player.mls_position);

        byPosition[player.mls_position].value += compensation;

        if (compensation >= SALARY_DP_CATEGORY) {
            byCategory[0].value += compensation;
        } else if (compensation < SALARY_DP_CATEGORY && compensation >= SALARY_TAM_CATEGORY) {
            byCategory[1].value += compensation;
        } else if (compensation < SALARY_TAM_CATEGORY && compensation > SALARY_SUPPLEMENTAL) {
            byCategory[2].value += compensation;
        } else if (compensation <= SALARY_SUPPLEMENTAL) {
            byCategory[3].value += compensation;
        }
      });

      setTotalCompensation(totalComp);
      setSalaryByPosition(byPosition);
      setSalaryByCategory(byCategory);
    }
  }, [club, isScreenSmall, operationsData, team, theme.palette.primary.dark, theme.palette.primary.main, theme.palette.secondary.dark, theme.palette.secondary.main]);

  const divider = <Grid item xs={12} pt={4} pb={4}>
                    <Divider
                        sx={{
                          borderBottomWidth: 1,
                          borderBottomColor: theme.palette.primary.main,
                        }}
                    />
                  </Grid>

  // STATS SUBCOMPONENT
  const getDetailPanelContent: DataGridPremiumProps['getDetailPanelContent'] = (props: {row : Player}) => {
    // console.debug(props.row);

    return (
      <Container
        key={`player-metrics-${props.row.id}`}
        sx={{
          padding: 0,
          display: "flex",
          height: {
            xs: "100%",
            sm: "80%",
            md: "55%",
          },
          minHeight: {
            xs: `${height}px`,
            sm: "405px",
            md: "475px",
          },
        }}
      >
        <PlayerMetricLineChart player={props.row} />
      </Container>
    );
  }

  const getDetailPanelHeight: DataGridPremiumProps['getDetailPanelHeight'] = () => 550;
    
  const handleDetailPanelExpandedRowIdsChange = useCallback((newIds: GridRowId[]) => {
    setDetailPanelExpandedRowIds(
        newIds.length > 1 ? [newIds[newIds.length - 1]] : newIds,
    );
  }, []);


  const getArcLabel = (params: DefaultizedPieValueType) => {
    const percent = params.value / totalCompensation;
    return `${params.label} (${(percent * 100).toFixed(0)}%)`;
  };

  return (
    <Grid
      container
      sx={{ maxHeight: height - APPBAR_HEIGHT_AS_NUM, overflow: "auto", padding: isScreenSmall ? 0 : 8 }}
    >
        {team === FIRST_TEAM && 
          <>
            <Grid item sm={12} sx={{width: "100%", paddingTop: isScreenSmall ? 4 : 0 }}>
              <Rankings data={operationsData?.rank_data} /> 
            </Grid>

            {divider}
          </>
        }

        <Grid item md={12}>
          <LeagueTableDashboard />
        </Grid>

        {club === CHICAGO_FIRE && (
          <>
          {!isScreenSmall && 
            <>
              {divider}
              <LineChart
                xAxis={trendData?.xAxis}
                series={trendData?.datasets}
                width={isScreenMedium ? 800 : 1600}
                height={500}
                grid={{ vertical: true, horizontal: true }}
              />
            </>
          }

          {club === CHICAGO_FIRE &&
            <>
                {divider}

                <Grid 
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >

                <Grid 
                  item 
                  xs={12}
                  display="flex"
                  justifyContent="space-around"
                  alignItems="center"
                >
                  <Box alignContent='center' textAlign='center'>
                  <Typography
                    variant="h5"
                    sx={{ fontWeight: 400, textDecoration: "underline", marginBottom: 2 }}
                  >
                    SALARY INFO
                  </Typography>

                  <FormControl sx={{ mt: isScreenSmall ? 0 : 1, mb: isScreenSmall ? 0 : 4, minWidth: 175 }} size="small">
                    <InputLabel id="pie-chart-select-label">Show By</InputLabel>
                    <Select
                      labelId="pie-chart-select"
                      id="pie-chart-select"
                      value={pieBreakdown}
                      label="Show By"
                      onChange={(event) => setPieBreakdown(event?.target.value)}
                    >
                      <MenuItem value={PIE_BREAKDOWN_BY_CATEGORY}>Category</MenuItem>
                      <MenuItem value={PIE_BREAKDOWN_BY_POSITION}>Position</MenuItem>
                    </Select>
                  </FormControl>

                  <PieChart
                    height={isScreenSmall ? pieDims : pieDims - 100}
                    width={isScreenSmall ? pieDims - 50 : pieDims + 100}
                    series={
                    [
                        {
                            arcLabel: getArcLabel,
                            data: pieBreakdown === PIE_BREAKDOWN_BY_CATEGORY ? salaryByCategory : Object.keys(salaryByPosition).map(
                                (position) => {
                                    return salaryByPosition[position];
                                }
                            ),
                            highlightScope: { faded: 'global', highlighted: 'item' },
                            faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
                        },
                    ]
                    }
                    slotProps={{
                    legend: {
                        position: {
                        horizontal: "right",
                        vertical: "middle"
                        },
                        direction: "column"
                    }
                    }}
                    sx={{
                    [`& .${pieArcLabelClasses.root}`]: {
                        fill: 'white',
                        fontWeight: 'bold',
                    }
                    }}
                  />
                  </Box>
                </Grid>

                <Grid item xs={12} sx={{marginTop: isScreenSmall ? 4: 8}}>
                    <DataGridPremium
                    columns={OperationsRosterDataGridCols(playerData.length)}
                    detailPanelExpandedRowIds={detailPanelExpandedRowIds}
                    getDetailPanelContent={getDetailPanelContent}
                    getDetailPanelHeight={getDetailPanelHeight}
                    hideFooter
                    initialState={{
                        aggregation: {
                            model: {
                            age: 'avg',
                            guaranteed_compensation: 'sum'
                            },
                        },
                    }}
                    onDetailPanelExpandedRowIdsChange={handleDetailPanelExpandedRowIdsChange}
                    onSortModelChange={(model) => setSortModel(model)}
                    rows={playerData}
                    sortModel={sortModel}
                    />
                </Grid>
              </Grid>
            </>
          }
          </>
        )}
    </Grid>
  );
};

export default OperationsPage;
