import {
    Box,
    Link,
    List,
    ListItem,
    ListItemText,
    MenuItem,
    Typography,
} from "@mui/material";

import { DATABASE_ROUTE } from "../routes";
import { formatDateTimeWithoutSecondsToLocal } from "../utils/dates";
import { useNavigate } from "react-router-dom";
import { useNotificationsContext } from "../contexts/NotificationContext";

interface NotificationsProps {
  handleNotificationsClose?: (
    event: {},
    reason: "backdropClick" | "escapeKeyDown"
  ) => void | undefined;
}

export default function Notifications(props: NotificationsProps) {
  const { notifications } = useNotificationsContext();
  const navigate = useNavigate();

  return (
    <Box>
      {notifications.map((notification) => (
        <MenuItem
          key={`${notification.action_time}-${notification.content}`}
          onClick={(event) => {
            const search = notification.link.split("?").pop();

            if (props.handleNotificationsClose) {
              props.handleNotificationsClose(event, "backdropClick");
            }

            navigate({
              pathname: DATABASE_ROUTE,
              search: search,
            });
          }}
        >
          <ListItemText
            primary={<Link>{notification.content}</Link>}
            secondary={`${formatDateTimeWithoutSecondsToLocal(
              notification.action_time
            )}`}
            primaryTypographyProps={{
              variant: "caption",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
            secondaryTypographyProps={{
              variant: "caption",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
            style={{ whiteSpace: "pre-line" }}
          />
        </MenuItem>
      ))}

      {(!notifications || notifications?.length === 0) && (
        <List dense={true}>
          <ListItem>
            <Typography variant="h6">No notifications</Typography>
          </ListItem>
        </List>
      )}
    </Box>
  );
}
