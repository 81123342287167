import { Box, useMediaQuery, useTheme } from "@mui/material";
import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";

import { Line } from "react-chartjs-2";
import { Match } from "../../api/types";
import Selectors from "../Selectors";
import randomColor from "randomcolor";
import { useState } from "react";
import { useTeamMetrics } from "../../api/queries";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export default function TeamDashboard(props: { club: string }) {
  // CONTEXT
  const theme = useTheme();
  const isScreenSmall = useMediaQuery(theme.breakpoints.down("md"));

  // STATE
  const end = new Date();
  const begin = new Date(end);
  begin.setMonth(begin.getMonth() - 8);
  const [startDate, setStartDateValue] = useState<Date>(begin);
  const [endDate, setEndDateValue] = useState<Date>(end); // Default to today

  // QUERIES
  const teamStatsQuery = useTeamMetrics(startDate, endDate, props.club);

  // VARIABLES
  const TENSION = 0.5;
  const FONT_SIZE = isScreenSmall ? 16 : 18;

  const options = {
    responsive: true,
    elements: {
      point: {
        radius: 5,
        hoverRadius: 10,
      },
    },
    interaction: {
      mode: "x" as "x",
      intersect: false,
    },
    layout: {
      padding: {
        left: isScreenSmall ? 0 : 100,
      },
    },
    plugins: {
      legend: {
        position: "top" as const,
        labels: {
          font: {
            size: FONT_SIZE,
          },
        },
      },
      title: {
        display: true,
        text: `${props.club} Team Season Stats`,
        font: {
          size: FONT_SIZE,
        },
      },
      tooltip: {
        titleFont: {
          size: FONT_SIZE,
        },
        bodyFont: {
          size: FONT_SIZE,
        },
        footerFont: {
          size: FONT_SIZE,
        },
        callbacks: {
          beforeBody: function (tooltipItem: any) {
            if (teamStatsQuery.data) {
              return `\nOPPONENT: ${
                teamStatsQuery?.data[tooltipItem[0].dataIndex].team_opponent
              }\n`;
            }
          },
        },
      },
    },
  };

  const labels = teamStatsQuery?.data?.map(
    (value: Match, index: number, array: Match[]) => value.match_date
  );

  const data = {
    labels,
    datasets: [
      {
        hidden: true,
        label: "Counterpresures",
        data: teamStatsQuery?.data?.map(
          (value: Match, index: number, array: Match[]) =>
            value.counterpressures
        ),
        borderColor: randomColor(),
        backgroundColor: randomColor(),
        tension: TENSION,
      },
      {
        hidden: true,
        label: "Cumulative Points",
        data: teamStatsQuery?.data?.map(
          (value: Match, index: number, array: Match[]) =>
            value.cumulative_points
        ),
        borderColor: randomColor(),
        backgroundColor: randomColor(),
        tension: TENSION,
      },
      {
        hidden: true,
        label: "Deep Completions",
        data: teamStatsQuery?.data?.map(
          (value: Match, index: number, array: Match[]) =>
            value.deep_completions
        ),
        borderColor: randomColor(),
        backgroundColor: randomColor(),
        tension: TENSION,
      },
      {
        hidden: true,
        label: "Deep Progressions",
        data: teamStatsQuery?.data?.map(
          (value: Match, index: number, array: Match[]) =>
            value.deep_progressions
        ),
        borderColor: randomColor(),
        backgroundColor: randomColor(),
        tension: TENSION,
      },
      {
        hidden: true,
        label: "Fouls",
        data: teamStatsQuery?.data?.map(
          (value: Match, index: number, array: Match[]) =>
            value.deep_progressions
        ),
        borderColor: randomColor(),
        backgroundColor: randomColor(),
        tension: TENSION,
      },
      {
        hidden: true,
        label: "Goals For",
        data: teamStatsQuery?.data?.map(
          (value: Match, index: number, array: Match[]) => value.goals_for
        ),
        borderColor: randomColor(),
        backgroundColor: randomColor(),
        tension: TENSION,
      },
      {
        hidden: true,
        label: "Goals Against",
        data: teamStatsQuery?.data?.map(
          (value: Match, index: number, array: Match[]) => value.goals_against
        ),
        borderColor: randomColor(),
        backgroundColor: randomColor(),
        tension: TENSION,
      },
      {
        hidden: true,
        label: "Goal Diff",
        data: teamStatsQuery?.data?.map(
          (value: Match, index: number, array: Match[]) => value.goal_diff
        ),
        borderColor: randomColor(),
        backgroundColor: randomColor(),
        tension: TENSION,
      },
      {
        hidden: true,
        label: "Passes into Box",
        data: teamStatsQuery?.data?.map(
          (value: Match, index: number, array: Match[]) => value.passes_into_box
        ),
        borderColor: randomColor(),
        backgroundColor: randomColor(),
        tension: TENSION,
      },
      {
        hidden: true,
        label: "Pressures",
        data: teamStatsQuery?.data?.map(
          (value: Match, index: number, array: Match[]) => value.pressures
        ),
        borderColor: randomColor(),
        backgroundColor: randomColor(),
        tension: TENSION,
      },
      {
        hidden: true,
        label: "Pressure Regains",
        data: teamStatsQuery?.data?.map(
          (value: Match, index: number, array: Match[]) =>
            value.pressure_regains
        ),
        borderColor: randomColor(),
        backgroundColor: randomColor(),
        tension: TENSION,
      },
      {
        hidden: true,
        label: "Successful Crosses",
        data: teamStatsQuery?.data?.map(
          (value: Match, index: number, array: Match[]) =>
            value.successful_crosses
        ),
        borderColor: randomColor(),
        backgroundColor: randomColor(),
        tension: TENSION,
      },
      {
        hidden: true,
        label: "PS xG",
        data: teamStatsQuery?.data?.map(
          (value: Match, index: number, array: Match[]) => value.np_psxg
        ),
        borderColor: randomColor(),
        backgroundColor: randomColor(),
        tension: TENSION,
      },
      {
        hidden: false,
        label: "xG",
        showLine: true,
        data: teamStatsQuery?.data?.map(
          (value: Match, index: number, array: Match[]) => value.np_xg
        ),
        borderColor: randomColor({
          luminosity: "bright",
          hue: "green",
        }),
        backgroundColor: randomColor({
          luminosity: "bright",
          hue: "green",
        }),
        tension: TENSION,
      },
      {
        hidden: false,
        label: "xGA",
        data: teamStatsQuery?.data?.map(
          (value: Match, index: number, array: Match[]) => value.opponent_np_xg
        ),
        borderColor: randomColor({
          luminosity: "bright",
          hue: "red",
        }),
        backgroundColor: randomColor({
          luminosity: "bright",
          hue: "red",
        }),
        tension: TENSION,
      },
      {
        hidden: true,
        label: "xGD",
        data: teamStatsQuery?.data?.map(
          (value: Match, index: number, array: Match[]) => value.np_xgd
        ),
        borderColor: randomColor(),
        backgroundColor: randomColor(),
        tension: TENSION,
      },
    ],
  };

  return (
    <Box width="100%" ml="auto" mr="auto" mt="auto" mb="auto">
      <Line options={options} data={data} />
      <Selectors
        startDate={startDate}
        endDate={endDate}
        club={props.club}
        setStartDateValue={setStartDateValue}
        setEndDateValue={setEndDateValue}
      />
    </Box>
  );
}
