import { Chip, MenuList, Popper, useMediaQuery } from "@mui/material";
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import Draggable, { DraggableData, DraggableEvent } from "react-draggable";
import { ScoutedPlayer, ScoutingReport, TotwPlayer } from "../../api/types";

import ScoutingReportItem from "./ScoutingReportItem";
import { useThemeContext } from "../../contexts/CustomThemeContext";
import { useTotwContext } from "../../contexts/TotwContext";

const WHITE = "#fff";
const PLAYER_LIST_MAX_WIDTH = 250;

interface AllPlayerProps {
  grouping: string;
  position: string;
  topOffset: string;
  leftOffset: string;
  reports: ScoutingReport[] | null | undefined;
  editMode: boolean;
  setPosition: Dispatch<SetStateAction<string>>;
  setScoutedPlayerInContext: Dispatch<
    SetStateAction<ScoutedPlayer | null | undefined>
  >;
  setScoutedPlayerProfileModalOpen: Dispatch<SetStateAction<boolean>>;
}

export default function AllPlayer(props: AllPlayerProps) {
  // CONTEXTS
  const { theme } = useThemeContext();
  const isScreenSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const { selectedWeek } = useTotwContext();

  // STATE
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [showPlayers, setShowPlayers] = useState<boolean>(false);
  const [showPlayersLastState, setShowPlayersLastState] =
    useState<boolean>(false);
  const [lastX, setLastX] = useState<number>();
  const [lastY, setLastY] = useState<number>();

  // REFS
  const ref = useRef(null);

  // FUNCTIONS
  function compare(a: ScoutingReport, b: ScoutingReport) {
    if (a.rating < b.rating) {
      return 1;
    }

    if (a.rating > b.rating) {
      return -1;
    }

    if (a.rating === b.rating) {
      if (a.first_name < b.first_name) {
        return 1;
      }

      if (a.first_name > b.first_name) {
        return -1;
      }
    }

    return 0;
  }

  // EFFECTS
  useEffect(() => {
    setAnchorEl(ref.current);
    setShowPlayers(!isScreenSmall);
    setShowPlayersLastState(!isScreenSmall);
  }, [isScreenSmall, ref]);

  // VARIABLES
  const BORDER = `1px solid ${theme.palette.primary.main}`;

  const sortedAndfilteredPlayers =
    props.reports &&
    props.reports
      .filter((element, index, array) => {
        // if in totw view, filter out players if they are not associated with the current week
        if (props.editMode) {
          return selectedWeek?.players?.some((p: TotwPlayer) => {
            return p.scouting_report_id === element.id;
          });
        } else {
          return element;
        }
      })
      .filter((element, index, array) => {
        // We need to account for the off by one error here for position
        return (
          element.grouping === props.grouping &&
          Number(element.position) === Number(props.position)
        );
      })
      .sort(compare)
      .filter((element, index, array) => {
        return index < 100;
      });

  return (
    <>
      {!isScreenSmall && (
        <>
          <Draggable
            nodeRef={ref}
            onDrag={() => {
              setShowPlayers(false);
            }}
            onStart={(e: DraggableEvent, data: DraggableData) => {
              setShowPlayersLastState(showPlayers);
              setLastX(data.x);
              setLastY(data.y);
            }}
            onStop={(e: DraggableEvent, data: DraggableData) => {
              if (data.x !== lastX || data.y !== lastY) {
                setShowPlayers(showPlayersLastState);
                setShowPlayersLastState(showPlayers);
              } else {
                setShowPlayers(!showPlayers);
              }

              setLastX(data.x);
              setLastY(data.y);
            }}
          >
            <Chip
              color="primary"
              label={`All Number ${props.position}s`}
              ref={ref}
              sx={{
                cursor: "grab",
                position: "absolute",
                left: props.leftOffset,
                top: props.topOffset,
                marginLeft: 4,
              }}
            />
          </Draggable>
          <Popper
            id={`player-${props.position}-menu`}
            open={showPlayers}
            anchorEl={anchorEl}
            placement="bottom"
            modifiers={[
              {
                name: "preventOverflow",
                enabled: true,
                options: {
                  altAxis: true,
                  altBoundary: true,
                  tether: true,
                  rootBoundary: "document",
                  padding: 8,
                },
              },
            ]}
          >
            <MenuList
              //   dense
              disablePadding
              disableListWrap={true}
              sx={{
                backgroundColor: WHITE,
                border: BORDER,
                borderRadius: 1,
                maxWidth: PLAYER_LIST_MAX_WIDTH,
                marginTop: 1,
                overflow: "scroll",
              }}
            >
              {props.reports &&
                sortedAndfilteredPlayers &&
                sortedAndfilteredPlayers.map(
                  (report: ScoutingReport, index) => {
                    let playerInfo = `${report.first_name} ${report.last_name}`;

                    return (
                      <ScoutingReportItem
                        key={`${report?.id}-${report?.first_name}-${report?.last_name}`}
                        report={report}
                        grouping={props.grouping}
                        index={index}
                        sortedPlayersIndex={sortedAndfilteredPlayers.length}
                        isScreenSmall={isScreenSmall}
                        playerInfo={playerInfo}
                        editMode={props.editMode}
                        setScoutedPlayerInContext={
                          props.setScoutedPlayerInContext
                        }
                        setScoutedPlayerProfileModalOpen={
                          props.setScoutedPlayerProfileModalOpen
                        }
                      />
                    );
                  }
                )}
            </MenuList>
          </Popper>
        </>
      )}
    </>
  );
}
