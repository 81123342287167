import { Avatar, Typography } from "@mui/material";
import {
  EMPTY_FIELD,
  SALARY_DP_CATEGORY,
  SALARY_SUPPLEMENTAL,
  SALARY_TAM_CATEGORY,
  SECOND_TEAM,
} from "../constants";
import {
  EXTRA_EXTRA_LARGE_WIDTH,
  EXTRA_LARGE_WIDTH,
  LARGE_WIDTH,
  MEDIUM_WIDTH,
  SMALL_WIDTH,
  VERY_SMALL_WIDTH,
  withStringOperators,
  withoutStringOperators,
} from "../utils/dataGridHelpers";
import {
  GridActionsCellItem,
  GridAlignment,
  GridColDef,
  GridGroupingValueGetterParams,
  GridRenderCellParams,
  GridRowParams,
  GridValueFormatterParams,
  GridValueGetterParams,
} from "@mui/x-data-grid-premium";
import {
  formatDateToLocal,
  formatDateToLocalWithoutTimeYearFirst,
} from "../utils/dates";

import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { USDCurrencyFormatter } from "../utils/currency";
import { User } from "../api/types";
import moment from "moment";
import { useConstantsContext } from "../contexts/ConstantsContext";
import { useTeamContext } from "../contexts/TeamContext";
import { useThemeContext } from "../contexts/CustomThemeContext";

export const OperationsRosterDataGridCols = (
  numPlayers: number
): GridColDef[] => {
  let columns = [
    {
      field: "player_name",
      groupable: false,
      aggregable: false,
      headerAlign: "center" as GridAlignment,
      headerName: "PLAYER",
      type: "string",
      filterOperators: withStringOperators,
      flex: 1,
      minWidth: 200,
      align: "center" as GridAlignment,
    },
    {
      field: "mls_position",
      groupable: false,
      aggregable: false,
      headerAlign: "center" as GridAlignment,
      headerName: "POSITION",
      type: "string",
      filterOperators: withStringOperators,
      flex: 1,
      minWidth: 100,
      align: "center" as GridAlignment,
      valueFormatter: (params: GridValueFormatterParams<number>) => {
        return params?.value ? params.value : null;
      },
    },
    {
      field: "age",
      headerAlign: "center" as GridAlignment,
      headerName: "AGE",
      type: "number",
      filterOperators: withoutStringOperators,
      flex: 1,
      minWidth: 100,
      align: "center" as GridAlignment,
      valueFormatter: (params: GridValueFormatterParams<number>) => {
        return params?.value ? Math.round(params.value) : null;
      },
    },
    {
      field: "guaranteed_compensation",
      headerAlign: "center" as GridAlignment,
      headerName: "SALARY",
      type: "number",
      filterOperators: withoutStringOperators,
      flex: 1,
      minWidth: 150,
      align: "center" as GridAlignment,
      valueFormatter: (params: GridValueFormatterParams<number>) => {
        let category = "";
        const salary = Number(params.value);

        if (salary) {
          if (salary >= SALARY_DP_CATEGORY) {
            category = "DP";
          } else if (
            salary < SALARY_DP_CATEGORY &&
            salary >= SALARY_TAM_CATEGORY
          ) {
            category = "TAM";
          } else if (
            salary < SALARY_TAM_CATEGORY &&
            salary > SALARY_SUPPLEMENTAL
          ) {
            category = "SQUAD";
          } else if (salary <= SALARY_SUPPLEMENTAL) {
            category = "SUPP";
          } else {
            category = "RES";
          }

          return `${USDCurrencyFormatter.format(
            Math.ceil(salary / 10) * 10
          )} (${category})`;
        } else {
          return null;
        }
      },
      valueGetter: (params: GridValueGetterParams) => {
        if (params.value) {
          return Number(params.value.replace(/[^0-9.-]+/g, ""));
        } else {
          return 0;
        }
      },
    },
    {
      field: "obv_90_team_rank",
      groupable: false,
      aggregable: false,
      headerAlign: "center" as GridAlignment,
      headerName: "OBV (team rank)",
      type: "string",
      flex: 1,
      minWidth: 120,
      align: "center" as GridAlignment,
      renderCell: (params: GridRenderCellParams<any, number>) =>
        params?.value ? `${params.value} / ${numPlayers}` : null,
    },
  ];

  return columns;
};
export const GROUPING_COL = {
  field: "grouping",
  headerName: "GROUP",
  minWidth: EXTRA_EXTRA_LARGE_WIDTH,
  aggregable: true,
  filterOperators: withStringOperators,
  leafField: "player_name",
};

export const ScoutedPlayersDataGridCols = (): GridColDef[] => {
  const { team } = useTeamContext();

  let columns = [
    // {
    //   field: "rating",
    //   headerName: "RATING",
    //   minWidth: LARGE_WIDTH,
    //   width: LARGE_WIDTH,
    //   aggregable: true,
    //   type: "number",
    //   filterOperators: withoutStringOperators,
    //   valueFormatter: ratingFormatter,
    // },

    {
      field: "date",
      headerName: "DATE",
      minWidth: MEDIUM_WIDTH,
      type: "date",
      aggregable: true,
      valueFormatter: (params: GridValueFormatterParams<Date>) => {
        return params.value
          ? formatDateToLocalWithoutTimeYearFirst(params.value)
          : null;
      },
      valueGetter: (params: GridValueGetterParams) => {
        return params.value ? new Date(params.value) : null;
      },
    },
    GROUPING_COL,
    {
      field: "player_name",
      headerName: "PLAYER",
      minWidth: EXTRA_LARGE_WIDTH,
      aggregable: true,
      filterOperators: withStringOperators,
      leafField: "club",
      valueGetter: (params: GridValueGetterParams) => {
        return params.row["last_name"]
          ? `${params.row["first_name"]} ${params.row["last_name"]}`
          : null;
      },
      groupingValueGetter: (params: GridGroupingValueGetterParams) => {
        return params.row["last_name"]
          ? `${params.row["first_name"]} ${params.row["last_name"]}`
          : null;
      },
      renderCell: (params) => {
        return (
          <>
            <Avatar
              src={params.row.image}
              sx={{ width: 32, height: 32, marginRight: 1 }}
            />
            {params.value}
          </>
        );
      },
    } as GridColDef,
    {
      field: "first_name",
      headerName: "FIRST NAME",
      minWidth: MEDIUM_WIDTH,
      aggregable: true,
      filterOperators: withStringOperators,
    },
    {
      field: "last_name",
      headerName: "LAST NAME",
      minWidth: MEDIUM_WIDTH,
      aggregable: true,
      filterOperators: withStringOperators,
    },
    {
      field: "position",
      headerName: "POSITION",
      minWidth: EXTRA_LARGE_WIDTH,
      aggregable: true,
      filterOperators: withStringOperators,
    },
    {
      field: "team",
      headerName: "TEAM",
      minWidth: EXTRA_LARGE_WIDTH,
      aggregable: true,
      filterOperators: withStringOperators,
    },
    // {
    //   field: "position",
    //   headerName: "POSITION",
    //   minWidth: EXTRA_LARGE_WIDTH,
    //   width: EXTRA_LARGE_WIDTH,
    //   aggregable: true,
    //   filterOperators: withStringOperators,
    //   valueFormatter: (params: GridValueFormatterParams<number>) => {
    //     return SCOUTING_POSITIONS[params.value - 1];
    //   },
    // },
    {
      field: "domestic",
      headerName: "DOMESTIC",
      minWidth: MEDIUM_WIDTH,
      type: "boolean",
    },
    // {
    //   field: "secondary_position",
    //   headerName: "SECONDARY POSITION",
    //   minWidth: EXTRA_LARGE_WIDTH,
    //   width: EXTRA_LARGE_WIDTH,
    //   aggregable: true,
    //   filterOperators: withStringOperators,
    //   valueFormatter: (params: GridValueFormatterParams<number>) => {
    //     return SCOUTING_POSITIONS[params.value - 1];
    //   },
    // },
    {
      field: "birthdate",
      headerName: "BIRTHDATE",
      minWidth: MEDIUM_WIDTH,
      type: "date",
      aggregable: true,
      filterOperators: withoutStringOperators,
      valueGetter: (params: GridValueGetterParams) => {
        return params.value ? new Date(params.value) : "";
      },
      valueFormatter: (params: GridValueFormatterParams<Date>) => {
        return params.value
          ? formatDateToLocalWithoutTimeYearFirst(params.value)
          : "";
      },
    },
    {
      field: "birth_year",
      headerName: "BIRTH YEAR",
      minWidth: SMALL_WIDTH,
      aggregable: true,
      filterOperators: withStringOperators,
    },
    {
      field: "kit_number",
      headerName: "KIT #",
      minWidth: VERY_SMALL_WIDTH,
      width: VERY_SMALL_WIDTH,
      aggregable: true,
      type: "number",
      filterOperators: withoutStringOperators,
    },
    {
      field: "location",
      headerName: "LOCATION",
      minWidth: SMALL_WIDTH,
      // type: "string",
      aggregable: true,
      filterOperators: withStringOperators,
    },
    {
      field: "contact_info",
      headerName: "CONTACT",
      minWidth: LARGE_WIDTH,
      type: "string",
      aggregable: true,
      filterOperators: withStringOperators,
    },
    {
      field: "other_contact_info",
      headerName: "CONTACT (OTHER)",
      minWidth: LARGE_WIDTH,
      type: "string",
      aggregable: true,
      filterOperators: withStringOperators,
    },
    {
      field: "foot",
      headerName: "FOOT",
      minWidth: VERY_SMALL_WIDTH,
      aggregable: true,
      filterOperators: withStringOperators,
    },
    // {
    //   field: "scout",
    //   headerName: "SCOUT",
    //   minWidth: LARGE_WIDTH,
    //   aggregable: true,
    //   filterOperators: withStringOperators,
    // },
  ];

  if (team === SECOND_TEAM) {
    columns = columns.concat([
      {
        field: "year_in_school",
        headerName: "YR IN SCHOOL",
        minWidth: MEDIUM_WIDTH,
        aggregable: true,
        filterOperators: withStringOperators,
      },
    ]);
  }

  return columns;
};

export const SchedulingDataGridCols = (
  handleEditGame: (id: number) => void,
  handleDeleteGame: (id: number) => void,
  handleTeamClick: (team: string) => void
): GridColDef[] => {
  const { users } = useConstantsContext();
  const { theme } = useThemeContext();

  return [
    {
      field: "date_time",
      headerName: "DATE",
      minWidth: EXTRA_EXTRA_LARGE_WIDTH,
      type: "dateTime",
      aggregable: true,
      valueGetter: ({ value }) => value && new Date(formatDateToLocal(value)),
      valueFormatter: (params: GridValueFormatterParams<Date>) => {
        return params.value
          ? formatDateToLocal(params.value) +
              " " +
              moment.tz(moment.tz.guess()).zoneAbbr()
          : EMPTY_FIELD;
      },
    },
    {
      field: "teams",
      headerName: "TEAMS",
      minWidth: EXTRA_EXTRA_LARGE_WIDTH,
      type: "string",
      editable: true,
      renderCell: (params: GridRenderCellParams<any>) => {
        return (
          <>
            <div
              style={{ cursor: "pointer" }}
              onClick={() => handleTeamClick(params.row.away_team)}
            >
              <Typography sx={{ textDecoration: "underline" }}>
                {`${
                  params.row.away_team_rank
                    ? "#" + params.row.away_team_rank
                    : ""
                } ${params.row.away_team}`}
              </Typography>
            </div>
            <Typography sx={{ paddingLeft: 1, paddingRight: 1 }}>@</Typography>
            <div
              style={{ cursor: "pointer" }}
              onClick={() => handleTeamClick(params.row.home_team)}
            >
              <Typography sx={{ textDecoration: "underline" }}>
                {`${
                  params.row.home_team_rank
                    ? "#" + params.row.home_team_rank
                    : ""
                } ${params.row.home_team}`}
              </Typography>
            </div>
          </>
        );
      },
    },
    {
      field: "scout",
      headerName: "SCOUT",
      minWidth: EXTRA_EXTRA_LARGE_WIDTH,
      type: "string",
      aggregable: true,
      filterOperators: withStringOperators,
      valueFormatter: (params: GridValueFormatterParams<String>) => {
        let scout = users?.find((u: User) => {
          return u.email === params.value;
        });

        return scout ? scout.name : params.value;
      },
    },
    {
      field: "tv_network",
      headerName: "TV NETWORK",
      minWidth: MEDIUM_WIDTH,
      type: "string",
      aggregable: true,
      filterOperators: withStringOperators,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "",
      width: MEDIUM_WIDTH,
      getActions: (params: GridRowParams) => {
        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            onClick={() => handleEditGame(params.row.id)}
            sx={{ color: theme.palette.primary.main }}
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={() => handleDeleteGame(params.row.id)}
            sx={{ color: theme.palette.primary.main }}
          />,
        ];
      },
    },
  ];
};
