import { Stack, Typography, useTheme } from "@mui/material";

import { Folder } from "../api/types";
import { FolderActionMenu } from "./FolderActionMenu";
import { LIST } from "./ItemTypes";
import { useDrop } from "react-dnd";
import { useOktaAuthContext } from "../contexts/OktaAuthContext";

interface FolderLabelProps {
    index: number
    folder: Folder
}

export default function FolderLabel(props: FolderLabelProps) {
  // CONTEXT
  const theme = useTheme();
  const { email } = useOktaAuthContext();

  // console.debug(`${props.folder.id}:${props.folder.name}`);

  const [{ canDrop, isOver }, dropRef] = useDrop(() => ({
    accept: LIST,
    canDrop: () => (props.folder.id !== -1),
    drop: () => ({ id: props.folder.id as number }),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  }));

  const isActive = canDrop && isOver;

  // VARIABLES
  const showActionMenu = props.folder.id > 0 && props.folder.owner === email;
    return (
        <Stack
          key={`${props.folder.id}-${props.index}-folder-stack`}
          direction="row" 
          display="flex" 
          justifyContent='space-between' 
          pl={1}
          pr={1}
          sx={{
            backgroundColor: isActive ? theme.palette.info.main : 'transparent',
            width: '100%'
          }}
          ref={dropRef}
        >
            <Typography 
                key={`${props.folder.id}-${props.index}-folder-stack-typography`}
                variant='body2'
                noWrap
                mt='auto'
            >
                {props.folder.name}
            </Typography>
    
            {showActionMenu && <FolderActionMenu index={props.index} folder={props.folder} />}
        </Stack>
    );
}
