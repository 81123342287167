import {
  ACADEMY_AGE_GROUPINGS,
  ACADEMY_TEAM,
  SCOUTING_REPORT_GROUPINGS,
  SCOUTING_REPORT_GROUP_MLS,
  SCOUTING_REPORT_GROUP_MLS_NEXT_PRO,
  SCOUTING_REPORT_GROUP_NONE,
  SCOUTING_ROLE,
} from "../../constants";
import { Fab, SelectChangeEvent, Tooltip, useTheme } from "@mui/material";
import {
  SCOUTING_DEPTH_ROUTE,
  SCOUTING_ROOT_ROUTE,
  SCOUTING_SCHEDULING,
  SCOUTING_SHORTLIST_ROUTE,
  SCOUTING_TEAM_OF_THE_WEEK_ROUTE,
} from "../../routes";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import DepthChart from "./DepthChart";
// import { FieldIcon } from "../../components/CustomIcons";
import { GridFilterModel } from "@mui/x-data-grid-premium";
import GroupingSelect from "./GroupingSelect";
// import HomeIcon from "@mui/icons-material/Home";
import Landing from "./Landing";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
// import PersonIcon from "@mui/icons-material/Person";
import PitchShortList from "./PitchShortList";
import Scheduling from "./Scheduling";
import { ScoutedPlayer } from "../../api/types";
import ScoutedPlayerProfileDialog from "./Dialogs/ScoutedPlayerProfileDialog";
import ScoutingDatabase from "./ScoutingDatabase";
import TeamOfTheWeekChart from "./TeamOfTheWeekChart";
import { useGroupContext } from "../../contexts/GroupContext";
import { useScoutingReportDialogContext } from "../../contexts/ScoutingReportDialogContext";
import { useTeamContext } from "../../contexts/TeamContext";
import { useUserContext } from "../../contexts/UserContext";

const Scouting = () => {
  const theme = useTheme();
  const location = useLocation();
  const { handleCreateReportClick } = useScoutingReportDialogContext();
  const { team } = useTeamContext();
  const { group, setGroup } = useGroupContext();
  const { primaryRole } = useUserContext();
  const navigate = useNavigate();

  const [el, setEl] = useState<JSX.Element>();
  const [groupings, setGroupings] = useState<string[]>(
    team === ACADEMY_TEAM ? ACADEMY_AGE_GROUPINGS : SCOUTING_REPORT_GROUPINGS
  );
  // const [grouping, setGrouping] = useState<string>(group);
  const [landingOpen, setLandingOpen] = useState<boolean>(
    primaryRole === SCOUTING_ROLE
  );
  const [filterModel, setFilterModel] = useState<GridFilterModel | undefined>({
    items: [
      {
        id: "group-select",
        field: "grouping",
        operator: group ? "equals" : "isEmpty",
        value: group ? group : undefined,
      },
      {
        id: "player-name-search",
        field: "player_name",
        operator: "contains",
        value: "",
      },
    ],
    logicOperator: "and",
    quickFilterLogicOperator: "and",
    quickFilterValues: [],
  } as GridFilterModel);
  const [scoutedPlayerInContext, setScoutedPlayerInContext] = useState<
    ScoutedPlayer | null | undefined
  >();
  const [scoutedPlayerProfileModalOpen, setScoutedPlayerProfileModalOpen] =
    useState<boolean>(false);

  // EFFECTS
  useEffect(() => {
    setGroupings(
      team === ACADEMY_TEAM ? ACADEMY_AGE_GROUPINGS : SCOUTING_REPORT_GROUPINGS
    );
    setFilterModel({
      items: [
        {
          id: "group-select",
          field: "grouping",
          operator: group ? "equals" : "isEmpty",
          value: group ? group : undefined,
        },
        {
          id: "player-name-search",
          field: "player_name",
          operator: "contains",
          value: "",
        },
      ],
      logicOperator: "and",
      quickFilterLogicOperator: "and",
      quickFilterValues: [],
    } as GridFilterModel);
  }, [group, team]);

  useEffect(() => {
    const handleGroupChange = (event: SelectChangeEvent) => {
      setGroup(event.target.value);
      setFilterModel({
        items: [
          {
            id: "group-select",
            field: "grouping",
            operator: event.target.value ? "equals" : "isEmpty",
            value: event.target.value ? event.target.value : undefined,
          },
          {
            id: "player-name-search",
            field: "player_name",
            operator: "contains",
            value: "",
          },
        ],
        logicOperator: "and",
        quickFilterLogicOperator: "and",
        quickFilterValues: [],
      } as GridFilterModel);
      setLandingOpen(false);

      if (
        event.target.value === SCOUTING_REPORT_GROUP_MLS_NEXT_PRO ||
        event.target.value === SCOUTING_REPORT_GROUP_MLS
      ) {
        navigate(SCOUTING_DEPTH_ROUTE);
      } else {
        navigate(SCOUTING_ROOT_ROUTE);
      }

      // console.debug(filterModel);
    };
    const groupingSelectEl = (
      <GroupingSelect
        displayLabel={!landingOpen && group !== SCOUTING_REPORT_GROUP_NONE}
        groupings={groupings}
        landingOpen={landingOpen}
        handleGroupChange={handleGroupChange}
      />
    );

    // console.debug(location.pathname);

    if (landingOpen) {
      setEl(<Landing groupingSelectEl={groupingSelectEl} open={landingOpen} />);
    } else {
      let competition = "Major League Soccer";

      if (group === SCOUTING_REPORT_GROUP_MLS_NEXT_PRO) {
        competition = "MLS Next Pro";
      }

      switch (location.pathname) {
        case SCOUTING_DEPTH_ROUTE:
          setEl(<DepthChart competition={competition} />);
          break;
        case SCOUTING_SHORTLIST_ROUTE:
          setEl(
            <PitchShortList
              grouping={group}
              groupingSelectEl={groupingSelectEl}
              setScoutedPlayerInContext={setScoutedPlayerInContext}
              setScoutedPlayerProfileModalOpen={
                setScoutedPlayerProfileModalOpen
              }
            />
          );
          break;
        case SCOUTING_TEAM_OF_THE_WEEK_ROUTE:
          setEl(
            <TeamOfTheWeekChart
              grouping={group}
              groupingSelectEl={groupingSelectEl}
              setScoutedPlayerInContext={setScoutedPlayerInContext}
              setScoutedPlayerProfileModalOpen={
                setScoutedPlayerProfileModalOpen
              }
            />
          );
          break;
        case SCOUTING_SCHEDULING:
          setEl(<Scheduling />);
          break;
        default:
          setEl(
            <ScoutingDatabase
              filterModel={filterModel}
              setFilterModel={setFilterModel}
              scoutedPlayerInContext={scoutedPlayerInContext}
              setScoutedPlayerInContext={setScoutedPlayerInContext}
              setScoutedPlayerProfileModalOpen={
                setScoutedPlayerProfileModalOpen
              }
              groupingSelectEl={groupingSelectEl}
              grouping={group}
            />
          );
      }
    }
  }, [
    navigate,
    setGroup,
    group,
    groupings,
    location.pathname,
    landingOpen,
    filterModel,
    scoutedPlayerInContext,
  ]);

  return (
    <>
      {scoutedPlayerInContext && (
        <ScoutedPlayerProfileDialog
          handleClose={() => {
            setScoutedPlayerProfileModalOpen(false);
            setScoutedPlayerInContext(null);
          }}
          open={scoutedPlayerProfileModalOpen}
          scoutedPlayer={scoutedPlayerInContext}
          setScoutedPlayerInContext={setScoutedPlayerInContext}
        />
      )}

      {/* Dynamically render element */}
      {el}

      {location.pathname !== SCOUTING_SCHEDULING && (
        <Tooltip
          disableFocusListener
          key="create-scouting-report-tooltip"
          title="CREATE SCOUTING REPORT"
        >
          <Fab
            color="secondary"
            aria-label="edit"
            size="small"
            onClick={() => {
              handleCreateReportClick(undefined);
            }}
            sx={{
              color: theme.palette.primary.contrastText,
              bgcolor: theme.palette.primary.main,
              "&:hover": {
                bgcolor: theme.palette.primary.main,
              },
              position: "absolute",
              top: 75,
              right: 25,
            }}
          >
            <NoteAddIcon fontSize="small" />
          </Fab>
        </Tooltip>
      )}

      {/* <Tooltip
        disableFocusListener
        key="go-home-report-tooltip"
        title="CHOOSE GROUP"
      >
        <Fab
          color="secondary"
          aria-label="edit"
          size="small"
          onClick={() => {
            setGrouping(SCOUTING_REPORT_GROUP_NONE);
            setLandingOpen(true);
          }}
          sx={{
            color: theme.palette.primary.contrastText,
            bgcolor: theme.palette.primary.main,
            "&:hover": {
              bgcolor: theme.palette.primary.main,
            },
            position: "absolute",
            top: 75,
            right: 75,
          }}
        >
          <HomeIcon fontSize="small" />
        </Fab>
      </Tooltip> */}

      {/* <Tooltip disableFocusListener key="show-pitch-tooltip" title="PITCH VIEW">
        <Fab
          color="secondary"
          aria-label="edit"
          size="small"
          onClick={() => {
            navigate(SCOUTING_DEPTH_ROUTE);
          }}
          sx={{
            bgcolor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            "&:hover": {
              bgcolor: theme.palette.primary.main,
            },
            position: "absolute",
            right: 125,
            top: 75,
          }}
        >
          {FieldIcon()}
        </Fab>
      </Tooltip> */}

      {/* <Tooltip
        disableFocusListener
        key="show-repots-tooltip"
        title="PLAYERS VIEW"
      >
        <Fab
          color="secondary"
          aria-label="edit"
          size="small"
          onClick={() => {
            navigate(SCOUTING_REPORTS_ROUTE);
          }}
          sx={{
            bgcolor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            "&:hover": {
              bgcolor: theme.palette.primary.main,
            },
            position: "absolute",
            right: 175,
            top: 75,
          }}
        >
          <PersonIcon fontSize="small" />
        </Fab>
      </Tooltip> */}
    </>
  );
};

export default Scouting;
