import { Dispatch, SetStateAction } from "react";

import { Menu } from "@mui/material";
import Notifications from "./Notifications";

interface NotificationsMenuProps {
  notificationsMenuOpen: boolean;
  notificationsMenuAnchorEl: HTMLElement | null;
  setNotificationsMenuAnchorEl: Dispatch<SetStateAction<HTMLElement | null>>;
}

export default function NotificationsMenu(props: NotificationsMenuProps) {
  // FUNCTIONS
  const handleNotificationsClose = () => {
    props.setNotificationsMenuAnchorEl(null);
  };

  return (
    <Menu
      id="notifications-menu"
      MenuListProps={{
        "aria-labelledby": "long-button",
      }}
      anchorEl={props.notificationsMenuAnchorEl}
      open={props.notificationsMenuOpen}
      onClose={handleNotificationsClose}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
    >
      <Notifications handleNotificationsClose={handleNotificationsClose} />
    </Menu>
  );
}
