import {
  Box,
  FormControl,
  SelectChangeEvent,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { MenuItem, Select } from "@mui/material";
import { useScoutingReports, useTotwWeeks } from "../../api/queries";

import { APPBAR_HEIGHT_AS_NUM } from "../../app_bar/Toolbar";
import AddIcon from "@mui/icons-material/Add";
import AddWeekDialog from "./Dialogs/AddTotwWeekDialog";
import AllPlayer from "./AllPlayer";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteWeekDialog from "./Dialogs/DeleteWeekDialog";
import PitchPlayer from "./PitchPlayer";
import { SCOUTING_REPORT_GROUP_COLLEGE } from "../../constants";
import { ScoutedPlayer } from "../../api/types";
import { TotwWeek } from "../../api/types";
import { useClubContext } from "../../contexts/ClubContext";
import { useDeleteTotwWeekMutation } from "../../api/mutations";
import { useTeamContext } from "../../contexts/TeamContext";
import { useThemeContext } from "../../contexts/CustomThemeContext";
import { useTotwContext } from "../../contexts/TotwContext";

export const CREATE_ACTION = "create";
export const UPDATE_ACTION = "update";

const GREEN = "#4c7";
const LIGHT_GREEN = "#5d8";
const PLAYERS_PER_POSITION = 7;

interface PitchProps {
  editMode: boolean;
  grouping: string;
  groupingSelectEl: JSX.Element;
  setScoutedPlayerInContext: Dispatch<
    SetStateAction<ScoutedPlayer | null | undefined>
  >;
  setScoutedPlayerProfileModalOpen: Dispatch<SetStateAction<boolean>>;
}

export default function Pitch(props: PitchProps) {
  // CONTEXT
  const { club } = useClubContext();
  const { team } = useTeamContext();
  const { theme } = useThemeContext();
  const { selectedWeek, setSelectedWeek } = useTotwContext();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  // MUTATIONS
  const deleteTotwWeek = useDeleteTotwWeekMutation();

  // QUERIES
  const academyScoutingReports = useScoutingReports();
  const allTotwWeeks = useTotwWeeks().data;

  // PITCH DIMENSIONS
  // ASSUME PITCH IS 90 x 120 (width x height): so a ratio of 3 / 4

  const EFFECTIVE_PITCH_WIDTH = fullScreen ? "100vw" : "80vw";
  const EFFECTIVE_PITCH_HEIGHT = `calc(99vh - ${APPBAR_HEIGHT_AS_NUM}px)`;

  // BORDERS
  const BORDER_WIDTH = "4px";
  const BORDER = `${BORDER_WIDTH} solid white`;

  // SIX YARD BOX DIMENSIONS
  const SIX_YARD_BOX_HEIGHT_RATIO = "6 / 120";
  const SIX_YARD_BOX_WIDTH_RATIO = "20 / 90";
  const SIX_YARD_BOX_WIDTH = `calc((${SIX_YARD_BOX_WIDTH_RATIO}) * ${EFFECTIVE_PITCH_WIDTH})`;
  const SIX_YARD_BOX_HEIGHT = `calc((${SIX_YARD_BOX_HEIGHT_RATIO}) * ${EFFECTIVE_PITCH_HEIGHT})`;
  const SIX_YARD_BOX_OFFSET = `calc((${EFFECTIVE_PITCH_WIDTH} - ${SIX_YARD_BOX_WIDTH} - 8px) / 2)`;

  // EIGHTEEN YARD BOX
  const EIGHTTEN_HEIGHT_RATIO = "18 / 120";
  const EIGHTEEN_HEIGHT = `calc(${EIGHTTEN_HEIGHT_RATIO} * ${EFFECTIVE_PITCH_HEIGHT})`;
  const EIGHTEEN_WIDTH_RATIO = fullScreen ? "55 / 90" : "44 / 90";
  const EIGHTEEN_WIDTH = `calc(${EIGHTEEN_WIDTH_RATIO} * ${EFFECTIVE_PITCH_WIDTH})`;
  const EIGHTEEN_OFFSET = `calc((${EFFECTIVE_PITCH_WIDTH} - ${EIGHTEEN_WIDTH} - 8px) / 2)`;

  // HALF CIRCLES
  const HALF_CIRCLE_RADIUS_RATIO_START = fullScreen ? "15 / 120" : "17 / 120";
  const HALF_CIRCLE_RADIUS_RATIO_STOP = fullScreen
    ? "15.5 / 120"
    : "17.5 / 120";
  const HALF_CIRCLE_RADIUS_START = `calc(${HALF_CIRCLE_RADIUS_RATIO_START} * ${EFFECTIVE_PITCH_HEIGHT})`;
  const HALF_CIRCLE_RADIUS_STOP = `calc(${HALF_CIRCLE_RADIUS_RATIO_STOP} * ${EFFECTIVE_PITCH_HEIGHT})`;

  // VARIABLES
  const defaultWeek = useMemo(() => {
    return {
      id: -1,
      club: "",
      team: "",
      group: "",
      label: "",
      date: new Date(),
      players: [],
    };
  }, []);

  // STATE
  const [position, setPosition] = useState("9");
  const [currentTotwWeeks, setTotwWeeks] = useState<TotwWeek[]>([]);
  const [showAddTotwWeekDialog, setShowAddTotwWeekDialog] =
    useState<boolean>(false);
  const [showDeleteWeekDialog, setShowDeleteWeekDialog] =
    useState<boolean>(false);

  const handleWeekChange = (event: SelectChangeEvent) => {
    if (event.target.value !== "") {
      let week = currentTotwWeeks?.find((w: TotwWeek) => {
        return w.label === event.target.value;
      });
      setSelectedWeek(week || defaultWeek);
    } else {
      if (
        currentTotwWeeks === undefined ||
        currentTotwWeeks === null ||
        currentTotwWeeks.length === 0
      ) {
        setSelectedWeek(defaultWeek);
      }
    }
  };

  useEffect(() => {
    if (allTotwWeeks !== undefined && allTotwWeeks.length > 0) {
      let scopedWeeks = allTotwWeeks.filter((w) => {
        return w.group === props.grouping && w.team === team && w.club === club;
      });
      let updatedWeek = scopedWeeks.find((w) => {
        return w.id === selectedWeek?.id;
      });
      if (scopedWeeks.length > 0) {
        setTotwWeeks(
          scopedWeeks.sort((a, b) => a.date.getTime() - b.date.getTime())
        );
        setSelectedWeek(updatedWeek || scopedWeeks[0]);
      } else {
        setTotwWeeks([]);
        setSelectedWeek(defaultWeek);
      }
    } else {
      setTotwWeeks([]);
      setSelectedWeek(defaultWeek);
    }
  }, [
    allTotwWeeks,
    props.grouping,
    team,
    club,
    selectedWeek?.id,
    setSelectedWeek,
    defaultWeek,
  ]);

  return (
    // https://stackoverflow.com/questions/68295881/resize-soccer-pitch-to-fill-screen-react-css
    // PITCH
    <>
      <AddWeekDialog
        grouping={props.grouping}
        showAddTotwWeekDialog={showAddTotwWeekDialog}
        setShowAddTotwWeekDialog={setShowAddTotwWeekDialog}
      />
      <DeleteWeekDialog
        deleteWeekMutation={deleteTotwWeek}
        week={selectedWeek || defaultWeek}
        showDeleteWeekDialog={showDeleteWeekDialog}
        setShowDeleteWeekDialog={setShowDeleteWeekDialog}
      />
      <Box
        sx={{
          background: GREEN,
          position: "absolute",
          width: EFFECTIVE_PITCH_WIDTH,
          height: EFFECTIVE_PITCH_HEIGHT,
          border: "0.5vmin solid white",
          backgroundImage: `
                    radial-gradient(circle at 0% 0%, transparent 1%, white 0 1.5%, transparent 0),
                    radial-gradient(circle at 100% 0%, transparent 1%, white 0 1.5%, transparent 0), 
                    radial-gradient(circle at 0% 100%, transparent 1%, white 0 1.5%, transparent 0), 
                    radial-gradient(circle at 100% 100%, transparent 1%, white 0 1.5%, transparent 0), 
                    radial-gradient(circle at 50% 50%, white 1%, transparent 0), 
                    radial-gradient(circle at 50% 50%, transparent 14%, white 0 14.75%, transparent 0), 
                    radial-gradient(circle at 50% 5%, transparent ${HALF_CIRCLE_RADIUS_START}, white 0 ${HALF_CIRCLE_RADIUS_STOP}, transparent 0), 
                    radial-gradient(circle at 50% 95%, transparent ${HALF_CIRCLE_RADIUS_START}, white 0 ${HALF_CIRCLE_RADIUS_STOP}, transparent 0), 
                    linear-gradient(to top, transparent 49.75%, white 0 50.25%, transparent 0), 
                    repeating-linear-gradient(to top, ${GREEN} 0 10%, ${LIGHT_GREEN} 0 20%)
                `,
          paddingBottom: 0,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          {/* <Box ml={4} mt={2}>
            {props.groupingSelectEl}
          </Box> */}

          {props.editMode && (
            <Box ml={4} mt={2} display="flex" alignItems="center">
              <FormControl
                sx={{ zIndex: (theme) => theme.zIndex.drawer - 1 }}
                size="small"
              >
                <Select
                  id="totw-week-select"
                  value={selectedWeek?.label || defaultWeek.label}
                  onChange={handleWeekChange}
                  sx={{
                    backgroundColor: "white",
                    color: theme.palette.primary.main,
                  }}
                >
                  {/* eslint-disable-next-line array-callback-return */}
                  {currentTotwWeeks?.map((week: TotwWeek) => {
                    if (week.group === props.grouping) {
                      // there is a much cleaner way to do this but will need to explore handling
                      // string to date conversion and the timezone conversion that subsequently
                      // happens from the toString() method
                      let weekStr =
                        Number(week.date.getMonth() + 1) +
                        "/" +
                        Number(week.date.getDate());

                      return (
                        <MenuItem
                          key={`select-totw-week-${week.label}`}
                          value={week.label}
                        >
                          {week.label + " (" + weekStr + ")"}
                        </MenuItem>
                      );
                    }
                  })}
                  <MenuItem
                    key={`add-new-totw-week`}
                    sx={{
                      borderTop: currentTotwWeeks
                        ? `2px solid ${theme.palette.primary.main}`
                        : "",
                    }}
                    onClick={() => {
                      setShowAddTotwWeekDialog(true);
                    }}
                    value={""}
                  >
                    <Typography>Add new week</Typography>
                    <AddIcon
                      sx={{
                        color: theme.palette.primary.main,
                        height: 24,
                        width: 24,
                      }}
                    />
                  </MenuItem>
                </Select>
              </FormControl>
              {selectedWeek?.id !== -1 && (
                <div
                  style={{
                    cursor: "pointer",
                    marginLeft: 4,
                    zIndex: 1,
                  }}
                  onClick={() => setShowDeleteWeekDialog(true)}
                >
                  <DeleteIcon
                    sx={{
                      color: theme.palette.primary.main,
                      height: 28,
                      width: 28,
                    }}
                  />
                </div>
              )}
            </Box>
          )}
        </div>

        {/* Top 18 yard box */}
        <Box
          style={{
            background: GREEN,
            position: "absolute",
            top: "0%",
            left: EIGHTEEN_OFFSET,
            width: EIGHTEEN_WIDTH,
            height: EIGHTEEN_HEIGHT,
            border: BORDER,
            borderTop: "0",
          }}
        />
        {/* Top 6 yard box */}
        <Box
          style={{
            background: GREEN,
            position: "absolute",
            top: "0%",
            left: SIX_YARD_BOX_OFFSET,
            width: SIX_YARD_BOX_WIDTH,
            height: SIX_YARD_BOX_HEIGHT,
            border: BORDER,
            borderTop: "0",
          }}
        />
        {/* Bottom 18 yard box */}
        <Box
          style={{
            background: GREEN,
            position: "absolute",
            top: `calc(${EFFECTIVE_PITCH_HEIGHT} - ${EIGHTEEN_HEIGHT} - 4px)`,
            left: EIGHTEEN_OFFSET,
            width: EIGHTEEN_WIDTH,
            height: EIGHTEEN_HEIGHT,
            border: BORDER,
            borderBottom: "0",
          }}
        />
        {/* Bottom 6 yard box */}
        <Box
          style={{
            background: GREEN,
            position: "absolute",
            top: `calc(${EFFECTIVE_PITCH_HEIGHT} - ${SIX_YARD_BOX_HEIGHT} - 4px)`,
            left: SIX_YARD_BOX_OFFSET,
            width: SIX_YARD_BOX_WIDTH,
            height: SIX_YARD_BOX_HEIGHT,
            border: BORDER,
            borderBottom: "0",
          }}
        />
        {(!props.editMode || selectedWeek?.id !== -1) && (
          <>
            {/* Number 1 */}
            <PitchPlayer
              grouping={props.grouping}
              position={"1"}
              topOffset="70%"
              leftOffset={fullScreen ? "46%" : "48%"}
              reports={academyScoutingReports.data}
              playerPerPosition={PLAYERS_PER_POSITION - 1}
              setPosition={setPosition}
              showAddPlayerButtons={true}
              editMode={props.editMode}
              setScoutedPlayerInContext={props.setScoutedPlayerInContext}
              setScoutedPlayerProfileModalOpen={
                props.setScoutedPlayerProfileModalOpen
              }
            />
            {/* Number 2 */}
            <PitchPlayer
              grouping={props.grouping}
              position={"2"}
              topOffset="60%"
              leftOffset={fullScreen ? "86%" : "88%"}
              reports={academyScoutingReports.data}
              playerPerPosition={PLAYERS_PER_POSITION}
              setPosition={setPosition}
              showAddPlayerButtons={true}
              editMode={props.editMode}
              setScoutedPlayerInContext={props.setScoutedPlayerInContext}
              setScoutedPlayerProfileModalOpen={
                props.setScoutedPlayerProfileModalOpen
              }
            />
            {/* Number 3 */}
            <PitchPlayer
              grouping={props.grouping}
              position={"3"}
              topOffset="60%"
              leftOffset={fullScreen ? "6%" : "8%"}
              reports={academyScoutingReports.data}
              playerPerPosition={PLAYERS_PER_POSITION}
              setPosition={setPosition}
              showAddPlayerButtons={true}
              editMode={props.editMode}
              setScoutedPlayerInContext={props.setScoutedPlayerInContext}
              setScoutedPlayerProfileModalOpen={
                props.setScoutedPlayerProfileModalOpen
              }
            />
            {/* Number 4 */}
            <PitchPlayer
              grouping={props.grouping}
              position={"4"}
              topOffset="65%"
              leftOffset={fullScreen ? "66%" : "68%"}
              reports={academyScoutingReports.data}
              playerPerPosition={PLAYERS_PER_POSITION}
              setPosition={setPosition}
              showAddPlayerButtons={true}
              editMode={props.editMode}
              setScoutedPlayerInContext={props.setScoutedPlayerInContext}
              setScoutedPlayerProfileModalOpen={
                props.setScoutedPlayerProfileModalOpen
              }
            />
            {/* Number 5 */}
            <PitchPlayer
              grouping={props.grouping}
              position={"5"}
              topOffset="65%"
              leftOffset={fullScreen ? "26%" : "28%"}
              reports={academyScoutingReports.data}
              playerPerPosition={PLAYERS_PER_POSITION}
              setPosition={setPosition}
              showAddPlayerButtons={true}
              editMode={props.editMode}
              setScoutedPlayerInContext={props.setScoutedPlayerInContext}
              setScoutedPlayerProfileModalOpen={
                props.setScoutedPlayerProfileModalOpen
              }
            />
            {/* Number 6 */}
            <PitchPlayer
              grouping={props.grouping}
              position={"6"}
              topOffset="35%"
              leftOffset={fullScreen ? "46%" : "48%"}
              reports={academyScoutingReports.data}
              playerPerPosition={PLAYERS_PER_POSITION}
              setPosition={setPosition}
              showAddPlayerButtons={true}
              editMode={props.editMode}
              setScoutedPlayerInContext={props.setScoutedPlayerInContext}
              setScoutedPlayerProfileModalOpen={
                props.setScoutedPlayerProfileModalOpen
              }
            />
            {/* Number 7 */}
            <PitchPlayer
              grouping={props.grouping}
              position={"7"}
              topOffset="15%"
              leftOffset={fullScreen ? "86%" : "88%"}
              reports={academyScoutingReports.data}
              playerPerPosition={PLAYERS_PER_POSITION}
              setPosition={setPosition}
              showAddPlayerButtons={true}
              editMode={props.editMode}
              setScoutedPlayerInContext={props.setScoutedPlayerInContext}
              setScoutedPlayerProfileModalOpen={
                props.setScoutedPlayerProfileModalOpen
              }
            />
            {/* Number 8 */}
            <PitchPlayer
              grouping={props.grouping}
              position={"8"}
              topOffset="25%"
              leftOffset={fullScreen ? "66%" : "68%"}
              reports={academyScoutingReports.data}
              playerPerPosition={PLAYERS_PER_POSITION}
              setPosition={setPosition}
              showAddPlayerButtons={true}
              editMode={props.editMode}
              setScoutedPlayerInContext={props.setScoutedPlayerInContext}
              setScoutedPlayerProfileModalOpen={
                props.setScoutedPlayerProfileModalOpen
              }
            />
            {/* Number 9 */}
            <PitchPlayer
              grouping={props.grouping}
              position={"9"}
              topOffset="0%"
              leftOffset={fullScreen ? "46%" : "48%"}
              reports={academyScoutingReports.data}
              playerPerPosition={PLAYERS_PER_POSITION}
              setPosition={setPosition}
              showAddPlayerButtons={true}
              editMode={props.editMode}
              setScoutedPlayerInContext={props.setScoutedPlayerInContext}
              setScoutedPlayerProfileModalOpen={
                props.setScoutedPlayerProfileModalOpen
              }
            />
            {/* Number 10 */}
            <PitchPlayer
              grouping={props.grouping}
              position={"10"}
              topOffset="25%"
              leftOffset={fullScreen ? "26%" : "28%"}
              reports={academyScoutingReports.data}
              playerPerPosition={PLAYERS_PER_POSITION}
              setPosition={setPosition}
              showAddPlayerButtons={true}
              editMode={props.editMode}
              setScoutedPlayerInContext={props.setScoutedPlayerInContext}
              setScoutedPlayerProfileModalOpen={
                props.setScoutedPlayerProfileModalOpen
              }
            />
            {/* Number 11 */}
            <PitchPlayer
              grouping={props.grouping}
              position={"11"}
              topOffset="15%"
              leftOffset={fullScreen ? "6%" : "8%"}
              reports={academyScoutingReports.data}
              playerPerPosition={PLAYERS_PER_POSITION}
              setPosition={setPosition}
              showAddPlayerButtons={true}
              editMode={props.editMode}
              setScoutedPlayerInContext={props.setScoutedPlayerInContext}
              setScoutedPlayerProfileModalOpen={
                props.setScoutedPlayerProfileModalOpen
              }
            />
            {/* Complete List for Selected Position */}
            {!fullScreen &&
              props.grouping === SCOUTING_REPORT_GROUP_COLLEGE && (
                <AllPlayer
                  grouping={props.grouping}
                  position={position}
                  topOffset="10%"
                  leftOffset="-14%"
                  reports={academyScoutingReports.data}
                  setPosition={setPosition}
                  editMode={props.editMode}
                  setScoutedPlayerInContext={props.setScoutedPlayerInContext}
                  setScoutedPlayerProfileModalOpen={
                    props.setScoutedPlayerProfileModalOpen
                  }
                />
              )}
          </>
        )}
      </Box>
    </>
  );
}
