import { Box, Divider, Stack, Tooltip, Typography } from "@mui/material";

import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";

interface RankingSubsetProps {
  subset: string;
  ranking: number | undefined;
  plusMinus: number;
  icon: React.ReactNode | undefined;
}

export const RankingSubset = (props: RankingSubsetProps) => {
  
  return (
    <Stack 
      alignItems="center"
      direction="row"
      justifyContent="center"
      padding={1}
      spacing={1}
    >
        {props.icon && props.icon}

        <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
          {props.subset}
        </Typography>

        <Typography variant="h6" sx={{ fontWeight: 600 }}>
          {props.ranking ? props.ranking : <Tooltip title="could not fetch rank"><Typography>n/a</Typography></Tooltip>}
        </Typography>

        {props.plusMinus !== 0 && 
          <Divider flexItem orientation="vertical" />
        }

        {props.plusMinus !== 0 &&
          <Stack
            direction="row"
            alignItems="center"
            sx={{
              color: props.plusMinus > 0 ? "green" : props.plusMinus < 0 ? "red" : ""
            }}
          >
            <Typography variant="h6" sx={{ fontWeight: 600 }}>
              {props.plusMinus ? props.plusMinus : <Tooltip title="could not fetch rank"><Typography>n/a</Typography></Tooltip>}
            </Typography>

            <Box p={0} m={0}>
              {props.plusMinus > 0 
                ? <ArrowUpwardIcon fontSize="small" /> 
                : <ArrowDownwardIcon fontSize="small" />
              }
            </Box>
          </Stack>
        }
    </Stack>
  );
};
