import { GridOverlay } from "@mui/x-data-grid-premium";
import { Typography } from "@mui/material";
import { useWindowSizeContext } from "../../contexts/WindowSizeContext";

export default function GridEmptyOverlay(props: { content: string }) {
  const { width } = useWindowSizeContext();
  // console.debug(props.content);

  return (
    <GridOverlay sx={{ width: width }}>
      <Typography alignItems="center" gutterBottom variant="h4" component="div">
        {props.content}
      </Typography>
    </GridOverlay>
  );
}
