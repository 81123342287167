import { CHICAGO_FIRE, LUGANO, SUPERUSER } from "../constants";
import { Dispatch, SetStateAction } from "react";
import { ListItemIcon, Menu, MenuItem, Typography } from "@mui/material";

import { ReactComponent as CffcLogo } from "../assets/logos/cffc.svg";
import { ReactComponent as LuganoLogo } from "../assets/logos/lugano.svg";
import { useClubContext } from "../contexts/ClubContext";
import { useUserContext } from "../contexts/UserContext";

interface ClubMenuProps {
  clubMenuOpen: boolean;
  clubMenuAnchorEl: HTMLElement | null;
  setClubMenuAnchorEl: Dispatch<SetStateAction<HTMLElement | null>>;
}

export default function ClubMenu(props: ClubMenuProps) {
  // CONTEXT
  const { setClub } = useClubContext();
  const { clubs } = useUserContext();

  // FUNCTIONS
  const handleClubMenuClose = () => {
    props.setClubMenuAnchorEl(null);
  };

  return (
    <Menu
      id="club-menu"
      anchorEl={props.clubMenuAnchorEl}
      open={props.clubMenuOpen}
      onClose={handleClubMenuClose}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
    >
      {(clubs.includes(SUPERUSER) || clubs.includes(CHICAGO_FIRE)) && (
        <MenuItem onClick={() => setClub(CHICAGO_FIRE)}>
          <ListItemIcon>
            <CffcLogo style={{ width: "2rem", height: "2rem" }} />
          </ListItemIcon>
          <Typography variant="button">{CHICAGO_FIRE}</Typography>
        </MenuItem>
      )}

      {(clubs.includes(SUPERUSER) || clubs.includes(LUGANO)) && (
        <MenuItem onClick={() => setClub(LUGANO)}>
          <ListItemIcon>
            <LuganoLogo style={{ width: "2rem", height: "2rem" }} />
          </ListItemIcon>
          <Typography variant="button">{LUGANO}</Typography>
        </MenuItem>
      )}
    </Menu>
  );
}
