import { Badge } from "@mui/material";

export interface NavFolderIconProps {
  name: string
  count: number
  icon: JSX.Element
}

const FolderIcon = (props: NavFolderIconProps) => {
  return (
    <Badge
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      badgeContent={props.count}
      color="default"
      showZero
    >
      {props.icon}
    </Badge>
  )
}

export default FolderIcon;
