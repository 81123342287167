import {
  ACADEMY_AGE_GROUPINGS,
  ACADEMY_TEAM,
  EMPTY_FIELD,
  SCOUTING_REPORT_GROUPINGS,
} from "../../constants";
import {
  Box,
  Card,
  CardMedia,
  Grid,
  IconButton,
  Link,
  Tooltip as MuiTooltip,
  SelectChangeEvent,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { SEVERITY_INFO, useAlertContext } from "../../contexts/AlertContext";

import EditIcon from "@mui/icons-material/Edit";
import GroupingSelect from "./GroupingSelect";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import MutateScoutedPlayerAttributesDialog from "./Dialogs/MutateScoutedPlayerAttributesDialog";
import { ScoutedPlayer } from "../../api/types";
import ScoutedPlayerProfileActionsMenu from "./ScoutedPlayerProfileActionsMenu";
import ScoutingReportsTable from "./ScoutingReportsTable";
import default_player from "../../assets/default_player.jpeg";
import { formatDateToLocalWithoutTimeForLineChart } from "../../utils/dates";
import { getUniqueKeyScoutedPlayer } from "../../utils/uniqueKey";
import { useTeamContext } from "../../contexts/TeamContext";
import { useThemeContext } from "../../contexts/CustomThemeContext";
import { useUpdateScoutedPlayerMutation } from "../../api/mutations";

interface ScoutedPlayerProfileProps {
  scoutedPlayer: ScoutedPlayer;
  setScoutedPlayerInContext?: Dispatch<
    SetStateAction<ScoutedPlayer | null | undefined>
  >;
}

export default function ScoutedPlayerProfile(props: ScoutedPlayerProfileProps) {
  // CONTEXT
  const { setAlertOptions } = useAlertContext();
  const { theme } = useThemeContext();
  const { team } = useTeamContext();

  // STATE
  const [scoutingReportsDrawerOpen, setScoutingReportsDrawerOpen] =
    useState(false);
  const [actionsAnchorEl, setActionsAnchorEl] = useState<null | HTMLElement>(
    null
  );
  const actionsMenuOpen = Boolean(actionsAnchorEl);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [groupings, _] = useState<string[]>(
    team === ACADEMY_TEAM ? ACADEMY_AGE_GROUPINGS : SCOUTING_REPORT_GROUPINGS
  );
  const [
    showMutateScoutedPlayerAttrubitesDialog,
    setShowMutateScoutedPlayerAttrubitesDialog,
  ] = useState(false);

  const name = `${
    props.scoutedPlayer.first_name ? props.scoutedPlayer.first_name : ""
  } ${props.scoutedPlayer.last_name ? props.scoutedPlayer.last_name : ""}`;

  // QUERIES
  // const playerKPIs = usePlayerKPIs(statsDrawerOpen, props.scoutedPlayer.id);

  // FUNCTIONS
  const toggleScoutingReportsDrawer = useCallback(
    async (open: boolean) => {
      setScoutingReportsDrawerOpen(open);
    },
    [setScoutingReportsDrawerOpen]
  );

  const handleActionsMenuButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setActionsAnchorEl(event.currentTarget);
  };

  const handleActionsMenuClose = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setActionsAnchorEl(null);
  };

  // MUTATIONS
  const updateScoutedPlayerMutation = useUpdateScoutedPlayerMutation();

  // VARIABLES
  const isScreenSmall = useMediaQuery(theme.breakpoints.down("md"));
  const uniqueKeyPrefix = getUniqueKeyScoutedPlayer(props.scoutedPlayer);

  const textAlign = "center";

  // USEEFFECT
  useEffect(() => {}, [props.scoutedPlayer]);

  return (
    <Box key={`${uniqueKeyPrefix}-box`}>
      <MutateScoutedPlayerAttributesDialog
        showMutateScoutedPlayerAttributesDialog={
          showMutateScoutedPlayerAttrubitesDialog
        }
        setShowMutateScoutedPlayerAttributesDialog={
          setShowMutateScoutedPlayerAttrubitesDialog
        }
        scoutedPlayer={props.scoutedPlayer}
      />

      <ScoutedPlayerProfileActionsMenu
        anchorEl={actionsAnchorEl}
        handleActionMenuClose={handleActionsMenuClose}
        open={actionsMenuOpen}
        scoutedPlayer={props.scoutedPlayer}
        scoutingReportsDrawerOpen={scoutingReportsDrawerOpen}
        setScoutingReportsDrawerOpen={setScoutingReportsDrawerOpen}
        toggleScoutingReportsDrawer={toggleScoutingReportsDrawer}
        // setNotesDrawerOpen={setNotesDrawerOpen}
        // setCreateNoteModalOpen={setCreateNoteModalOpen}
        // toggleNotesDrawer={toggleNotesDrawer}
      />

      <Grid
        key={`${uniqueKeyPrefix}-container`}
        container
        justifyContent={"textAlign"}
      >
        <Grid
          item
          key={`${uniqueKeyPrefix}-card`}
          xs={12}
          sm={3}
          justifyContent="center"
          alignItems="center"
          display="flex"
        >
          <Card
            raised={false}
            key={`${uniqueKeyPrefix}-card`}
            sx={{
              textAlign: "center",
              boxShadow: "0px 0px 0px 0px #fff;",
            }}
          >
            <CardMedia
              key={`${uniqueKeyPrefix}-card-img`}
              component="img"
              alt={name}
              image={
                props.scoutedPlayer.image
                  ? props.scoutedPlayer.image
                  : default_player
              }
              sx={{
                maxWidth: isScreenSmall ? "250px" : "250px",
                minHeight: isScreenSmall ? "200px" : "250px",
                maxHeight: isScreenSmall ? "200px" : "300px",
              }}
            />
          </Card>
        </Grid>

        <Grid
          item
          key={`${uniqueKeyPrefix}-player-data-container`}
          xs={12}
          sm={9}
        >
          <Grid
            container
            key={`${uniqueKeyPrefix}-player-name-container`}
            alignItems={textAlign}
            display="flex"
            justifyContent={!isScreenSmall ? "space-between" : "center"}
            pr={isScreenSmall ? 0 : 2}
            // pl={isScreenSmall ? 0 : 2}
            pt={2}
            sx={{
              mt: isScreenSmall ? 1 : 0,
              // ml: isScreenSmall ? 0 : 1,
            }}
          >
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={1}
            >
              <MuiTooltip
                key={`${uniqueKeyPrefix}-tm-link-tooltip`}
                title={"Go to Transfermarkt Profile"}
              >
                <Link
                  key={`${uniqueKeyPrefix}-tm-data-container-player-name-typography`}
                  alignContent={textAlign}
                  noWrap={true}
                  onClick={() => {
                    if (props.scoutedPlayer.tm_link) {
                      window.open(
                        props.scoutedPlayer.tm_link,
                        "_blank",
                        "noopener,noreferrer"
                      );
                    } else {
                      setAlertOptions({
                        message: `${
                          props.scoutedPlayer.name
                            ? props.scoutedPlayer.name
                            : "this player"
                        } doesn't have a transfermakrt profile`,
                        severity: SEVERITY_INFO,
                      });
                    }
                  }}
                  sx={{
                    cursor: "pointer",
                  }}
                  variant={"h4"}
                >
                  {name}
                </Link>
              </MuiTooltip>
              <GroupingSelect
                displayLabel={false}
                groupings={groupings}
                selectedGroup={props.scoutedPlayer.grouping}
                handleGroupChange={(event: SelectChangeEvent) => {
                  updateScoutedPlayerMutation.mutate({
                    ...props.scoutedPlayer,
                    grouping: event.target.value,
                  } as ScoutedPlayer);
                }}
                variant="standard"
              />
            </Stack>

            <Box>
              <Stack
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                spacing={0}
              >
                {props.scoutedPlayer.id && (
                  <MuiTooltip
                    key={`${uniqueKeyPrefix}-player-actions-tooltip`}
                    title="Actions"
                  >
                    <IconButton
                      key={`${props.scoutedPlayer.id}-actions-button`}
                      edge="end"
                      aria-label="expand"
                      aria-controls={actionsMenuOpen ? "basic-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={actionsMenuOpen ? "true" : undefined}
                      onClick={handleActionsMenuButtonClick}
                    >
                      <MoreVertIcon
                        key={`${props.scoutedPlayer.id}-player-actions-menu-vert-icon`}
                        fontSize={"small"}
                        color={"primary"}
                      />
                    </IconButton>
                  </MuiTooltip>
                )}
              </Stack>
            </Box>
          </Grid>

          <Grid
            container
            key={`${uniqueKeyPrefix}-player-data`}
            ml={isScreenSmall ? 1 : 0}
            justifyContent="space-around"
            alignItems="center"
            display="flex"
            pt={4}
          >
            <Grid item xs={12} sm={3}>
              <MuiTooltip
                key={`${uniqueKeyPrefix}-position-tooltip`}
                title={`${props.scoutedPlayer.position || EMPTY_FIELD}`}
              >
                <Stack alignItems={"center"}>
                  <Typography key={`${uniqueKeyPrefix}-position-title`}>
                    Position
                  </Typography>
                  <Typography
                    key={`${uniqueKeyPrefix}-position-value`}
                    sx={{ color: "gray" }}
                  >
                    {props.scoutedPlayer.position || EMPTY_FIELD}
                  </Typography>
                </Stack>
              </MuiTooltip>
            </Grid>
            <Grid item xs={12} sm={3}>
              <MuiTooltip
                key={`${uniqueKeyPrefix}-team-tooltip`}
                title={`${props.scoutedPlayer.team || EMPTY_FIELD}`}
              >
                <Stack alignItems={"center"}>
                  <Typography key={`${uniqueKeyPrefix}-team-title`}>
                    Team
                  </Typography>
                  <Typography
                    key={`${uniqueKeyPrefix}-team-value`}
                    sx={{ color: "gray" }}
                  >
                    {props.scoutedPlayer.team}
                  </Typography>
                </Stack>
              </MuiTooltip>
            </Grid>
            <Grid item xs={12} sm={3}>
              <MuiTooltip
                key={`${uniqueKeyPrefix}-nationality-tooltip`}
                title={`${props.scoutedPlayer.nationality || EMPTY_FIELD}`}
              >
                <Stack alignItems={"center"}>
                  <Typography key={`${uniqueKeyPrefix}-nationality-title`}>
                    Nationality
                  </Typography>
                  <Typography
                    key={`${uniqueKeyPrefix}-nationality-value`}
                    sx={{ color: "gray" }}
                  >
                    {props.scoutedPlayer.nationality}
                  </Typography>
                </Stack>
              </MuiTooltip>
            </Grid>
            <Grid item xs={12} sm={3}>
              <MuiTooltip
                key={`${uniqueKeyPrefix}-birthdate-tooltip`}
                title={
                  `${
                    props.scoutedPlayer.birthdate &&
                    formatDateToLocalWithoutTimeForLineChart(
                      props.scoutedPlayer.birthdate
                    )
                  } (${props.scoutedPlayer?.age})` || EMPTY_FIELD
                }
              >
                <Stack alignItems={"center"}>
                  <Typography key={`${uniqueKeyPrefix}-birthdate-title`}>
                    Date of Birth
                  </Typography>
                  <Typography
                    key={`${uniqueKeyPrefix}-birthdate-value`}
                    sx={{ color: "gray" }}
                  >
                    {`${
                      props.scoutedPlayer.birthdate &&
                      formatDateToLocalWithoutTimeForLineChart(
                        props.scoutedPlayer.birthdate
                      )
                    } (${props.scoutedPlayer?.age})` || EMPTY_FIELD}
                  </Typography>
                </Stack>
              </MuiTooltip>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} padding="32px">
        <Grid container justifyContent="space-evenly" pb={4}>
          <Grid item xs={12}>
            <Box
              display="flex"
              justifyContent="flex-end"
              sx={{ cursor: "pointer" }}
              onClick={() => {
                setShowMutateScoutedPlayerAttrubitesDialog(true);
              }}
            >
              <EditIcon fontSize="small" />
            </Box>
          </Grid>
          <Grid item xs={12} sm={4} textAlign="center">
            <Stack>
              <Typography fontWeight="500">Player Comparison</Typography>
              <Typography sx={{ whiteSpace: "pre-line" }}>
                {props.scoutedPlayer.comparison || "N/A"}
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={4} textAlign="center">
            <Typography fontWeight="500">Strengths</Typography>
            <Typography sx={{ whiteSpace: "pre-line" }}>
              {props.scoutedPlayer.strengths || "N/A"}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4} textAlign="center">
            <Typography fontWeight="500">Areas to Improve</Typography>
            <Typography sx={{ whiteSpace: "pre-line" }}>
              {props.scoutedPlayer.areas_to_improve || "N/A"}
            </Typography>
          </Grid>
        </Grid>

        {props?.scoutedPlayer?.scouting_report_ids?.length > 0 && (
          <Grid
            container
            key={`${uniqueKeyPrefix}-player-scouting-reports`}
            ml={isScreenSmall ? 1 : 0}
            justifyContent="center"
            alignItems="center"
            display="flex"
          >
            <ScoutingReportsTable scoutedPlayer={props.scoutedPlayer} />
          </Grid>
        )}
      </Grid>
    </Box>
  );
}
