import { createContext, useContext, useEffect, useState } from "react";

import { FullStory } from '@fullstory/browser';
import { UserClaims } from "@okta/okta-auth-js";
import { checkRefreshToken } from "../utils/refreshToken";
import { useOktaAuth } from "@okta/okta-react";

interface AppAuth {
  pageViewAllowed: boolean;
  email: string;
  name: string;
  sub: string;
}

const defaultState = {
  pageViewAllowed: true,
  email: "",
  name: "",
  sub: "",
};

const OktaAuthContext = createContext<AppAuth>(defaultState);

export const OktaAuthProvider = ({children}: {children: React.ReactNode}) => {
  // STATE
  const [userInfo, setUserInfo] = useState<UserClaims>(defaultState);

  // CONTEXT
  const { authState, oktaAuth } = useOktaAuth();
  checkRefreshToken({ authState, oktaAuth }); 

  useEffect(() => {
    // console.debug(authState);

    if (authState && authState.idToken && authState.isAuthenticated) {
      setUserInfo(authState.idToken.claims);
      if (process.env.NODE_ENV === "production" && authState.idToken.claims.email) {
        FullStory('setIdentity', {
            uid: authState.idToken.claims.email,
            properties: {
              displayName: authState.idToken.claims.name,
              email: authState.idToken.claims.email
            }
        });
      }
    }
  }, [authState, oktaAuth]);

  return (
    <OktaAuthContext.Provider
      value={{
        pageViewAllowed: authState?.isAuthenticated || false,
        email: userInfo?.email || "",
        name: userInfo?.name || "",
        sub: userInfo?.sub,
      }}
    >
      {children}
    </OktaAuthContext.Provider>
  );
};

export const useOktaAuthContext = () => useContext(OktaAuthContext);
