import { Dispatch, SetStateAction, createContext, useContext } from "react";

import { PlayerList } from "../api/types";
import { useState } from "react";

interface ListContextInterface {
  listInContext: PlayerList | undefined;
  setListInContext: Dispatch<SetStateAction<PlayerList | undefined>>;
}

const defaultState = {
  listInContext: undefined,
  setListInContext: () => {},
};

const ListContext = createContext<ListContextInterface>(defaultState);

export const ListProvider = ({children}: {children: React.ReactNode}) => {
  // Queries
  const [listInContext, setListInContext] = useState<PlayerList>();
  const value = {
    listInContext: listInContext,
    setListInContext: setListInContext,
  };

  return <ListContext.Provider value={value}>{children}</ListContext.Provider>;
};

export const useListContext = () => useContext(ListContext);
