import { ScoutedPlayer, ScoutingReport } from "../api/types";
import { createContext, useContext, useState } from "react";

import MutateScoutingReportDialog from "../pages/scouting/Dialogs/MutateScoutingReportDialog";
import QueryScoutingReportDialog from "../pages/scouting/Dialogs/QueryScoutingReportDialog";
import { REPORT_ID } from "../queryParams";
import moment from "moment";
import { useOktaAuthContext } from "./OktaAuthContext";
import { useSearchParams } from "react-router-dom";
import { useTeamContext } from "./TeamContext";

interface ScoutingReportDialogProps {
  handleCreateReportClick: (scoutedPlayer: ScoutedPlayer | undefined) => void;
  handleReportClick: (
    scoutedPlayer: ScoutedPlayer,
    report: ScoutingReport
  ) => void;
}

const defaultState = {
  handleCreateReportClick: () => {},
  handleReportClick: () => {},
};

const ScoutingReportDialogContext =
  createContext<ScoutingReportDialogProps>(defaultState);

export const ScoutingReportDialogProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  // CONTEXT
  const { email } = useOktaAuthContext();
  const { team } = useTeamContext();
  const [reportInContext, setReportInContext] = useState<ScoutingReport>();
  const [scoutedPlayer, setScoutedPlayer] = useState<ScoutedPlayer>();

  // STATE
  const [mutateDialogOpen, setMutateDialogOpen] = useState<boolean>(false);
  const [queryDialogOpen, setQueryDialogOpen] = useState<boolean>(false);

  const handleReportClick = (
    scoutedPlayer: ScoutedPlayer,
    report: ScoutingReport
  ) => {
    // console.debug(report);
    if (report) {
      searchParams.set(REPORT_ID, String(report.id));
      setSearchParams(searchParams);
      setScoutedPlayer(scoutedPlayer);
      setReportInContext(report);
    }
    setQueryDialogOpen(true);
  };

  const handleCreateReportClick = (
    scoutedPlayer: ScoutedPlayer | undefined
  ) => {
    searchParams.delete(REPORT_ID);
    // console.debug(player);
    const newReport = {
      birth_year: scoutedPlayer?.birthdate
        ? moment(scoutedPlayer.birthdate).year().toString()
        : null,
      club: scoutedPlayer?.team || "",
      date_of_birth: scoutedPlayer?.birthdate || null,
      // foot: scoutedPlayer?.foot || "",
      for_club: team,
      first_name: scoutedPlayer?.first_name || "",
      last_name: scoutedPlayer?.last_name || "",
      player_id: scoutedPlayer?.id || null,
      scout: email,
      team: scoutedPlayer?.team || "",
    } as ScoutingReport;
    // console.debug(newReport);
    setReportInContext(newReport);
    setScoutedPlayer(scoutedPlayer);
    setMutateDialogOpen(true);
  };

  const defaultValue = {
    handleCreateReportClick: handleCreateReportClick,
    handleReportClick: handleReportClick,
  };

  return (
    <ScoutingReportDialogContext.Provider value={defaultValue}>
      <MutateScoutingReportDialog
        scoutedPlayer={scoutedPlayer}
        open={mutateDialogOpen}
        onClose={() => {
          setScoutedPlayer(undefined);
          setMutateDialogOpen(false);
        }}
        report={reportInContext}
        setOpen={setMutateDialogOpen}
        setReportInContext={setReportInContext}
      />

      <QueryScoutingReportDialog
        scoutedPlayer={scoutedPlayer}
        open={queryDialogOpen}
        openMutateDialog={setMutateDialogOpen}
        report={reportInContext}
        setOpen={setQueryDialogOpen}
        readOnly={false}
      />
      {children}
    </ScoutingReportDialogContext.Provider>
  );
};

export const useScoutingReportDialogContext = () =>
  useContext(ScoutingReportDialogContext);
