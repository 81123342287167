import { Theme, createTheme, responsiveFontSizes } from "@mui/material/styles";

import Common from "./common";

export const luganoTheme: Theme = responsiveFontSizes(
  createTheme({
    ...Common,
    palette: {
      primary: {
        // Off black
        light: "#525254",
        main: "#2a2a2b",
        dark: "#000000",
        contrastText: "#ffffff",
      },
      secondary: {
        // Red
        light: "#d53351",
        main: "#CB0026",
        dark: "#8e001a",
        contrastText: "#000000",
      },
      info: {
        // Yellow
        light: "#f1d655",
        main: "#EECC2B",
        dark: "#a68e1e",
        contrastText: "#ffffff",
      },
      error: {
        // Red
        light: "#ff5a36",
        main: "#ff0000",
        dark: "#c20000",
        contrastText: "#ffffff",
      },
      warning: {
        // Yellow
        light: "#fff04e",
        main: "#F6BE00",
        dark: "#be8e00",
        contrastText: "#000000",
      },
      success: {
        // Green
        light: "#eeb545",
        main: "#b8860b",
        dark: "#835900",
        contrastText: "#000000",
      },
    },
  })
);
