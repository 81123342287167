import * as React from "react";

import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import { EnhancedHeadCell } from "./EnhancedTableCell";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

const anchor = "bottom";

export interface EnhancedTableLegendProps<T> {
  headCells: EnhancedHeadCell<T>[];
}

export default function EnhancedTableLegend<T>(
  props: EnhancedTableLegendProps<T>
) {
  const [open, setOpen] = React.useState(false);

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setOpen(open);
    };

  return (
    <React.Fragment key={anchor}>
      <Drawer
        anchor={anchor}
        open={open}
        onClose={toggleDrawer(false)}
        sx={{ zIndex: (theme) => theme.zIndex.modal + 1 }}
      >
        <Box
          sx={{ width: "auto" }}
          role="presentation"
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
        >
          <TableContainer component={Paper}>
            <Table
              sx={{ minWidth: 650 }}
              size="small"
              aria-label="a dense table"
            >
              <TableHead>
                <TableRow>
                  <TableCell
                    align="left"
                    sx={{ fontSize: "18px", fontWeight: "bold" }}
                  >
                    Tag
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{ fontSize: "18px", fontWeight: "bold" }}
                  >
                    Elongated
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{ fontSize: "18px", fontWeight: "bold" }}
                  >
                    Explanation
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {props.headCells.map((item) => (
                  <TableRow
                    key={item.label}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="left" component="th" scope="row">
                      {item.label}
                    </TableCell>
                    <TableCell align="left">{item.elongated}</TableCell>
                    <TableCell align="left">{item.explanation}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Drawer>
    </React.Fragment>
  );
}
