import {
  IconButton,
  Link,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import { CHICAGO_FIRE } from "../constants";
import CloseIcon from "@mui/icons-material/Close";
import { DATABASE_ROUTE } from "../routes";
import { Folder } from "../api/types";
import MenuIcon from "@mui/icons-material/Menu";
import NavDrawer from "../nav/NavDrawer";
import TeamMenu from "./TeamMenu";
import { useClubContext } from "../contexts/ClubContext";
import { useListContext } from "../contexts/ListContext";
import { useLists } from "../api/queries";
import { useState } from "react";
import { useTeamContext } from "../contexts/TeamContext";

export default function ToolbarLeft() {
  // HOOKS
  const theme = useTheme();

  // CONTEXT
  const isScreenSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const { club } = useClubContext();
  const { listInContext } = useListContext();
  const { team, icon } = useTeamContext();

  // STATE
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [teamMenuAnchorEl, setTeamMenuAnchorEl] = useState<null | HTMLElement>(
    null
  );
  const [drawerOpen, setDrawerOpen] = useState(false);
  const open = Boolean(anchorEl);
  const teamMenuOpen = Boolean(teamMenuAnchorEl);

  // QUERIES
  const folders = useLists();

  const handleDrawerClick = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleTeamMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setTeamMenuAnchorEl(event.currentTarget);
  };

  return (
    <>
      <NavDrawer
        drawerOpen={drawerOpen}
        setDrawerOpen={setDrawerOpen}
        folders={folders.data as Folder[]}
      />

      {/* TEAM MENU */}
      {club === CHICAGO_FIRE && (
        <TeamMenu
          teamMenuOpen={teamMenuOpen}
          teamMenuAnchorEl={teamMenuAnchorEl}
          setTeamMenuAnchorEl={setTeamMenuAnchorEl}
        />
      )}

      <Stack direction="row" spacing={0}>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerClick}
          edge="start"
        >
          {drawerOpen ? <CloseIcon /> : <MenuIcon />}
        </IconButton>

        {club === CHICAGO_FIRE && (
          <Tooltip title="Switch Team">
            <IconButton
              color="info"
              onClick={handleTeamMenuClick}
              size="small"
              aria-controls={open ? "team-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
            >
              <Typography
                sx={{ display: "flex", alignItems: "center" }}
                variant="button"
                color="inherit"
              >
                {icon}&nbsp;{!isScreenSmall && team.replace("_", " ")}
              </Typography>
            </IconButton>
          </Tooltip>
        )}

        {listInContext?.name && !isScreenSmall && (
          <Link
            href={`${DATABASE_ROUTE}?list_id=${listInContext.id}`}
            noWrap={true}
            color={theme.palette.primary.contrastText}
            sx={{
              m: "auto",
              ml: 1,
            }}
            variant="button"
          >
            {listInContext.name}
          </Link>
        )}
      </Stack>
    </>
  );
}
