import { Dispatch, SetStateAction } from "react";

import ListItemIcon from "@mui/material/ListItemIcon";
import { ListItemText } from "@mui/material";
import LoginIcon from "@mui/icons-material/Login";
import Logout from "@mui/icons-material/Logout";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { toRelativeUrl } from "@okta/okta-auth-js";
import { useOktaAuth } from "@okta/okta-react";
import { useOktaAuthContext } from "../contexts/OktaAuthContext";

interface AccountMenuProps {
  accountMenuOpen: boolean;
  accountMenuAnchorEl: HTMLElement | null;
  setAccountMenuAnchorEl: Dispatch<SetStateAction<HTMLElement | null>>;
}

export default function AccountMenu(props: AccountMenuProps) {
  // CONTEXT
  const { authState, oktaAuth } = useOktaAuth();
  const { name, email } = useOktaAuthContext();

  // FUNCTIONS
  const handleAccountClose = () => {
    props.setAccountMenuAnchorEl(null);
  };

  return (
    <Menu
      id="account-menu"
      anchorEl={props.accountMenuAnchorEl}
      open={props.accountMenuOpen}
      onClose={handleAccountClose}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
    >
      <MenuItem>
        <ListItemText primaryTypographyProps={{ noWrap: true }}>
          {name} | {email}
        </ListItemText>
      </MenuItem>

      {authState?.isAuthenticated && (
        <MenuItem
          onClick={() => {
            oktaAuth.signOut();
          }}
        >
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          <ListItemText>Logout</ListItemText>
        </MenuItem>
      )}

      {!authState?.isAuthenticated && (
        <MenuItem
          onClick={() => {
            const originalUri = toRelativeUrl(
              window.location.href,
              window.location.origin
            );
            oktaAuth.setOriginalUri(originalUri);
            oktaAuth.signInWithRedirect();
          }}
        >
          <ListItemIcon>
            <LoginIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Login</ListItemText>
        </MenuItem>
      )}
    </Menu>
  );
}
