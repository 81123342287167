import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
} from "@mui/material";
import { DateValidationError, PickerChangeHandlerContext } from "@mui/x-date-pickers";
import { Dispatch, SetStateAction, useRef, useState } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";

import DefaultDatePicker from "../../../components/DefaultDatePicker";
import { SchedulingWeek } from "../../../api/types";
import { useClubContext } from "../../../contexts/ClubContext";
import { useCreateSchedulingWeekMutation } from "../../../api/mutations";
import { useTeamContext } from "../../../contexts/TeamContext";

export interface AddSchedulingWeekDialogProps {
  showAddSchedulingWeekDialog: boolean;
  setShowAddSchedulingWeekDialog: Dispatch<SetStateAction<boolean>>;
}

export default function AddSchedulingWeekDialog(
  props: AddSchedulingWeekDialogProps
) {
  // CONTEXTS
  const { club } = useClubContext();
  const { team } = useTeamContext();

  // MUTATIONS
  const createTotwWeek = useCreateSchedulingWeekMutation();

  // REFS
  const formRef = useRef();

  // STATES
  const [label, setLabel] = useState<string>("");
  const [startDate, setStartDate] = useState<Date | undefined>(undefined);

  // FUNCTIONS
  const clearStates = () => {
    setLabel("");
    setStartDate(undefined);
  };

  const handleSubmit = () => {
    const obj = {
      club: club,
      team: team,
      label: label,
      date: startDate,
    };

    createTotwWeek.mutate(obj as SchedulingWeek);
    clearStates();
    props.setShowAddSchedulingWeekDialog(false);
  };

  return (
    <Dialog
      open={props.showAddSchedulingWeekDialog}
      onClose={() => {
        clearStates();
        props.setShowAddSchedulingWeekDialog(false);
      }}
    >
      <DialogTitle>{`New Scheduling Week`}</DialogTitle>
      <ValidatorForm
        ref={formRef.current}
        onSubmit={() => {
          handleSubmit();
        }}
      >
        <DialogContent>
          <Stack spacing={2}>
            <TextValidator
              fullWidth
              id="week-dialog-label-select"
              label="Week Label"
              name="label"
              value={label}
              validators={["required"]}
              errorMessages={["this field is required"]}
              variant="outlined"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setLabel(event.target.value)
              }
            />

            <DefaultDatePicker
              key="week-dialog-start-date"
              label="Start Date"
              value={startDate}
              onChange={(
                value: Date | null,
                context: PickerChangeHandlerContext<DateValidationError>
              ) => value && setStartDate(value)}
              disableFuture={false}
            />
          </Stack>
        </DialogContent>
        <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
          <Button onClick={() => props.setShowAddSchedulingWeekDialog(false)}>
            CANCEL
          </Button>
          <Button
            type="submit"
            disabled={
              label === "" || startDate === undefined || isNaN(+startDate)
            }
          >
            CREATE WEEK
          </Button>
        </DialogActions>
      </ValidatorForm>
    </Dialog>
  );
}
