import { FullStory as FS } from '@fullstory/browser';
import { OktaAuthOptions } from '@okta/okta-auth-js';

export const isProduction = process.env.NODE_ENV === "production";

export const redirectUri = isProduction
  ? "https://" + window.location.hostname + "/login/callback"
  : "http://localhost:3000/login/callback";

export const fullStoryConfig = {
  // devMode: process.env.NODE_ENV == 'development',
  orgId: process.env.REACT_APP_FULLSTORY_ORG_ID || '',
}

export const oktaConfig : OktaAuthOptions = {
    issuer: process.env.REACT_APP_OKTA_ISSUER as string,
    clientId: process.env.REACT_APP_OKTA_CLIENT_ID,
    redirectUri: redirectUri,
    scopes: ["openid", "profile", "email", "offline_access"],
  }

// same configuration you would create for the Rollbar.js SDK
export const rollbarConfig = {
  accessToken: process.env.REACT_APP_ROLLBAR_TOKEN,
  enabled: process.env.NODE_ENV === 'production', 
  environment: process.env.NODE_ENV,
  autoInstrument: true,
  captureUncaught: true,
  captureUnhandledRejections: true,
  captureUsername: true,
  captureEmail: true,
  payload: {
    environment: process.env.NODE_ENV,
    client: {
      javascript: {
        code_version: "1.0.0",
        source_map_enabled: true,
      },
    }
  },
  transform: function (payload: any) {
      // Ensure the FullStory object exists.
      // console.debug(payload);
      if ( FS('getSession')) {
          // Add the FullStory session URL to the Rollbar payload
          payload.fullstorysessionURL =  FS('getSession');
      }
      return payload;
  }
};
