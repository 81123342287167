import { Folder, PlayerList } from "../api/types";

import { Box } from "@mui/material";
import CancelTwoToneIcon from "@mui/icons-material/CancelTwoTone";
import { DATABASE_ROUTE } from "../routes";
import ListLabel from "./ListLabel";
import RadioButtonUncheckedRoundedIcon from "@mui/icons-material/RadioButtonUncheckedRounded";
import { TreeItem } from "./TreeItem";
import { useListContext } from "../contexts/ListContext";
import { useNavigate } from "react-router-dom";

interface NavListProps {
    folder: Folder,
    list: PlayerList,
    folderIndex: number,
    index: number
}

export default function NavList(props: NavListProps) {
    const navigate = useNavigate();
    const { listInContext, setListInContext } = useListContext();

    const selectedList = props.list.id === listInContext?.id;
    const color = selectedList ? "secondary" : "primary";

    function ListIcon() {
        return selectedList 
          ?
            <CancelTwoToneIcon
                key={`${props.folderIndex}-${props.index}-${props.folder.id}-${props.list.id}-tree-item-unselect-list`}
                fontSize="small"
                color={color}
                onClick={(event) => {
                        if (!selectedList) {
                            setListInContext(props.list);
                        } else {
                            setListInContext(undefined);
                        }
                        event.stopPropagation();
                    }
                }
                sx={{
                    width: '1rem'
                }}
            />
          :
            <RadioButtonUncheckedRoundedIcon
                key={`${props.folderIndex}-${props.index}-${props.folder.id}-${props.list.id}-tree-item-select-list`}
                fontSize="small"
                color={color}
                onClick={(event) => {
                        if (!selectedList) {
                            setListInContext(props.list);
                        } else {
                            setListInContext(undefined);
                        }
                        event.stopPropagation();
                    }
                }
                sx={{
                    width: '1rem'
                }}
            />;
    }

    return (
        <TreeItem 
            itemId={`${props.folderIndex}-${props.index}-${props.folder.id}-${props.folder.name}-tree-item-label`}        
            label={
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
              <Box
                component={ListIcon}
                color="inherit"
              />
                <ListLabel folder={props.folder} list={props.list} />
             </Box>
            }
            onClick={() => {
                if (props.list === listInContext) { 
                    setListInContext(undefined);
                    navigate(DATABASE_ROUTE);
                } else {
                    setListInContext(props.list);
                    navigate(`${DATABASE_ROUTE}?list_id=${props.list.id}`);
                }
            }}
        />
    )
}
