import TableauDashboard, { TableauDashboardParams } from "../../components/TableauDashboard";

const WORKBOOK_URL = "https://prod-useast-b.online.tableau.com/t/waldenanalytics/views/DepthChart/DepthChart";

interface DepthChartProps {
    competition?: string
    team?: string
}

const DepthChart = (props: DepthChartProps) => {
    const params = props as TableauDashboardParams
    // console.debug(params);

    return (
        <TableauDashboard url={WORKBOOK_URL} params={params} />
    );
}

export default DepthChart;
