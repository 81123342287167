import { Box, Stack } from "@mui/material";

import DefaultDatePicker from "./DefaultDatePicker";
import { Dispatch } from "react";
import { GET_TEAM_STATS } from "../api/keys";
import { useQueryClient } from "react-query";

export type SelectorsProps = {
  startDate: Date;
  endDate: Date;
  club: string;
  setStartDateValue: Dispatch<Date>;
  setEndDateValue: Dispatch<Date>;
};

export default function Selectors(props: SelectorsProps) {
  const queryClient = useQueryClient();

  const handleStartDateChange = (startDate: Date | null) => {
    if (startDate) {
      props.setStartDateValue(startDate);
      queryClient.invalidateQueries([GET_TEAM_STATS]);
    }
  };

  const handleEndDateChange = (endDate: Date | null) => {
    if (endDate) {
      props.setEndDateValue(endDate);
      queryClient.invalidateQueries([GET_TEAM_STATS]);
    }
  };

  return (
    <Box mt={2} sx={{ display: "flex", justifyContent: "center" }}>
      <Stack direction="row" spacing={2}>
        <Box>
          <DefaultDatePicker
            label="Start Date"
            value={props.startDate}
            onChange={handleStartDateChange}
            disableFuture
          />
        </Box>

        <Box>
          <DefaultDatePicker
            label="End Date"
            value={props.endDate}
            onChange={handleEndDateChange}
            disableFuture
          />
        </Box>
      </Stack>
    </Box>
  );
}
