import {
  Autocomplete,
  Avatar,
  Chip,
  ListItem,
  ListItemText,
  MenuList,
  PopoverOrigin,
  Popper,
  Stack,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import Draggable, { DraggableData, DraggableEvent } from "react-draggable";
import { EMPTY_FIELD, SCOUTING_REPORT_GROUP_COLLEGE } from "../../constants";
import {
  PlayerWithScoutingReport,
  ScoutedPlayer,
  ScoutingReport,
  TotwPlayer,
  TotwWeek,
} from "../../api/types";

import AddBoxIcon from "@mui/icons-material/Add";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { alpha } from "@mui/material";
import { useCreateTotwPlayersMutation } from "../../api/mutations";
import { useThemeContext } from "../../contexts/CustomThemeContext";
import { useTotwContext } from "../../contexts/TotwContext";

const PLAYER_LIST_MAX_WIDTH = 250;
const WHITE = "#fff";

interface PlayerProps {
  grouping: string;
  position: string;
  topOffset: string;
  leftOffset: string;
  reports: PlayerWithScoutingReport[] | null | undefined;
  playerPerPosition: number;
  showAddPlayerButtons: boolean;
  editMode: boolean;
  setScoutedPlayerInContext: Dispatch<
    SetStateAction<ScoutedPlayer | null | undefined>
  >;
  setScoutedPlayerProfileModalOpen: Dispatch<SetStateAction<boolean>>;
  setPosition: Dispatch<SetStateAction<string>>;
}

export default function PitchPlayer(props: PlayerProps) {
  // CONTEXT
  const { theme } = useThemeContext();
  const isScreenSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const { selectedWeek } = useTotwContext();

  // STATE
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [showPlayers, setShowPlayers] = useState<boolean>(false);
  const [showPlayersLastState, setShowPlayersLastState] =
    useState<boolean>(false);
  const [lastX, setLastX] = useState<number>();
  const [lastY, setLastY] = useState<number>();
  const [showAddPlayers, setShowAddPlayers] = useState<boolean>(false);
  const [filteredReports, setFilteredReports] = useState<
    PlayerWithScoutingReport[] | null | undefined
  >([]);
  const [sortedAndfilteredPlayers, setSortedAndFilteredPlayers] = useState<
    PlayerWithScoutingReport[] | null | undefined
  >([]);

  const [selectedReports, setSelectedReports] = useState<ScoutingReport[]>([]);
  const [currentWeek, setCurrentWeek] = useState<TotwWeek | undefined>(
    selectedWeek
  );

  // MUTATIONS
  const createTotwPlayers = useCreateTotwPlayersMutation();

  // REFs
  const ref = useRef(null);

  // PLAYERS
  const playerAvatarRadius = isScreenSmall ? 24 : 32;

  // FUNCTIONS
  function compare(a: ScoutingReport, b: ScoutingReport) {
    if (a.rating < b.rating) {
      return 1;
    }

    if (a.rating > b.rating) {
      return -1;
    }

    if (a.rating === b.rating) {
      if (a.first_name < b.first_name) {
        return 1;
      }

      if (a.first_name > b.first_name) {
        return -1;
      }
    }

    return 0;
  }

  function addPlayersToTOTW() {
    if (selectedWeek?.id) {
      let players = selectedReports.map((report) => {
        let obj = {
          scouting_report_id: report.id,
          week_id: selectedWeek?.id,
        };

        return obj;
      });

      createTotwPlayers.mutate({
        weekId: selectedWeek?.id,
        players: players as TotwPlayer[],
      });
    }

    setSelectedReports([]);
    setShowAddPlayers(false);
  }

  // EFFECTS
  useEffect(() => {
    setAnchorEl(ref.current);
    setShowPlayers(!isScreenSmall);
    setShowPlayersLastState(!isScreenSmall);
  }, [ref, isScreenSmall, setShowPlayers, setShowPlayersLastState]);

  useEffect(() => {
    let reports =
      props.reports &&
      props.reports.filter((report) => {
        return (
          report.grouping === props.grouping &&
          Number(report.position) === Number(props.position)
        );
      });

    setFilteredReports(reports);
  }, [props.reports, props.grouping, props.position]);

  useEffect(() => {
    // filtered for players for TOTW and for this specific position
    let players =
      filteredReports &&
      filteredReports
        .filter((element, index, array) => {
          // if in totw view, filter out players if they are not associated with the current week
          if (props.editMode) {
            return selectedWeek?.players?.some((p: TotwPlayer) => {
              return p.scouting_report_id === element.id;
            });
          } else {
            return true;
          }
        })
        .filter((element, index, array) => {
          // We need to account for the off by one error here for position
          return (
            element.grouping === props.grouping &&
            Number(element.position) === Number(props.position)
          );
        })
        .sort(compare)
        .filter((element, index, array) => {
          return index < props.playerPerPosition;
        });

    setSortedAndFilteredPlayers(players);

    // this is to maintain showAddPlayers status if changes are made within the same week
    if (currentWeek?.id !== selectedWeek?.id) {
      setCurrentWeek(selectedWeek);
      setShowPlayers(true);
    }
  }, [
    currentWeek?.id,
    filteredReports,
    props.editMode,
    props.grouping,
    props.playerPerPosition,
    props.position,
    selectedWeek,
  ]);

  // VARIABLES
  const BORDER = `1px solid ${theme.palette.primary.main}`;

  let transformOrigin: PopoverOrigin = {
    vertical: "top",
    horizontal: "center",
  };

  if (isScreenSmall) {
    if (props.position === "7" || props.position === "2") {
      transformOrigin.horizontal = "right";
    } else if (props.position === "11" || props.position === "3") {
      transformOrigin.horizontal = "left";
    }
  }

  return (
    <>
      <Draggable
        nodeRef={ref}
        onDrag={() => {
          setShowPlayers(false);
        }}
        onStart={(e: DraggableEvent, data: DraggableData) => {
          props.setPosition(props.position);
          setShowPlayersLastState(showPlayers);
          setShowAddPlayers(false);
          setLastX(data.x);
          setLastY(data.y);
        }}
        onStop={(e: DraggableEvent, data: DraggableData) => {
          if (data.x !== lastX || data.y !== lastY) {
            setShowPlayers(showPlayersLastState);
            setShowPlayersLastState(showPlayers);
          } else {
            setShowPlayers(!showPlayers);
          }

          setLastX(data.x);
          setLastY(data.y);
        }}
      >
        <Tooltip
          key={`player-position-${props.position}-tooltip`}
          placement="top"
          title="Click and hold to drag"
        >
          <Avatar
            ref={ref}
            aria-describedby={`player-${props.position}-popover`}
            sx={{
              position: "absolute",
              cursor: "grab",
              backgroundColor: theme.palette.primary.main,
              width: playerAvatarRadius,
              height: playerAvatarRadius,
              top: props.topOffset,
              left: props.leftOffset,
            }}
          >
            <Typography variant="button">{props.position}</Typography>
          </Avatar>
        </Tooltip>
      </Draggable>
      {props.editMode && filteredReports && props.showAddPlayerButtons && (
        <Popper
          id={`all-player-${props.position}-edit`}
          open={showPlayers}
          anchorEl={anchorEl}
          placement="right"
          modifiers={[
            {
              name: "preventOverflow",
              enabled: true,
              options: {
                altAxis: true,
                altBoundary: true,
                tether: true,
                rootBoundary: "document",
                padding: 8,
              },
            },
          ]}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <div
              style={{ cursor: "pointer" }}
              onClick={() => {
                setShowAddPlayers(!showAddPlayers);
              }}
            >
              <AddBoxIcon
                sx={{
                  color: theme.palette.info.main,
                  backgroundColor: theme.palette.primary.main,
                  height: 20,
                  width: 20,
                  borderRadius: 1,
                  marginLeft: 1,
                }}
                fontSize="large"
              />
            </div>
            {showAddPlayers && (
              <div
                id={`player-auto-complete-${props.position}`}
                style={{
                  position: "absolute",
                  left: 40,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  padding: 6,
                  backgroundColor: theme.palette.primary.main,
                  borderRadius: 2,
                }}
              >
                <Autocomplete
                  sx={{
                    width: 250,
                    zIndex: 200,
                  }}
                  multiple
                  options={filteredReports}
                  onChange={(event, values) => {
                    setSelectedReports(values);
                  }}
                  getOptionLabel={(option) => {
                    return option.first_name + " " + option.last_name;
                  }}
                  renderOption={(props, option) => {
                    return (
                      <li {...props} key={option.id}>
                        {`${option.first_name} ${option.last_name}`}
                      </li>
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                        style: {
                          backgroundColor: "white",
                          fontSize: 14,
                          paddingLeft: 5,
                        },
                      }}
                      variant="standard"
                      placeholder={
                        selectedReports.length === 0 ? "Select player" : ""
                      }
                    />
                  )}
                  renderTags={(tagValue, getTagProps) => {
                    return tagValue.map((option, index) => (
                      <Chip
                        sx={{ fontSize: 13 }}
                        {...getTagProps({ index })}
                        label={option.first_name + " " + option.last_name}
                      />
                    ));
                  }}
                />
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => addPlayersToTOTW()}
                >
                  <PersonAddIcon
                    sx={{
                      color: theme.palette.info.main,
                      paddingLeft: 1,
                      paddingRight: 1,
                      height: 20,
                      width: 20,
                    }}
                  />
                </div>
              </div>
            )}
          </div>
        </Popper>
      )}
      <Popper
        id={`player-${props.position}-popover`}
        open={showPlayers}
        anchorEl={anchorEl}
        placement="bottom"
        modifiers={[
          {
            name: "preventOverflow",
            enabled: true,
            options: {
              altAxis: true,
              altBoundary: true,
              tether: true,
              rootBoundary: "document",
              padding: 8,
            },
          },
        ]}
      >
        <MenuList
          dense
          disablePadding
          disableListWrap={true}
          sx={{
            backgroundColor: WHITE,
            border: BORDER,
            borderRadius: 1,
            maxWidth: PLAYER_LIST_MAX_WIDTH,
            marginTop: 1,
          }}
        >
          {props.reports &&
            sortedAndfilteredPlayers &&
            sortedAndfilteredPlayers.map(
              (report: PlayerWithScoutingReport, index) => {
                let playerInfo = `${report.first_name} ${report.last_name} | ${
                  report.year_in_school ? report.year_in_school : EMPTY_FIELD
                } | ${report.club}`;

                return (
                  <ListItem
                    dense
                    disableGutters
                    key={`${report.id}-${report.first_name}-${report.last_name}`}
                    sx={{
                      backgroundColor:
                        props.grouping === SCOUTING_REPORT_GROUP_COLLEGE &&
                        !report.domestic
                          ? alpha(theme.palette.secondary.main, 0.7)
                          : "white",
                      borderBottom:
                        index === sortedAndfilteredPlayers?.length - 1
                          ? 0
                          : BORDER,
                      maxWidth: PLAYER_LIST_MAX_WIDTH,
                    }}
                  >
                    <Tooltip
                      disableFocusListener
                      describeChild
                      placement="right"
                      key={`${report.id}-${report.first_name}-${report.last_name}-tooltip`}
                      title={"Click to View Player Profile"}
                    >
                      <ListItemText
                        // primary={`${report.first_name} ${report.last_name} | ${Math.round(report.rating * 100) / 100}`}
                        // primaryTypographyProps={{ noWrap: true, variant: 'caption', fontSize: isScreenSmall ? 9 : 11 }}
                        onClick={() => {
                          props.setScoutedPlayerInContext({} as ScoutedPlayer);
                          props.setScoutedPlayerProfileModalOpen(true);
                        }}
                        sx={{
                          margin: 0,
                          padding: 0,
                          paddingLeft: 1,
                          paddingRight: 1,
                          cursor: "pointer",
                        }}
                      >
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                          minWidth={125}
                        >
                          {props.grouping !== SCOUTING_REPORT_GROUP_COLLEGE && (
                            <>
                              <Typography
                                noWrap
                                variant="caption"
                                sx={{ fontSize: isScreenSmall ? 9 : 11 }}
                              >
                                {report.first_name} {report.last_name}
                              </Typography>

                              <Typography
                                variant="caption"
                                sx={{ fontSize: isScreenSmall ? 9 : 11 }}
                              >
                                {Math.round(report.rating * 100) / 100}
                              </Typography>
                            </>
                          )}

                          {props.grouping === SCOUTING_REPORT_GROUP_COLLEGE && (
                            <>
                              <Typography
                                noWrap
                                variant="caption"
                                sx={{
                                  fontSize: isScreenSmall ? 9 : 11,
                                }}
                              >
                                {playerInfo}
                              </Typography>
                            </>
                          )}
                        </Stack>
                      </ListItemText>
                    </Tooltip>
                  </ListItem>
                );
              }
            )}
        </MenuList>
      </Popper>
    </>
  );
}
