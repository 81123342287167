import {
  Box,
  Button,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import {
  DataGridPremium,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid-premium";
import {
  SchedulingGame,
  SchedulingWeek,
  SchedulingWeekGame,
} from "../../api/types";
import { useEffect, useMemo, useState } from "react";
import {
  useSchedulingGames,
  useSchedulingWeeks,
  useScoutingReports,
} from "../../api/queries";

import { APPBAR_HEIGHT_AS_NUM } from "../../app_bar/Toolbar";
import AddIcon from "@mui/icons-material/Add";
import AddSchedulingWeekDialog from "./Dialogs/AddSchedulingWeekDialog";
import DeleteGameDialog from "./Dialogs/DeleteGameDialog";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteWeekDialog from "./Dialogs/DeleteWeekDialog";
import MutateGameDialog from "./Dialogs/MutateGameDialog";
import { SchedulingDataGridCols } from "../../common/DataGridColDefs";
import TeamPlayersDialog from "./Dialogs/TeamPlayersDialog";
import { useClubContext } from "../../contexts/ClubContext";
import { useDeleteSchedulingWeekMutation } from "../../api/mutations";
import useLocalStorage from "react-use-localstorage";
import { useSchedulingContext } from "../../contexts/SchedulingContext";
import { useTeamContext } from "../../contexts/TeamContext";
import { useThemeContext } from "../../contexts/CustomThemeContext";
import { useWindowSizeContext } from "../../contexts/WindowSizeContext";

function Scheduling() {
  // CONTEXT
  const { height } = useWindowSizeContext();
  const { theme } = useThemeContext();
  const { club } = useClubContext();
  const { team } = useTeamContext();
  const { selectedWeek, setSelectedWeek, setSelectedGame } =
    useSchedulingContext();

  // MUTATIONS
  const deleteSchedulingWeek = useDeleteSchedulingWeekMutation();

  // QUERIES
  const allSchedulingWeeks = useSchedulingWeeks().data;
  const allSchedulingGames = useSchedulingGames().data;
  const allScoutingReports = useScoutingReports().data;
  // VARIABLES
  const defaultWeek = useMemo(() => {
    return {
        id: -1,
        club: "",
        team: "",
        label: "",
        date: new Date(),
        week_games: [],
    }
  }, []);

  // STATES
  const [currentSchedulingWeeks, setCurrentSchedulingWeeks] = useState<
    SchedulingWeek[]
  >([]); // maintain sorted order of weeks
  const [currentGames, setCurrentGames] = useState<
    SchedulingGame[] | undefined
  >([]);
  const [showAddWeekDialog, setShowAddWeekDialog] = useState<boolean>(false);
  const [showDeleteWeekDialog, setShowDeleteWeekDialog] =
    useState<boolean>(false);
  const [showMutateGameDialog, setShowMutateGameDialog] =
    useState<boolean>(false);
  const [showDeleteGameDialog, setShowDeleteGameDialog] =
    useState<boolean>(false);
  const [selectedTeam, setSelectedTeam] = useState<string>("");


  const storageKey = `col_vis_model_for_${team}_scheduling_db`;
  const [storedColumnVisibilityModel, setStoredColumnVisibilityModel] = useLocalStorage(
    storageKey,
    JSON.stringify({})
  );
  const columnVisibilityModel = JSON.parse(storedColumnVisibilityModel);

  // FUNCTIONS
  const handleWeekChange = (event: SelectChangeEvent) => {
    if (event.target.value !== "") {
      let week = currentSchedulingWeeks.find((w: SchedulingWeek) => {
        return w.label === event.target.value;
      });
      setSelectedWeek(week || defaultWeek);
    } else {
      if (
        currentSchedulingWeeks === undefined ||
        currentSchedulingWeeks === null ||
        currentSchedulingWeeks.length === 0
      ) {
        setSelectedWeek(defaultWeek);
      }
    }
  };

  const addNewGame = () => {
    setShowMutateGameDialog(true);
  };

  const handleEditGame = (gameId: number) => {
    setSelectedGame(
      currentGames?.find((g) => {
        return g.id === gameId;
      })
    );
    setShowMutateGameDialog(true);
  };

  const handleDeleteGame = (gameId: number) => {
    setSelectedGame(
      currentGames?.find((g) => {
        return g.id === gameId;
      })
    );
    setShowDeleteGameDialog(true);
  };

  const handleTeamClick = (team: string) => {
    setSelectedTeam(team);
  };

  // EFFECTS
  useEffect(() => {
    if (allSchedulingWeeks !== undefined && allSchedulingWeeks.length > 0) {
      let scopedWeeks = allSchedulingWeeks.filter((w) => {
        return w.team === team && w.club === club;
      });
      let updatedWeek = scopedWeeks.find((w) => {
        return w.id === selectedWeek?.id;
      });
      setCurrentSchedulingWeeks(
        scopedWeeks.sort((a, b) => a.date.getTime() - b.date.getTime())
      );
      setSelectedWeek(updatedWeek || scopedWeeks[0]);
    } else {
      setCurrentSchedulingWeeks([]);
      setSelectedWeek(defaultWeek);
    }
  }, [allSchedulingWeeks, team, club, defaultWeek, selectedWeek?.id, setSelectedWeek]);

  useEffect(() => {
    let games = allSchedulingGames?.filter((g) => {
      return selectedWeek?.week_games.some((wg: SchedulingWeekGame) => {
        return wg.game_id === g.id;
      });
    });

    setCurrentGames(games);
  }, [selectedWeek, allSchedulingGames, allSchedulingWeeks]);

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <FormControl
          sx={{ zIndex: 100 }}
          size="small"
        >
          <Select
            id="scheduling-week-select"
            value={selectedWeek?.label || defaultWeek.label}
            onChange={handleWeekChange}
            sx={{
              backgroundColor: "white",
              color: theme.palette.primary.main,
            }}
          >
            {currentSchedulingWeeks?.map((week: SchedulingWeek) => {
              let weekStr =
                Number(week.date.getMonth() + 1) +
                "/" +
                Number(week.date.getDate());

              return (
                <MenuItem
                  key={`select-scheduling-week-${week.label}`}
                  value={week.label}
                >
                  {week.label + " (" + weekStr + ")"}
                </MenuItem>
              );
            })}
            <MenuItem
              key={`add-new-scheduling-week`}
              sx={{
                borderTop: currentSchedulingWeeks
                  ? `2px solid ${theme.palette.primary.main}`
                  : "",
              }}
              onClick={() => {
                setShowAddWeekDialog(true);
              }}
              value={""}
            >
              <Typography>Add new week</Typography>
              <AddIcon
                sx={{
                  color: theme.palette.primary.main,
                  height: 24,
                  width: 24,
                }}
              />
            </MenuItem>
          </Select>
        </FormControl>
        {selectedWeek?.id !== -1 && (
          <div
            style={{
              cursor: "pointer",
              marginRight: 16,
              zIndex: 1,
            }}
            onClick={() => setShowDeleteWeekDialog(true)}
          >
            <DeleteIcon
              sx={{
                color: theme.palette.primary.main,
                height: 28,
                width: 28,
              }}
            />
          </div>
        )}
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport />
        {selectedWeek?.id && (
          <Button
            sx={{ display: "inline-flex" }}
            startIcon={<AddIcon />}
            onClick={addNewGame}
          >
            Add Game
          </Button>
        )}
      </GridToolbarContainer>
    );
  };

  return (
    <>
      <AddSchedulingWeekDialog
        showAddSchedulingWeekDialog={showAddWeekDialog}
        setShowAddSchedulingWeekDialog={setShowAddWeekDialog}
      />
      <DeleteWeekDialog
        week={selectedWeek || defaultWeek}
        deleteWeekMutation={deleteSchedulingWeek}
        showDeleteWeekDialog={showDeleteWeekDialog}
        setShowDeleteWeekDialog={setShowDeleteWeekDialog}
      />
      <MutateGameDialog
        showMutateGameDialog={showMutateGameDialog}
        setShowMutateGameDialog={setShowMutateGameDialog}
      />
      <DeleteGameDialog
        showDeleteGameDialog={showDeleteGameDialog}
        setShowDeleteGameDialog={setShowDeleteGameDialog}
      />
      <TeamPlayersDialog
        scoutingReports={allScoutingReports}
        selectedTeam={selectedTeam}
        setSelectedTeam={setSelectedTeam}
      />
      <Box style={{ height: height - APPBAR_HEIGHT_AS_NUM - 5 }}>
        <DataGridPremium
          aria-label={"scheduling-datagrid"}
          columns={SchedulingDataGridCols(
            handleEditGame,
            handleDeleteGame,
            handleTeamClick
          )}
          columnVisibilityModel={columnVisibilityModel}
          initialState={{
            sorting: {
              sortModel: [{ field: "date_time", sort: "asc" }],
            },
          }}
          onColumnVisibilityModelChange={(newModel) =>
            setStoredColumnVisibilityModel(JSON.stringify(newModel))
          }
          rows={currentGames ? currentGames : []}
          slots={{ toolbar: CustomToolbar }}
        />
      </Box>
    </>
  );
}

export default Scheduling;
