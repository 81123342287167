import "./index.css";

import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

import { QueryClient, QueryClientProvider } from "react-query";

import App from "./App";
import CacheBuster from "react-cache-buster";
import { Helmet } from "react-helmet";
import { LicenseInfo } from "@mui/x-license";
import React from "react";
import { ReactQueryDevtools } from "react-query/devtools";
import { BrowserRouter as Router } from "react-router-dom";
import { createRoot } from "react-dom/client";
import { init as initFullStory } from '@fullstory/browser';
import packageJson from "../package.json";
import reportWebVitals from "./reportWebVitals";

console.debug(`Version: ${packageJson.version}`);

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUIX_LICENSE_KEY as string);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retryDelay: (attemptIndex) => Math.min(1000 * 2 ** attemptIndex, 30000),
    },
  },
});

// console.debug(process.env.NODE_ENV);
const isProduction = process.env.NODE_ENV === "production";
const title =
  isProduction ? "The Pond" : "The Pond (dev)";

if (isProduction) {
  initFullStory(
    { orgId: process.env.REACT_APP_FULLSTORY_ORG_ID || '' },
  );
}

const root = createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <React.StrictMode>
    <CacheBuster
      currentVersion={packageJson.version}
      isEnabled={true}
      isVerboseMode={true}
      metaFileDirectory={`/meta.json?t=${Date.now()}#`}
    >
      <Router>
        <QueryClientProvider client={queryClient} contextSharing={true}>
          <Helmet title={title} />
          <App />
          <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
        </QueryClientProvider>
      </Router>
    </CacheBuster>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
