import { CHICAGO_FIRE, CHICAGO_FIRE_2, SECOND_TEAM } from "../constants";
import { Dispatch, SetStateAction } from "react";
import {
  GET_AUTH,
  GET_CONSTANT_COUNTRIES,
  GET_CONSTANT_SCHOOLS,
  GET_FOLDERS,
  GET_KIT_MANAGER_ACTIONS,
  GET_KIT_MANAGER_ACTION_LOGS,
  GET_KIT_MANAGER_PLAYERS,
  GET_LIST,
  GET_LISTS,
  GET_NOTIFICATIONS,
  GET_OPERATIONS_DATA,
  GET_PLAYER,
  GET_PLAYERS,
  GET_PLAYER_DATABASE_META,
  GET_PLAYER_KPIS,
  GET_PLAYER_NOTES,
  GET_PLAYER_WEEKLY_STATS,
  GET_SCHEDULING_GAMES,
  GET_SCHEDULING_WEEKS,
  GET_SCOUTED_PLAYERS,
  GET_SCOUTED_PLAYER_SCOUTING_REPORTS,
  GET_SCOUTING_REPORTS,
  GET_SHARES,
  GET_TABLE_METRICS,
  GET_TEAM_STATS,
  GET_TOTW_WEEKS,
  GET_USERS,
  PLAYER_SEARCH,
  SCOUTED_PLAYER_SEARCH,
} from "./keys";
import { GridFilterModel, GridSortModel } from "@mui/x-data-grid-premium";
import {
  NO_DATA_FOUND_ALERT_MESSAGE,
  useAlertContext,
} from "../contexts/AlertContext";
import { Player, PlayerList, SchedulingGame } from "./types";
import {
  formatDateToLocal,
  formatDateToLocalWithoutTime,
  getUTCDate,
} from "../utils/dates";

import { AxiosError } from "axios";
import { isTokenExpired } from "../utils/refreshToken";
import { useAPIContext } from "../contexts/APIContext";
import { useClubContext } from "../contexts/ClubContext";
import { useOktaAuth } from "@okta/okta-react";
import { useOktaAuthContext } from "../contexts/OktaAuthContext";
import { useQuery } from "react-query";
import { useTeamContext } from "../contexts/TeamContext";

const DEFAULT_STALE_TIME = 60 * 60 * 24;

interface IQueryOptions {
  alertSeverity?: string;
  alertTitle?: string;
  alertMessage?: string;
  enabled?: boolean;
  errorBoundaryHttpCode?: number;
  refetchOnWindowFocus?: boolean;
  retry?: boolean;
  staleTime?: number;
}

export function QueryOptions({
  alertMessage,
  alertSeverity = "error",
  alertTitle = "Error",
  enabled = true,
  errorBoundaryHttpCode = 400,
  refetchOnWindowFocus = false,
  retry = false,
  staleTime = DEFAULT_STALE_TIME,
}: IQueryOptions) {
  const { authState, oktaAuth } = useOktaAuth();
  const { setAlertOptions } = useAlertContext();

  return {
    enabled:
      enabled &&
      !isTokenExpired({ authState, oktaAuth }) &&
      !!authState?.isAuthenticated,
    retry: retry,
    refetchOnWindowFocus: refetchOnWindowFocus,
    staleTime: staleTime,
    onError: (error: Error) => {
      if (error instanceof AxiosError) {
        //   console.debug(error);

        if (Number(error?.response?.status) >= errorBoundaryHttpCode) {
          setAlertOptions({
            message: alertMessage
              ? alertMessage
              : `${error.message} | ${error.response?.data.detail}`,
            severity: alertSeverity,
            title: alertTitle,
          });
        }
      } else {
        setAlertOptions({
          message: alertMessage ? alertMessage : error.message,
          severity: alertSeverity,
          title: alertTitle,
        });
      }
    },
    useErrorBoundary: (error: Error) => {
      if (error instanceof AxiosError) {
        console.debug(
          `error.response.status: ${error?.response?.status} | error.message: ${error.message} | errorBoundaryHttpCode: ${errorBoundaryHttpCode}`
        );

        if (error?.response?.status === 401) {
          oktaAuth.signOut();
        }
      }

      return false;
    },
  };
}

export function useAuth() {
  const { apiClient } = useAPIContext();
  const { email } = useOktaAuthContext();

  return useQuery(
    [GET_AUTH],
    async () => {
      return await apiClient.getAuth(email);
    },
    QueryOptions({
      alertMessage: "failed to fetch auth",
      enabled: !!email,
    })
  );
}

export function useFolders(owner: string | undefined) {
  const { apiClient } = useAPIContext();

  return useQuery(
    [GET_FOLDERS],
    async () => {
      if (owner) {
        return await apiClient.getFolders(owner);
      } else {
        return [];
      }
    },
    QueryOptions({
      alertMessage: "failed to load folders",
      staleTime: 0,
    })
  );
}

export function useLeagueTable() {
  const { apiClient } = useAPIContext();
  const { club } = useClubContext();
  const { team } = useTeamContext();

  return useQuery(
    [GET_TABLE_METRICS, { club: club, team: team }],
    async () => {
      return await apiClient.getTeamTable(
        club === CHICAGO_FIRE && team === SECOND_TEAM ? CHICAGO_FIRE_2 : club
      );
    },
    QueryOptions({
      alertMessage: "failed to load table",
    })
  );
}

export function useList(
  listId: number,
  loadList: boolean,
  pageNumber: number,
  pageSize: number,
  filters: GridFilterModel | undefined,
  sortModel: GridSortModel | undefined,
  setListInContext?: Dispatch<SetStateAction<PlayerList | undefined>>
) {
  const { email } = useOktaAuthContext();
  const { apiClient } = useAPIContext();

  return useQuery(
    [
      GET_LIST,
      {
        email: email,
        listId: listId,
        pageNumber: pageNumber,
        pageSize: pageSize,
        filters: filters,
        sortModel: sortModel,
      },
    ],
    async () => {
      const list = await apiClient.getList(
        listId,
        email,
        pageNumber,
        pageSize,
        filters,
        sortModel
      );
      list && setListInContext && setListInContext(list);
      return list;
    },
    QueryOptions({
      enabled: loadList,
      alertMessage: "failed to load list",
      retry: true,
    })
  );
}

export function useLists() {
  const { apiClient } = useAPIContext();
  const { email } = useOktaAuthContext();

  return useQuery(
    [GET_LISTS, { email: email }],
    async () => {
      return await apiClient.getLists(email);
    },
    QueryOptions({
      enabled: email !== null,
      alertMessage: "failed to load lists",
      staleTime: DEFAULT_STALE_TIME,
    })
  );
}

export function usePlayer(playerId: number) {
  const { apiClient } = useAPIContext();

  return useQuery(
    [GET_PLAYER, { playerId: playerId }],
    async () => {
      if (playerId) {
        return await apiClient.getPlayer(playerId);
      }
    },
    QueryOptions({
      alertMessage: "failed to load player",
    })
  );
}

export function usePlayerDatabaseMeta() {
  const { apiClient } = useAPIContext();

  return useQuery(
    [GET_PLAYER_DATABASE_META],
    async () => {
      return await apiClient.getPlayerDatabaseMeta();
    },
    QueryOptions({
      enabled: true,
      alertMessage: NO_DATA_FOUND_ALERT_MESSAGE,
    })
  );
}

export function usePlayerKPIs(enabled: boolean, playerId: number) {
  const { apiClient } = useAPIContext();

  return useQuery(
    [GET_PLAYER_KPIS, { playerId: playerId }],
    async () => {
      if (enabled) {
        return await apiClient.getPlayerKPIs(playerId);
      }
    },
    QueryOptions({
      enabled: enabled,
      alertMessage: NO_DATA_FOUND_ALERT_MESSAGE,
    })
  );
}

export function useScoutedPlayerScoutingReports(
  scoutedPlayerId: number | undefined
) {
  const { apiClient } = useAPIContext();

  return useQuery(
    [GET_SCOUTED_PLAYER_SCOUTING_REPORTS, { scoutedPlayerId: scoutedPlayerId }],
    async () => {
      if (scoutedPlayerId) {
        return await apiClient.getScoutedPlayerScoutingReports(scoutedPlayerId);
      }
    },
    QueryOptions({
      alertMessage: NO_DATA_FOUND_ALERT_MESSAGE,
    })
  );
}

export function usePlayerNotes(playerId: number) {
  const { apiClient } = useAPIContext();

  return useQuery(
    [GET_PLAYER_NOTES, { playerId: playerId }],
    async () => {
      if (playerId) {
        return await apiClient.getPlayerNotes(playerId);
      }
    },
    QueryOptions({
      alertMessage: "failed to load player notes",
    })
  );
}

export function usePlayers(
  filters: GridFilterModel | undefined,
  loadList: boolean,
  pageNumber: number,
  pageSize: number,
  sortModel: GridSortModel | undefined
) {
  const { apiClient } = useAPIContext();

  return useQuery(
    [
      GET_PLAYERS,
      {
        filters: filters,
        pageNumber: pageNumber,
        pageSize: pageSize,
        sortModel: sortModel,
      },
    ],
    async () => {
      return await apiClient.getPlayers(
        pageNumber,
        pageSize,
        filters,
        sortModel
      );
    },
    QueryOptions({
      enabled: !loadList,
      alertMessage: "failed to load players",
    })
  );
}

export function usePlayerWeeklyStats(player: Player, metric: string) {
  const { apiClient } = useAPIContext();

  return useQuery(
    [GET_PLAYER_WEEKLY_STATS, { playerId: player.id, metric: metric }],
    async () => {
      return await apiClient.getPlayersWeeklyStats(player, metric);
    },
    QueryOptions({
      alertMessage: "failed to load weekly stats",
    })
  );
}

export function useNotifications() {
  const { apiClient } = useAPIContext();
  const { email } = useOktaAuthContext();

  return useQuery(
    [GET_NOTIFICATIONS],
    async () => {
      return await apiClient.getNotifications(email);
    },
    QueryOptions({
      enabled: !!email,
    })
  );
}

export function useScoutedPlayers() {
  const { apiClient } = useAPIContext();

  return useQuery(
    [GET_SCOUTED_PLAYERS],
    async () => {
      return await apiClient.getScoutedPlayers();
    },
    QueryOptions({
      alertMessage: "failed to load scouted players",
    })
  );
}

export function useScoutingReports() {
  const { apiClient } = useAPIContext();
  const { club } = useClubContext();
  const { team } = useTeamContext();

  return useQuery(
    [GET_SCOUTING_REPORTS, { club, team }],
    async () => {
      return await apiClient.getScoutingReports(club, team);
    },
    QueryOptions({
      alertMessage: "failed to load scouting reports",
    })
  );
}

export function useShares(objectId: number | undefined, objectType: string) {
  const { email } = useOktaAuthContext();
  const { apiClient } = useAPIContext();

  return useQuery(
    [GET_SHARES, { email, objectId, objectType }],
    async () => {
      if (email && objectId) {
        return await apiClient.getShares(email, objectId, objectType);
      } else {
        return [];
      }
    },
    QueryOptions({
      staleTime: 0,
    })
  );
}

export function usePlayerSearch(
  submittedParams: { query: string; by: string },
  pageSize: number,
  pageNumber: number
) {
  const { apiClient } = useAPIContext();

  return useQuery(
    [PLAYER_SEARCH, { submittedParams, pageNumber, pageSize }],
    async () => {
      return await apiClient.searchPlayers(
        submittedParams.query,
        submittedParams.by,
        pageSize,
        pageNumber
      );
    },
    QueryOptions({
      enabled: !!submittedParams.query && !!submittedParams.by,
      errorBoundaryHttpCode: 500,
    })
  );
}

export function useScoutedPlayerSearch(
  submittedParams: { query: string; by: string },
  pageSize: number,
  pageNumber: number
) {
  const { apiClient } = useAPIContext();

  return useQuery(
    [SCOUTED_PLAYER_SEARCH, { submittedParams, pageNumber, pageSize }],
    async () => {
      return await apiClient.searchScoutedPlayers(
        submittedParams.query,
        submittedParams.by,
        pageSize,
        pageNumber
      );
    },
    QueryOptions({
      enabled: !!submittedParams.query && !!submittedParams.by,
      errorBoundaryHttpCode: 500,
    })
  );
}

export function useTeamSearch(
  submittedParams: { query: string | null; by: string },
  pageSize: number,
  pageNumber: number
) {
  // const {apiClient} = useAPIContext();
  // return useQuery(
  //   [TEAM_SEARCH, { submittedParams, pageNumber, pageSize }],
  //   async () => {
  //     return await apiClient.searchTeams(
  //       submittedParams.query,
  //       submittedParams.by,
  //       pageSize,
  //       pageNumber
  //     )
  //   },
  //   QueryOptions({
  //     enabled: !!submittedParams.query && !!submittedParams.by,
  //     errorBoundaryHttpCode: 500,
  //   })
  // )
}

export function useTeamMetrics(
  startDate: Date,
  endDate: Date,
  teamName: string
) {
  const { apiClient } = useAPIContext();

  const startDateCacheKey = formatDateToLocalWithoutTime(startDate);
  const endDateCacheKey = formatDateToLocalWithoutTime(endDate);

  return useQuery(
    [GET_TEAM_STATS, { startDateCacheKey, endDateCacheKey, teamName }],
    async () => {
      return await apiClient.getTeamStats(startDate, endDate, teamName);
    },
    QueryOptions({
      alertMessage: "failed to load stats",
    })
  );
}

export function useConstantUsers() {
  const { apiClient } = useAPIContext();

  return useQuery(
    [GET_USERS],
    async () => {
      return await apiClient.getUsers();
    },
    QueryOptions({
      staleTime: 0,
      errorBoundaryHttpCode: 500,
    })
  );
}

export function useTotwWeeks() {
  const { apiClient } = useAPIContext();

  return useQuery(
    [GET_TOTW_WEEKS],
    async () => {
      let weeks = await apiClient.getTotwWeeks();
      weeks.forEach((w) => {
        // adjust for automatic timezone adjustment
        w.date = getUTCDate(new Date(w.date));
      });

      return weeks;
    },
    QueryOptions({ alertMessage: "failed to load TOTW Weeks" })
  );
}

export function useSchedulingWeeks() {
  const { apiClient } = useAPIContext();

  return useQuery(
    [GET_SCHEDULING_WEEKS],
    async () => {
      let weeks = await apiClient.getSchedulingWeeks();
      weeks.forEach((w) => {
        // adjust for automatic timezone adjustment
        w.date = getUTCDate(new Date(w.date));
      });

      return weeks;
    },
    QueryOptions({ alertMessage: "failed to load Scheduling Weeks" })
  );
}

export function useSchedulingGames() {
  const { apiClient } = useAPIContext();

  return useQuery(
    [GET_SCHEDULING_GAMES],
    async () => {
      let games = await apiClient.getSchedulingGames();

      games.map((g: SchedulingGame) => {
        return (g.date_time = new Date(formatDateToLocal(g.date_time)));
      });

      return games;
    },
    QueryOptions({ alertMessage: "failed to load Scheduling Games" })
  );
}

export function useConstantSchools() {
  const { apiClient } = useAPIContext();

  return useQuery(
    [GET_CONSTANT_SCHOOLS],
    async () => {
      let schools = await apiClient.getConstantSchools();

      return schools;
    },
    QueryOptions({
      errorBoundaryHttpCode: 500,
    })
  );
}

export function useConstantCountries() {
  const { apiClient } = useAPIContext();

  return useQuery(
    [GET_CONSTANT_COUNTRIES],
    async () => {
      let schools = await apiClient.getConstantCountries();

      return schools;
    },
    QueryOptions({
      errorBoundaryHttpCode: 500,
    })
  );
}

export function useOperationsData(club: string) {
  const { apiClient } = useAPIContext();

  return useQuery(
    [GET_OPERATIONS_DATA, { club }],
    async () => {
      let data = await apiClient.getOperationsData(club);

      return data;
    },
    QueryOptions({
      errorBoundaryHttpCode: 500,
    })
  );
}

export function useKitManagerPlayers(team: string) {
  const { apiClient } = useAPIContext();

  return useQuery(
    [GET_KIT_MANAGER_PLAYERS, { team }],
    async () => {
      let players = await apiClient.getKitManagerPlayers(team);

      return players;
    },
    QueryOptions({
      errorBoundaryHttpCode: 500,
    })
  );
}

export function useKitManagerActionLogs(team: string) {
  const { apiClient } = useAPIContext();

  return useQuery(
    [GET_KIT_MANAGER_ACTION_LOGS, { team }],
    async () => {
      let action_logs = await apiClient.getKitManagerActionLogs(team);

      return action_logs;
    },
    QueryOptions({
      errorBoundaryHttpCode: 500,
    })
  );
}

export function useKitManagerActions() {
  const { apiClient } = useAPIContext();

  return useQuery(
    [GET_KIT_MANAGER_ACTIONS],
    async () => {
      let actions = await apiClient.getKitManagerActions();

      return actions;
    },
    QueryOptions({
      errorBoundaryHttpCode: 500,
    })
  );
}
