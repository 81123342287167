import { alpha, styled } from "@mui/material/styles";

import TableRow from "@mui/material/TableRow";

const EnhancedTableRow = styled(TableRow)(({ theme }) => {
  return {
    // hide last border
    "&:last-child th": {
      border: 0,
    },
    "&.Mui-selected, &.MuiTableRow-hover:hover": {
      background: alpha(theme.palette.info.main, 0.25),
    },
  };
});

export default EnhancedTableRow;
