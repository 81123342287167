import {
  ChartsAxisHighlight,
  ChartsLegend,
  ChartsReferenceLine,
  ChartsTooltip,
  ChartsXAxis,
  ChartsYAxis,
  LinePlot,
  ResponsiveChartContainer
} from "@mui/x-charts";
import { FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent, useTheme } from "@mui/material";
import { MLS_SEASON_START, STAT_INFO } from "../constants";
import { Player, WeeklyStat } from "../api/types";

import { AxisValueFormatterContext } from "@mui/x-charts/models/axis";
import { CustomLineChartTooltip } from "./CustomLineChartTooltip";
import { GridLoadingOverlay } from "@mui/x-data-grid-premium";
import { formatDateToLocalWithoutTimeForLineChart } from "../utils/dates";
import { usePlayerWeeklyStats } from "../api/queries";
import { useState } from "react";

export type PlayerMetricLineChartProps = {
  player: Player;
};

const DATA_KEY_SEASON_PERCENTILE = "value_p90_season_pctile";
const DATA_KEY_TEAM_RANK = "value_p90_team_rank";
const DATA_KEY_VALUE_P90 = "value_p90";

const DATA_KEY_SEASON_PERCENTILE_LABEL = "season percentile";
const DATA_KEY_SEASON_TEAM_RANK_LABEL = "team rank";
const DATA_KEY_SEASON_VALUEP90_LABEL = "value (p90)";

let WEEKLY_METRICS : string[] = [];

for (const key in STAT_INFO) {
  if (key.startsWith("player_match")) {
    WEEKLY_METRICS.push(key);
  }
}

function yAxisLabel(metric: string) {
  return metric.replace("player_match_", "").replaceAll("_", " ").toLowerCase();
}

export default function PlayerMetricLineChart(props: PlayerMetricLineChartProps) {
  const theme = useTheme();

  // STATE
  const [dataKey, setDataKey] = useState<string>(DATA_KEY_SEASON_PERCENTILE);
  const [dataKeyLabel, setDataKeyLabel] = useState<string>(DATA_KEY_SEASON_PERCENTILE_LABEL);
  const [metric, setMetric] = useState<string>("player_match_obv");

  // QUERIES
  const playerWeeklyStatsQuery = usePlayerWeeklyStats(props?.player, metric);

  // console.debug(`dataKey: ${dataKey} | metric: ${metric}`);

  if (playerWeeklyStatsQuery.isFetched && playerWeeklyStatsQuery.isSuccess) {
    return (
      <Grid container>
        <Grid item xs={12} mt={1}>
          <FormControl sx={{ m: 1 }} size="small">
            <InputLabel id="metric-label">Metric</InputLabel>
            <Select
              autoWidth
              id="metric-select"
              label="Metric"
              labelId="metric-label"
              onChange={(event: SelectChangeEvent) => setMetric(event.target.value)}
              value={metric}
            >
              {WEEKLY_METRICS.map((metric: string, index: number) => {
                  return (
                    <MenuItem key={`${dataKey}-${metric}-${index}`} value={metric}>
                      {yAxisLabel(metric)}
                    </MenuItem>
                  )
                })
              }
            </Select>
          </FormControl>

          <FormControl sx={{ m: 1 }} size="small">
            <InputLabel id="value-type-label">Y-Axis</InputLabel>
            <Select
              autoWidth
              id="value-type-select"
              label="Y-Axis"
              labelId="value-type-label"
              onChange={(event: SelectChangeEvent) => {
                  setDataKey(event.target.value);
                  if (event.target.value === DATA_KEY_SEASON_PERCENTILE) {
                    setDataKeyLabel(DATA_KEY_SEASON_PERCENTILE_LABEL);
                  } else if (event.target.value === DATA_KEY_TEAM_RANK) {
                    setDataKeyLabel(DATA_KEY_SEASON_TEAM_RANK_LABEL);
                  } else {
                    setDataKeyLabel(DATA_KEY_SEASON_VALUEP90_LABEL);
                  }
                }
              }
              value={dataKey}
            >
              <MenuItem value={DATA_KEY_SEASON_PERCENTILE}>{DATA_KEY_SEASON_PERCENTILE_LABEL}</MenuItem>
              <MenuItem value={DATA_KEY_TEAM_RANK}>{DATA_KEY_SEASON_TEAM_RANK_LABEL}</MenuItem>
              <MenuItem value={DATA_KEY_VALUE_P90}>{DATA_KEY_SEASON_VALUEP90_LABEL}</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid
            item
            xs={12}
            display="flex"
            justifyContent="center"
            alignItems="center"
        >
            <ResponsiveChartContainer
              dataset={playerWeeklyStatsQuery.data}
              series={
                [
                  { 
                    curve: "catmullRom",
                    color: theme.palette.primary.main,
                    dataKey: dataKey,
                    label: yAxisLabel(metric),
                    type: 'line'
                  },
                  { 
                    curve: "catmullRom", 
                    color: theme.palette.secondary.main,
                    dataKey: `${dataKey}_avg`,
                    label: yAxisLabel(metric) + " (rolling average)",
                    type: 'line'
                  }
                ]
              }
              xAxis={
                [
                  { 
                    id: 'xAxis',
                    data: playerWeeklyStatsQuery.data.map((dataPoint: WeeklyStat) => new Date(dataPoint.match_date)),
                    dataKey: 'match_date',
                    label: 'match date',
                    labelStyle: {
                        fontSize: 18,
                        fontWeight: 'bold',
                        transform: `translateY(5px)`
                    },
                    reverse: true,
                    scaleType: 'point',
                    valueFormatter: (value, context: AxisValueFormatterContext) => {
                        return formatDateToLocalWithoutTimeForLineChart(value)
                    },
                  }
                ]
              }
              yAxis={
                [
                  {
                    id: 'yAxis',
                    label: yAxisLabel(metric) + ` (${dataKeyLabel})`,
                    labelStyle: {
                        fontSize: 18,
                        fontWeight: 'bold'
                    },
                  }
                ]
              }
            >
                <LinePlot />
                <ChartsXAxis />
                <ChartsYAxis />
                <ChartsLegend />
                <ChartsAxisHighlight x="line" />
                <ChartsTooltip 
                    trigger="axis"
                    slots={{
                        itemContent: CustomLineChartTooltip(playerWeeklyStatsQuery.data)
                    }}
                />
                <ChartsReferenceLine
                    axisId='xAxis'
                    x={new Date(MLS_SEASON_START)}
                    label="Season Start"
                    labelAlign="end"
                    labelStyle={{ fontSize: '12' }}
                    lineStyle={{ strokeDasharray: '8 8' }}
                />
                <ChartsReferenceLine
                    y={50}
                    lineStyle={{ strokeDasharray: '8 8' }}
                />
            </ResponsiveChartContainer>
        </Grid>
      </Grid>
    );
  } else {
    return <GridLoadingOverlay />;
  }
}
