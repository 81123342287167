import { Player, ScoutedPlayer } from "../api/types";

import md5 from "md5";

export const getUniqueKeyPlayer = (player: Player): string => {
  const hash = md5(
    `${player.tm_link}-${player.birthdate}-${player.nationality}`
  ).replace(" ", "-");
  return `${player.id}-${player.competition_id}-${player.season_id}-${hash}`;
};

export const getUniqueKeyScoutedPlayer = (
  scoutedPlayer: ScoutedPlayer
): string => {
  const hash = md5(
    `${scoutedPlayer.tm_link}-${scoutedPlayer.birthdate}-${scoutedPlayer.nationality}`
  ).replace(" ", "-");
  return `${scoutedPlayer.id}-${scoutedPlayer.grouping}-${scoutedPlayer.team}-${hash}`;
};
