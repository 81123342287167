import CloseIcon from "@mui/icons-material/Close";
import { Fab } from "@mui/material";

interface CloseButtonProps {
  onClick: () => void;
  position: string;
  top?: number;
  bottom?: number;
  left?: number;
  right?: number;
}

export default function CloseButton(props: CloseButtonProps) {
  return (
    <Fab
      sx={{
        position: props.position,
        top: props.top ? props.top : "auto",
        bottom: props.bottom ? props.bottom : "auto",
        left: props.left ? props.left : "auto",
        right: props.right ? props.right : "auto",
      }}
      onClick={props.onClick}
      size="small"
      color="secondary"
      aria-label="close"
    >
      <CloseIcon />
    </Fab>
  );
}
