import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  DateValidationError,
  PickerChangeHandlerContext,
} from "@mui/x-date-pickers";
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";

import CloseButton from "../../../components/CloseButton";
import DefaultDatePicker from "../../../components/DefaultDatePicker";
import { ScoutedPlayerCreate } from "../../../api/types";
import { useConstantsContext } from "../../../contexts/ConstantsContext";
import { useCreateScoutedPlayerMutation } from "../../../api/mutations";

export interface CreateScoutedPlayerDialogProps {
  name: string;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  closeDialog: () => void;
  grouping: string;
}

export default function CreateScoutedPlayerDialog(
  props: CreateScoutedPlayerDialogProps
) {
  // CONTEXTS
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { countries } = useConstantsContext();

  // STATES
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [position, setPosition] = useState<string>("");
  const [dateOfBirth, setDateOfBirth] = useState<Date | undefined>();
  const [dobError, setDOBError] = useState<DateValidationError | undefined>();
  const [team, setTeam] = useState<string>("");
  const [nationality, setNationality] = useState<string[]>([]);
  const [disabled, setDisabled] = useState<boolean>(true);

  // REFS
  const formRef = useRef();

  // MUTATIONS
  const createScoutedPlayer = useCreateScoutedPlayerMutation();

  // FUNCTIONS
  const resetForm = () => {
    setFirstName("");
    setLastName("");
    setPosition("");
    setDateOfBirth(undefined);
    setTeam("");
    setNationality([]);
  };

  const handleSubmit = () => {
    let payload = {
      grouping: props.grouping,
      first_name: firstName,
      last_name: lastName,
      position: position,
      birthdate: dateOfBirth,
      team,
      nationality: nationality.toString(),
    };

    createScoutedPlayer.mutate(payload as ScoutedPlayerCreate);

    props.setOpen(false);
    resetForm();
  };

  useEffect(() => {
    if (
      firstName === "" ||
      lastName === "" ||
      position == null ||
      dateOfBirth == null ||
      dobError != null ||
      team === "" ||
      nationality.length === 0
    ) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [firstName, lastName, position, dateOfBirth, dobError, team, nationality]);

  useEffect(() => {
    setFirstName(props.name.split(" ")[0]);
    setLastName(props.name.split(" ")[1]);
  }, [props.name]);

  return (
    <Box>
      <Dialog
        open={props.open}
        onClose={(event: object, reason: string) => {
          if (reason === "escapeKeyDown") {
            props.closeDialog();
          }
        }}
        fullScreen={fullScreen}
        fullWidth
      >
        <DialogTitle sx={{ textAlign: "center" }}>
          CREATE SCOUTING PLAYER
        </DialogTitle>
        <ValidatorForm onSubmit={handleSubmit} ref={formRef.current}>
          <DialogContent>
            <CloseButton
              position="absolute"
              top={5}
              left={5}
              onClick={() => {
                props.closeDialog();
                resetForm();
              }}
            />

            <Stack spacing={2}>
              <TextValidator
                errorMessages={["this field is required"]}
                id="create-scouted-player-dialog-first-name-text"
                label="First Name"
                name="first_name"
                validators={["required"]}
                value={firstName}
                variant="outlined"
                fullWidth
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setFirstName(event.target.value)
                }
              />
              <TextValidator
                errorMessages={["this field is required"]}
                id="create-scouted-player-dialog-last-name-text"
                label="Last Name"
                name="player_name"
                validators={["required"]}
                value={lastName}
                variant="outlined"
                fullWidth
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setLastName(event.target.value)
                }
              />
              <TextValidator
                errorMessages={["this field is required"]}
                id="create-scouted-player-dialog-position-text"
                label="Position"
                name="position"
                validators={["required"]}
                value={position}
                variant="outlined"
                fullWidth
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setPosition(event.target.value)
                }
              />
              <DefaultDatePicker
                key={"create-scouted-player-dialog-dob-picker"}
                label="Date of Birth"
                value={dateOfBirth}
                onChange={(
                  value: Date | null,
                  context: PickerChangeHandlerContext<DateValidationError>
                ) => value && setDateOfBirth(value)}
                onError={(error) => setDOBError(error)}
              />
              <TextValidator
                errorMessages={["this field is required"]}
                id="create-scouted-player-dialog-team-text"
                label="Team"
                name="team"
                validators={["required"]}
                value={team}
                variant="outlined"
                fullWidth
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setTeam(event.target.value)
                }
              />
              <Autocomplete
                multiple
                id="create-scouted-player-dialog-nationality"
                options={countries || []}
                renderInput={(params) => (
                  <TextField {...params} label="Nationality" />
                )}
                value={nationality}
                onChange={(event: any, newValue: string[]) => {
                  setNationality(newValue);
                }}
              />
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button type="submit" disabled={disabled}>
              Submit
            </Button>
          </DialogActions>
        </ValidatorForm>
      </Dialog>
    </Box>
  );
}
