import { Order } from "../types";
import { Theme } from "@emotion/react";

export const stickyColumnStyle = (theme: Theme) => {
  return {
    position: "sticky",
    left: 0,
  };
};

export function descendingComparator<T>(a: T, b: T, sortKey: keyof T): number {
  if (b[sortKey] < a[sortKey]) {
    return -1;
  }

  if (b[sortKey] > a[sortKey]) {
    return 1;
  }

  // sortKeys are equivalent, compare name
  if (a["name" as keyof T] && b["name" as keyof T]) {
    if (b["name" as keyof T] < a["name" as keyof T]) {
      return -1;
    }

    if (b["name" as keyof T] > a["name" as keyof T]) {
      return 1;
    }
  }

  return 0;
}

export function getComparator(order: Order, sortKey: string) {
  // Match stat X to player_match_X
  return order === "desc"
    ? (a: any, b: any) => descendingComparator(a, b, sortKey)
    : (a: any, b: any) => -descendingComparator(a, b, sortKey);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
export function stableSort<T>(
  array: T[],
  comparator: (a: T, b: T) => number
): T[] {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });

  return stabilizedThis.map((el) => el[0]) as T[];
}
