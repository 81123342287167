import { Backdrop, Button, Stack } from "@mui/material";

import LoginIcon from "@mui/icons-material/Login";
import landingBackground from "../../assets/landing_background.jpg";
import { useOktaAuth } from "@okta/okta-react";
import { useThemeContext } from "../../contexts/CustomThemeContext";

interface SignInPageProps {
    triggerLogin: () => {}
}

export default function SignInPage(props: SignInPageProps) {
    const { theme } = useThemeContext();
    const { authState} = useOktaAuth();

    return (
        <Backdrop
            sx={{
                backgroundImage: `url(${landingBackground})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                zIndex: (theme) => theme.zIndex.appBar + 1
            }}
            open={!authState?.isAuthenticated}
        >
            <Stack
                direction="column"
                justifyContent="space-evenly"
                alignItems="center"
            >
                {!authState?.isAuthenticated && (
                    <Button
                        size="large"
                        variant="contained"
                        endIcon={<LoginIcon />}
                        sx={{
                            marginLeft: "auto",
                            marginRight: "auto",
                            backgroundColor: theme.palette.primary.main,
                        }}
                        onClick={() => {
                            props.triggerLogin();
                        }}
                    >
                        Sign In
                    </Button>
                )}
            </Stack>
        </Backdrop>
    );
}
