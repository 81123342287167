import { Box, Typography } from "@mui/material"

interface DialogFieldProps {
  label: string
  value: string | number | undefined
  width: string
}

export const DialogField = (props: DialogFieldProps) => {
  return (
    <Box sx={{ width: props.width }} pb={1}>
      <Typography variant="subtitle2" align="center">
        {props.label.toUpperCase()}
      </Typography>

      <Typography
        variant="subtitle1"
        align="center"
      >
        {props?.value}
      </Typography>
    </Box>
  )
}
