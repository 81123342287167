import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  styled,
} from "@mui/material";

import { ReactNode } from "react";
import { SCOUTING_REPORT_GROUP_NONE } from "../../constants";
import { SelectProps } from "@mui/material/Select";

import "./GroupingSelect.css";
import { useGroupContext } from "../../contexts/GroupContext";

interface GroupingSelectProps {
  groupings: string[];
  handleGroupChange: (event: SelectChangeEvent) => void;
  displayLabel?: boolean;
  selectedGroup?: string;
  landingOpen?: boolean;
  variant?: SelectProps["variant"];
}

const GroupingSelect = (props: GroupingSelectProps) => {
  // console.debug(props);
  const { group } = useGroupContext();

  const variant = props?.variant ? props.variant : "outlined";

  const CustomSelect = styled(Select<string>)`
    backgroundcolor: "white";

    div#grouping-select.MuiSelect-standard {
      border-bottom: 2px solid rgba(0, 0, 0, 0.3);
      padding: 0;
    }

    div#grouping-select.MuiSelect-standard:hover {
      border-bottom: 2px solid rgba(0, 0, 0, 1);
    }
  `;

  return (
    <FormControl
      sx={{
        marginTop: "auto",
        marginBottom: "auto",
        minWidth: 125,
      }}
    >
      {props.displayLabel && (
        <InputLabel htmlFor="grouping-select-label" variant={variant}>
          Group
        </InputLabel>
      )}

      <CustomSelect
        autoWidth
        displayEmpty
        id="grouping-select"
        label={props.displayLabel ? "Group" : ""}
        labelId={props.displayLabel ? "grouping-select-label" : ""}
        onChange={(event: SelectChangeEvent<string>, child: ReactNode) => {
          props.handleGroupChange(event);
        }}
        renderValue={(selected) => {
          // console.debug(`selected: ${selected} | length: ${selected.length}`);

          if (selected.length === 0) {
            return props.landingOpen ? <em>Select Group</em> : "None";
          } else if (
            selected.length === 1 &&
            selected === SCOUTING_REPORT_GROUP_NONE
          ) {
            return "None";
          }

          return selected;
        }}
        sx={{
          backgroundColor: "white",
          borderBottom: "0px",
        }}
        value={props.selectedGroup ?? group ?? ""}
        variant={variant}
      >
        {props.landingOpen && (
          <MenuItem
            disabled
            key={"placeholder"}
            value={SCOUTING_REPORT_GROUP_NONE}
            selected={props.landingOpen}
          >
            <em>Select Group</em>
          </MenuItem>
        )}
        {props.groupings.map((teamGrouping: string) => {
          if (
            props.landingOpen &&
            teamGrouping === SCOUTING_REPORT_GROUP_NONE
          ) {
            // eslint-disable-next-line array-callback-return
            return;
          }

          return (
            <MenuItem
              key={`select-player-group-${teamGrouping}`}
              value={teamGrouping}
              selected={
                !props.landingOpen &&
                teamGrouping === group &&
                group !== SCOUTING_REPORT_GROUP_NONE
              }
            >
              {teamGrouping === SCOUTING_REPORT_GROUP_NONE
                ? "None"
                : teamGrouping}
            </MenuItem>
          );
        })}
      </CustomSelect>
    </FormControl>
  );
};

export default GroupingSelect;
