import {
  List,
  ListItem,
  ListItemText,
  Paper,
  Stack,
  Typography,
} from "@mui/material";

import { ChartsItemContentProps } from "@mui/x-charts/ChartsTooltip";
import { WeeklyStat } from "../api/types";
import { formatStringToLocalString } from "../utils/dates";

export const CustomLineChartTooltip = (stats: WeeklyStat[]) => function(props: ChartsItemContentProps) {
  // console.debug(props);

  if (!props?.itemData.dataIndex || !props?.itemData) {
    return <></>;
  }

  // console.debug(props);

  const dataIndex = props?.itemData.dataIndex;
  const date = stats[dataIndex].match_date;
  const value = props?.series.data[dataIndex] as number;

  // console.debug(`dataIndex: ${dataIndex} | date: ${date} | value: ${value}`);

  return (
    <Paper
      elevation={3}
      sx={{ paddingLeft: 1, paddingRight: 1, textTransform: "capitalize" }}
      key={"custom-tooltip-paper"}
    >
      <List dense={true} key={"custom-tooltip-list"}>
        <ListItem key={"custom-tooltip-list-label"}>
          <Typography variant="h6">
            {formatStringToLocalString(date || "n/a")}
          </Typography>
        </ListItem>

        <ListItem key={"custom-tooltip-list-home-label"}>
          <Typography variant="body1">
            Home: {stats[dataIndex].home_team_name}
          </Typography>
        </ListItem>

        <ListItem key={"custom-tooltip-list-away-label"}>
          <Typography variant="body1">
            Away: {stats[dataIndex].away_team_name}
          </Typography>
        </ListItem>

        <Stack
          direction="row"
          key={"custom-tooltip-list-stack"}
        >
          <ListItem key={"custom-tooltip-value"}>
            <ListItemText
              primary={Math.round(value)}
            />
          </ListItem>
        </Stack>
      </List>
    </Paper>
  );
}
