import {
  Box,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  Dispatch,
  SetStateAction,
  useState,
} from "react";
import { KitManagerAction, KitManagerPlayer } from "../../api/types";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import InfoIcon from "@mui/icons-material/Info";
import { useThemeContext } from "../../contexts/CustomThemeContext";

export interface NumberActionProps {
  action: KitManagerAction;
  setTotalCredits: Dispatch<SetStateAction<number>>;
  player: KitManagerPlayer | null;
  actions: KitManagerAction[];
  setActions: Dispatch<SetStateAction<KitManagerAction[]>>;
}

export default function NumberAction(props: NumberActionProps) {
  // CONTEXTS
  const { theme } = useThemeContext();

  // STATES
  const [count, setCount] = useState(0);

  return (
    <Grid
      container
      alignItems="center"
      sx={{
        paddingTop: "2px",
        paddingBottom: "2px",
      }}
    >
      <Grid item xs={6}>
        <Stack direction="row" alignItems="center" spacing={2}>
          <Tooltip
            title={
              <Box>
                <Typography>{props.action.description}</Typography>
                <Typography>Credits: {props.action.credits}</Typography>
              </Box>
            }
            enterTouchDelay={0}
            placement="right"
            componentsProps={{
              tooltip: {
                sx: {
                  backgroundColor: theme.palette.primary.main,
                  maxWidth: "200px",
                },
              },
            }}
          >
            <InfoIcon />
          </Tooltip>
          <Typography variant="h6">{props.action.label}</Typography>
        </Stack>
      </Grid>
      <Grid item xs={4}>
        <IconButton
          disabled={count === 0}
          onClick={() => {
            props.setTotalCredits((prev) => prev - props.action.credits);
            setCount((prev) => prev - 1)
            let actions = [...props.actions]
            actions.splice(actions.findIndex(action => action.action = props.action.action), 1)
            props.setActions(actions)
          }}
        >
          <ArrowDropDownIcon />
        </IconButton>
        <IconButton
          onClick={() => {
            props.setTotalCredits((prev) => prev + props.action.credits);
            setCount((prev) => prev + 1)
            let actions = [...props.actions]
            actions.push(props.action)
            props.setActions(actions)
          }}
        >
          <ArrowDropUpIcon />
        </IconButton>
      </Grid>
      <Grid item xs={2}>
        <Typography sx={{ fontWeight: "bold" }}>{count}</Typography>
      </Grid>
    </Grid>
  );
}
