import { ConcacafIcon, ConfederationIcon, GlobeIcon, MidwestIcon } from "../../components/CustomIcons";
import { Divider, Grid, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";

import { CHICAGO_FIRE } from "../../constants";
import { GlobalFootballRank } from "../../api/types";
import { ReactComponent as MLSLogo } from "../../assets/logos/mls.svg";
import { RankingSubset } from "./RankingSubset";
import { ReactComponent as SuperLeagueLogo } from "../../assets/logos/swiss_super_league.svg";
import { ReactComponent as UefaLogo } from "../../assets/logos/uefa.svg";
import { useClubContext } from "../../contexts/ClubContext";

interface RankingsProps {
  data: GlobalFootballRank | undefined;
}

export default function Rankings(props: RankingsProps) {
  const theme = useTheme();
  const { club } = useClubContext();
  const isScreenSmall = useMediaQuery(theme.breakpoints.down("sm"));

  const logoStyle = { width: "2rem", height: "2rem" };

  let conferenceName = "UEFA";
  let competitionName = "Super League";

  if (club === CHICAGO_FIRE) {
    conferenceName = "CONFEDERATION";
    competitionName = "LEAGUE";
  }

  return (
    <Grid container direction="row" justifyContent="center" alignItems="center" spacing={1}>
      <Stack justifyContent="center" alignItems="center">
        {club === CHICAGO_FIRE &&
          <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
            <ConcacafIcon />

            <Typography variant="h4">
              {club === CHICAGO_FIRE ? "CONCACAF" : "" }
            </Typography>
          </Stack>
        }

        <Stack 
          direction={isScreenSmall ? "column" : "row"}
          justifyContent="center"
          alignItems="center"
        >
          {club === CHICAGO_FIRE && (
            <RankingSubset
              subset={"REGION"}
              ranking={props.data?.region}
              plusMinus={0}
              icon={<MidwestIcon />}
            />
          )}

          <RankingSubset
            subset={competitionName}
            ranking={props.data?.league}
            plusMinus={0}
            icon={
              club === CHICAGO_FIRE ? (
                <MLSLogo style={logoStyle} />
              ) : (
                <SuperLeagueLogo style={logoStyle} />
              )
            }
          />

          <RankingSubset
            subset={conferenceName}
            ranking={props.data?.confederation}
            plusMinus={0}
            icon={
                club === CHICAGO_FIRE ? (
                  <ConfederationIcon />
                ) : (
                  <UefaLogo style={logoStyle} />
                )
            }
          />

        </Stack>

        <Divider variant="middle" sx={{ width: "100%" }} />

        <Stack direction="row" spacing={1}>
          <RankingSubset
            subset={"GLOBAL FOOTBALL RANKING"}
            ranking={props.data?.global}
            plusMinus={0}
            icon={
              <GlobeIcon />
            }
          />
        </Stack>
      </Stack>
    </Grid>
  );
}
