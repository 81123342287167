import { createContext, useContext } from "react";

import APIClient from "../api/client";
import { useOktaAuth } from "@okta/okta-react";

interface APIContextInterface {
  apiClient: APIClient;
}

const defaultState = {
  apiClient: new APIClient(),
};

const APIContext = createContext<APIContextInterface>(defaultState);

export const APIProvider = ({children}: {children: React.ReactNode}) => {
  const { authState, oktaAuth } = useOktaAuth();

  const client = new APIClient({
    authState: authState,
    oktaAuth: oktaAuth,
  });

  return (
    <APIContext.Provider value={{ apiClient: client }}>
      {children}
    </APIContext.Provider>
  );
};

export const useAPIContext = () => useContext(APIContext);
