import { APPBAR_HEIGHT_AS_NUM } from "../app_bar/Toolbar";
import { Box } from "@mui/material";
import { TableauEmbed } from "@stoddabr/react-tableau-embed-live";
import { useAuth } from "../api/queries";
import { useWindowSizeContext } from "../contexts/WindowSizeContext";

export interface TableauDashboardParams {
    [key: string]: string
}

interface TableauDashboardProps {
    url: string
    params?: TableauDashboardParams
}

export default function TableauDashboard(props: TableauDashboardProps) {
    // CONTEXT
    const { height, width } = useWindowSizeContext();
    const auth = useAuth();
    const marginTop = 10;
    const marginLeftRight = 200;
    const effectiveHeight = height - APPBAR_HEIGHT_AS_NUM - marginTop - 6;

    return (
        auth.data?.tableau_jwt ?
            <Box
                id="tableau"
                key="tableauBox"
                sx={{ width: width - marginLeftRight, height: effectiveHeight }}
                flex={1}
                overflow="auto"
                mt={marginTop}
                ml='auto'
                mr='auto'
            >
                <TableauEmbed
                    key="tableauEmbed"
                    version="3.5.0"
                    sourceUrl={props.url}
                    width={width - marginLeftRight}
                    height={effectiveHeight}
                    token={auth.data?.tableau_jwt}
                >
                    { props.params && Object.keys(props.params).map((key : string) => {
                        // Capitalize first letter to match parameter casing
                        let value = props.params ? `${props.params[key]}` : '';
                        key = key.charAt(0).toUpperCase() + key.slice(1);
                        // console.debug(`${key}: ${value}`);

                        return (
                            <custom-parameter name={key} value={value} />
                        )
                    })}
                </TableauEmbed>       
            </Box>
        : <></>
    );
}
