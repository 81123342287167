import {
  ACADEMY_SCOUTING_ACADEMY_PLAYER_RATING_LABELS,
  ACADEMY_TEAM,
  GROUPINGS_LOWER_DIVISION,
  SCOUTING_ACADEMY_PLAYER_RATING_LABELS,
  SCOUTING_COLLEGE_PLAYER_RATING_LABELS,
  SCOUTING_MLSNP_PLAYER_RATING_LABELS,
  SCOUTING_MLS_PLAYER_RATING_LABELS,
  SCOUTING_REPORT_GROUP_ACADEMY,
  SCOUTING_REPORT_GROUP_COLLEGE,
  SCOUTING_USL_CANADA_PLAYER_RATING_LABELS,
} from "../../constants";

import { GridValueFormatterParams } from "@mui/x-data-grid-premium";

const getAcademyRatingLabel = (value: number) => {
  if (value >= 3) {
    return "Sign";
  } else if (value >= 2) {
    return "Trial";
  } else if (value >= 1) {
    return "Track";
  } else {
    return "No";
  }
};

const getRatingLabel = (team: string, value: number, ratings: string[]) => {
  return team === ACADEMY_TEAM ? getAcademyRatingLabel(value) : ratings[value];
};

const ratingFormatter = (team: string, value: number, ratings: string[]) => {
  const ratingLabel = getRatingLabel(team, value, ratings);
  const roundedValue =
    team === ACADEMY_TEAM ? Math.round(value * 100) / 100 : value;
  return ratingLabel ? `${ratingLabel} (${roundedValue})` : "No Rating";
};

const getScoutingRatings = (group: string): string[] => {
  let ratings: string[] = [...SCOUTING_MLS_PLAYER_RATING_LABELS];
  if (GROUPINGS_LOWER_DIVISION.includes(group)) {
    ratings = ratings.concat(
      ...SCOUTING_USL_CANADA_PLAYER_RATING_LABELS,
      ...SCOUTING_MLSNP_PLAYER_RATING_LABELS
    );
  } else if (SCOUTING_REPORT_GROUP_COLLEGE === group) {
    ratings = ratings.concat(
      ...SCOUTING_USL_CANADA_PLAYER_RATING_LABELS,
      ...SCOUTING_MLSNP_PLAYER_RATING_LABELS,
      SCOUTING_COLLEGE_PLAYER_RATING_LABELS
    );
  } else if (SCOUTING_REPORT_GROUP_ACADEMY === group) {
    ratings = ratings.concat(
      ...SCOUTING_USL_CANADA_PLAYER_RATING_LABELS,
      SCOUTING_MLSNP_PLAYER_RATING_LABELS,
      SCOUTING_COLLEGE_PLAYER_RATING_LABELS,
      SCOUTING_ACADEMY_PLAYER_RATING_LABELS
    );
  }

  return ratings;
};

export const GetRatings = (team: string, group: string): string[] => {
  return team === ACADEMY_TEAM
    ? ACADEMY_SCOUTING_ACADEMY_PLAYER_RATING_LABELS
    : getScoutingRatings(group);
};

export const GetRatingFormatter =
  (team: string, group: string) =>
  (params: GridValueFormatterParams<number>) => {
    return ratingFormatter(team, params.value, GetRatings(team, group));
  };

export const GetRatingLabel = (
  team: string,
  rating: number,
  group: string
): string => {
  return ratingFormatter(team, rating, GetRatings(team, group));
};

export const ordinalSuffixOf = (i: string): string => {
  let x: number = +i;
  let j = x % 10,
    k = x % 100;
  if (j === 1 && k !== 11) {
    return x + "st";
  }
  if (j === 2 && k !== 12) {
    return x + "nd";
  }
  if (j === 3 && k !== 13) {
    return x + "rd";
  }
  return x + "th";
};
