import { Box, Tooltip, Typography, useMediaQuery } from "@mui/material";
import EnhancedTableCell, { EnhancedHeadCell } from "./EnhancedTableCell";

import { CHICAGO_FIRE } from "../../constants";
import { Dispatch } from "react";
import { Order } from "../types";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { useClubContext } from "../../contexts/ClubContext";
import { useThemeContext } from "../../contexts/CustomThemeContext";
import { visuallyHidden } from "@mui/utils";

export interface EnhancedTableHeadProps<T> {
  headCells: EnhancedHeadCell<T>[];
  order: Order;
  sortKey: string;
  setOrder: (order: Order) => void;
  setSortKey: Dispatch<string>;
  colSpan?: number;
}

export function EnhancedTableHead<T>(props: EnhancedTableHeadProps<T>) {
  // CONTEXT
  const { club } = useClubContext();
  const { theme } = useThemeContext();
  const isScreenSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const isScreenMedium = useMediaQuery(theme.breakpoints.down("md"));

  const createSortHandler =
    (property: string) => (event: React.MouseEvent<unknown>) => {
      const isAsc = props.sortKey === property && props.order === "asc";
      props.setOrder(isAsc ? "desc" : "asc");
      props.setSortKey(property);
    };

  return (
    <TableHead>
      <TableRow>
        {props.headCells
          .filter(
            (headCell) =>
              !(club !== CHICAGO_FIRE && headCell.id === "disciplinary_points") && !(isScreenSmall && !headCell.displayOnSmallScreen) && !(isScreenMedium && !headCell.displayOnMediumScreen)
          )
          .map((headCell) => {
            
            return (
              <Tooltip
                key={headCell.label}
                arrow
                title={
                  <Box>
                    <Typography noWrap color="inherit">
                      {headCell.elongated}
                    </Typography>
                    {headCell.explanation}
                  </Box>
                }
              >
                <EnhancedTableCell
                  theme={theme}
                  key={headCell.id.toString()}
                  sortDirection={
                    props.sortKey === headCell.sortKey ? props.order : false
                  }
                  colSpan={headCell.colSpan ? headCell.colSpan : 1}
                >
                  <TableSortLabel
                    active={props.sortKey === headCell.sortKey}
                    direction={
                      props.sortKey === headCell.sortKey && props.order
                        ? props.order
                        : "asc"
                    }
                    onClick={createSortHandler(
                      headCell.sortKey ? headCell.sortKey : ""
                    )}
                    sx={{
                      margin: 0,
                      padding: 0,
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "row-reverse",
                    }}
                  >
                    {headCell.sortKey && props.sortKey === headCell.sortKey ? (
                      <Box component="span" sx={visuallyHidden}>
                        {props.order === "desc"
                          ? "sorted descending"
                          : "sorted ascending"}
                      </Box>
                    ) : null}
                    <Typography
                      variant={"body2"}
                      color={"#000000"}
                      fontWeight={"bold"}
                      pr={isScreenSmall ? 2 : 3}
                    >
                      {headCell.label}
                    </Typography>
                  </TableSortLabel>
                </EnhancedTableCell>
              </Tooltip>
            );
          })}
      </TableRow>
    </TableHead>
  );
}
