/* eslint-disable no-eval */

export const ROOT_ROUTE = "/";
export const ADMIN_ROUTE = "/admin";
export const COACHES_CORNER_ROUTE = "/coaches";
export const IDP_ROUTE = "/idp";
export const DATABASE_ROUTE = "/database";
export const EQUIPMENT_ROUTE = "/equipment";
export const LOGIN_CALLBACK_ROUTE = "/login/callback";
export const OPERATIONS_ROOT_ROUTE = "/operations";
export const PERFORMANCE_ROUTE = "/performance";
export const REPORTS_ROUTE = "/reports";
export const SCOUTING_ROOT_ROUTE = "/scouting";
export const SCOUTING_DEPTH_ROUTE = "/scouting/depth";
export const SCOUTING_SHORTLIST_ROUTE = "/scouting/shortlist";
export const SCOUTING_REPORTS_ROUTE = "/scouting/reports";
export const SCOUTING_TEAM_OF_THE_WEEK_ROUTE = "/scouting/totw";
export const SCOUTING_SCHEDULING = "/scouting/scheduling";
export const SEARCH_ROUTE = "/search";
export const WELCOME_ROUTE = "/welcome"
