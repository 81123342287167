import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
} from "@mui/material";
import { Dispatch, SetStateAction } from "react";

import { useDeleteSchedulingGameMutation } from "../../../api/mutations";
import { useSchedulingContext } from "../../../contexts/SchedulingContext";

export interface DeleteGameDialogProps {
  showDeleteGameDialog: boolean;
  setShowDeleteGameDialog: Dispatch<SetStateAction<boolean>>;
}

export default function DeleteGameDialog(props: DeleteGameDialogProps) {
  // CONTEXTS
  const { selectedGame, setSelectedGame } = useSchedulingContext();

  // MUTATIONS
  const deleteSchedulingGame = useDeleteSchedulingGameMutation();

  // FUNCTIONS
  const closeDialog = () => {
    props.setShowDeleteGameDialog(false);
    setSelectedGame(null);
  };

  const deleteGame = () => {
    if (selectedGame?.id) {
      deleteSchedulingGame.mutate(selectedGame.id);
      closeDialog();
    }
  };

  return (
    <Dialog open={props.showDeleteGameDialog} onClose={closeDialog}>
      <DialogTitle>Are you sure you want to delete this game?</DialogTitle>
      <DialogActions>
        <Button onClick={closeDialog}>Cancel</Button>
        <Button onClick={deleteGame}>Delete</Button>
      </DialogActions>
    </Dialog>
  );
}
