import CreateDialog from "../components/CreateDialog";
import CreateNewFolderTwoToneIcon from "@mui/icons-material/CreateNewFolderTwoTone";
import { Divider } from "@mui/material";
import { Folder } from "../api/types";
import FolderOfLists from "./FolderOfLists";
import { TreeItem } from "./TreeItem";
import { useCreateFolderMutation } from "../api/mutations";
import { useOktaAuthContext } from "../contexts/OktaAuthContext";
import { useState } from "react";

export default function ListsMenu(props: { folders: Folder[] | null }) {
  // CONTEXT
  const { email } = useOktaAuthContext();

  // STATE
  const [createFolderModalOpen, setCreateFolderModalOpen] = useState(false);

  // MUTATIONS
  const createFolder = useCreateFolderMutation();

  return (

      <TreeItem 
        itemId="LISTS"
        label="LISTS"
      >
        <CreateDialog
            key={"create-folder-dialog"}
            open={createFolderModalOpen}
            rows={4}
            title="Create Folder"
            label="Folder Name"
            setOpen={setCreateFolderModalOpen}
            yesAction={(newValue) => {
              createFolder.mutate({ name: newValue, owner: email } as Folder);
              setCreateFolderModalOpen(false);
            }}
        />

        <TreeItem 
            itemId="CREATE FOLDER" 
            label="CREATE FOLDER"
            onClick={
                (event) => {
                    setCreateFolderModalOpen(true);
                    event.stopPropagation();
                }
            }
            slots={{
                icon: () => {
                    return (
                        <CreateNewFolderTwoToneIcon
                          key={"create-new-folder-outlined-icon"}
                          fontSize="small"
                        />
                    )
                }
            }}
        />
        
        <Divider />

        {props.folders && props.folders.map((folder: Folder, index) => {
            return <FolderOfLists key={index} index={index} folder={folder} />;
        })}
      </TreeItem>
  );
}
