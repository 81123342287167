import { Backdrop } from "@mui/material";
import scoutingBackground from "../../assets/scouting_background.jpg";

interface LandingProps {
    groupingSelectEl: JSX.Element
    open: boolean,
}

const Landing = (props: LandingProps) => {
    // console.debug(open);

    return (
        <Backdrop    
            sx={{
                backgroundImage: `url(${scoutingBackground})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                zIndex: (theme) => theme.zIndex.appBar + 1
            }}
            open={props.open}
        >
            {props.groupingSelectEl}
        </Backdrop>
    );
}

export default Landing;
