// Taken from https://usehooks.com/useWindowSize/

import { createContext, useContext } from "react";
import { useEffect, useState } from "react";

interface WindowSize {
  width: number;
  height: number;
}

const defaultState = {
  width: 0,
  height: 0,
};

const WindowSizeContext = createContext<WindowSize>(defaultState);

export const WindowSizeProvider = ({children}: {children: React.ReactNode}) => {
  const [windowSize, setWindowSize] = useState({
    width: 0,
    height: 0,
  });

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    // Add event listener
    window.addEventListener("resize", handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return (
    <WindowSizeContext.Provider value={windowSize}>
      {children}
    </WindowSizeContext.Provider>
  );
};

export const useWindowSizeContext = () => useContext(WindowSizeContext);
