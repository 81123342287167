import { FIRST_TEAM, SECOND_TEAM } from "../constants";
// Taken from https://techinscribed.com/building-react-app-using-material-ui-with-support-for-multiple-switchable-themes/
import { createContext, useContext, useEffect, useState } from "react";

import FontDownloadIcon from "@mui/icons-material/FontDownload";
import LooksOneIcon from "@mui/icons-material/LooksOne";
import LooksTwoIcon from "@mui/icons-material/LooksTwo";
import { useUserContext } from "./UserContext";

const LOCAL_STORAGE_KEY = "team";

const teamInLocalStorage = localStorage.getItem(LOCAL_STORAGE_KEY);
const academyIconDim = 23;

let currIcon: JSX.Element;

if (teamInLocalStorage === FIRST_TEAM) {
  currIcon = <LooksOneIcon fontSize="small" />;
} else if (teamInLocalStorage === SECOND_TEAM) {
  currIcon = <LooksTwoIcon fontSize="small" />;
} else {
  currIcon = (
    <FontDownloadIcon
      fontSize="small"
      sx={{ ml: "1px", width: academyIconDim, height: academyIconDim }}
    />
  );
}

const defaultState = {
  icon: currIcon,
  team: teamInLocalStorage ? teamInLocalStorage : "",
  setTeam: (team: string) => {
    localStorage.setItem(LOCAL_STORAGE_KEY, team);
  },
};

interface TeamMenuContext {
  icon: JSX.Element;
  team: string;
  setTeam: (clubName: string) => void;
}

export const TeamContext = createContext<TeamMenuContext>(defaultState);

export const TeamProvider = ({children}: {children: React.ReactNode}) => {
  const { teams, primaryTeam } = useUserContext();
  const [team, _setTeam] = useState<string>(
    teamInLocalStorage ? teamInLocalStorage : ""
  );
  const [icon, setIcon] = useState(currIcon);

  const setTeam = (team: string): void => {
    localStorage.setItem(LOCAL_STORAGE_KEY, team);
    _setTeam(team);
    if (team === FIRST_TEAM) {
      currIcon = <LooksOneIcon fontSize="small" />;
    } else if (team === SECOND_TEAM) {
      currIcon = <LooksTwoIcon fontSize="small" />;
    } else {
      currIcon = (
        <FontDownloadIcon
          fontSize="small"
          sx={{ ml: "1px", width: academyIconDim, height: academyIconDim }}
        />
      );
    }

    setIcon(currIcon);
  };

  // If the current team in storage is an empty string, or we don't have permission
  // then fall back to the primary team
  useEffect(() => {
    // console.debug(team);
    // console.debug(teams);
    if (team === "" || (!teams.includes(team) && !teams.includes("*"))) {
      setTeam(primaryTeam);
    }
  }, [team, teams, primaryTeam]);

  const value = { team, icon, setTeam };

  return <TeamContext.Provider value={value}>{children}</TeamContext.Provider>;
};

export const useTeamContext = () => useContext(TeamContext);
