import { Box, alpha, useMediaQuery, useTheme } from "@mui/material";
import {
  DataGridPremium,
  GridCallbackDetails,
  GridFilterModel,
  GridRowParams,
  GridToolbarContainer,
  GridToolbarQuickFilter,
  MuiEvent,
  useGridApiRef,
  useKeepGroupedColumnsHidden,
} from "@mui/x-data-grid-premium";
import { Dispatch, SetStateAction, useCallback, useEffect } from "react";
import {
  GROUPING_COL,
  ScoutedPlayersDataGridCols,
} from "../../common/DataGridColDefs";

import { ACADEMY_TEAM } from "../../constants";
import { APPBAR_HEIGHT_AS_NUM } from "../../app_bar/Toolbar";
import GridEmptyOverlay from "../../components/datagrid/GridEmptyOverlay";
import { ScoutedPlayer } from "../../api/types";
import useLocalStorage from "react-use-localstorage";
import { useScoutedPlayers } from "../../api/queries";
import { useTeamContext } from "../../contexts/TeamContext";
import { useWindowSizeContext } from "../../contexts/WindowSizeContext";

interface ScoutingDatabaseProps {
  filterModel: GridFilterModel | undefined;
  setFilterModel: Dispatch<SetStateAction<GridFilterModel | undefined>>;
  scoutedPlayerInContext: ScoutedPlayer | null | undefined;
  setScoutedPlayerInContext: Dispatch<
    SetStateAction<ScoutedPlayer | null | undefined>
  >;
  setScoutedPlayerProfileModalOpen: Dispatch<SetStateAction<boolean>>;
  groupingSelectEl: JSX.Element;
  grouping: string;
}

// Moving functional component to outside main component remedies issue of search bar unfocusing each time
// input is updated. This was due to the Toolbar rerendering each time the value changed which causes the focus to be lost
//
// https://github.com/mui/mui-x/issues/9580
function CustomToolbar(props: any) {
  return (
    <GridToolbarContainer {...props} sx={{ mt: 1 }}>
      {props.groupingSelectEl}
      <GridToolbarQuickFilter />
    </GridToolbarContainer>
  );
}

function ScoutingDatabase(props: ScoutingDatabaseProps) {
  // CONTEXT
  const theme = useTheme();
  const isScreenSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const { height } = useWindowSizeContext();
  const { team } = useTeamContext();
  const apiRef = useGridApiRef();

  const colVisibility = {
    birth_year: false,
    contact_info: false,
    date: false,
    domestic: false,
    first_name: false,
    foot: false,
    grouping: false,
    kit_number: false,
    last_name: false,
    location: team === ACADEMY_TEAM,
    other_contact_info: false,
    player_name: team !== ACADEMY_TEAM,
    rating: team === ACADEMY_TEAM,
    scout: team === ACADEMY_TEAM,
    year_in_school: false,
  };

  const academyInitialState = useKeepGroupedColumnsHidden({
    apiRef: apiRef,
    initialState: {
      aggregation: {
        model: {
          rating: "avg",
        },
      },
      columns: {
        // Other hidden columns
        columnVisibilityModel: colVisibility,
      },
      sorting: {
        sortModel: [{ field: "grouping", sort: "desc" }],
      },
    },
  });

  const initialState = useKeepGroupedColumnsHidden({
    apiRef: apiRef,
    initialState: {
      columns: {
        // Other hidden columns
        columnVisibilityModel: colVisibility,
      },
      rowGrouping: {
        model: [],
      },
      sorting: {
        sortModel: [{ field: "date", sort: "desc" }],
      },
    },
  });

  // STATE
  const storageKey = `col_vis_model_for_${team}_scouting_db`;
  const [storedColumnVisibilityModel, setStoredColumnVisibilityModel] =
    useLocalStorage(storageKey, JSON.stringify(colVisibility));
  const columnVisibilityModel = JSON.parse(storedColumnVisibilityModel);

  // QUERIES
  const scoutedPlayersQueryResult = useScoutedPlayers();

  // FUNCTIONS
  // const getGroupRows = ({ apiRef }: GridCsvGetRowsToExportParams) => {
  //   const rows = gridFilteredSortedRowIdsSelector(apiRef);
  //   const tree = gridRowTreeSelector(apiRef);

  //   return rows.filter((rowId) => {
  //     return tree[rowId].type === "group" && tree[rowId].depth > 0;
  //   });
  // };

  const showScoutedPlayerProfileModal = useCallback(
    (
      params: GridRowParams,
      event: MuiEvent<React.MouseEvent>,
      details: GridCallbackDetails
    ) => {
      //   console.debug(params.row);
      props.setScoutedPlayerInContext(params.row as ScoutedPlayer);
      props.setScoutedPlayerProfileModalOpen(true);
    },
    [props]
  );

  const onFilterModelChange = useCallback(
    (model: GridFilterModel, details: GridCallbackDetails) => {
      //   console.debug(model);
      props.setFilterModel(model);
    },
    [props]
  );

  // EFFECTS
  useEffect(() => {
    // console.debug(scoutingReportsQueryResult.data)

    if (props.scoutedPlayerInContext) {
      // console.debug(scoutingReportsQueryResult.data)
      let p = scoutedPlayersQueryResult?.data?.find(
        (scoutedPlayer: ScoutedPlayer) => {
          return scoutedPlayer.id === props.scoutedPlayerInContext?.id;
        }
      );

      if (p) {
        props.setScoutedPlayerInContext(p);
      }
    }
  }, [props, scoutedPlayersQueryResult.data]);

  const emptyOverlayProps = {
    content: "No Players Found!",
  };

  return (
    <Box style={{ height: height - APPBAR_HEIGHT_AS_NUM - 5 }}>
      <DataGridPremium
        apiRef={apiRef}
        aria-label={"scouting-reports-datagrid"}
        autoPageSize
        columns={ScoutedPlayersDataGridCols()}
        columnVisibilityModel={columnVisibilityModel}
        density={isScreenSmall ? "compact" : "standard"}
        disableRowSelectionOnClick={true}
        filterModel={props.filterModel}
        getRowId={(row) => row.id}
        groupingColDef={GROUPING_COL}
        hideFooter={scoutedPlayersQueryResult?.data?.length === 0}
        initialState={
          team === ACADEMY_TEAM ? academyInitialState : initialState
        }
        key={`scouting-reports-database-${team}`}
        loading={scoutedPlayersQueryResult.isLoading}
        onColumnVisibilityModelChange={(newModel) =>
          setStoredColumnVisibilityModel(JSON.stringify(newModel))
        }
        onFilterModelChange={onFilterModelChange}
        onRowClick={showScoutedPlayerProfileModal}
        getRowClassName={(params) => {
          return params.row.domestic === false ? "foreign-player" : "";
        }}
        pagination
        rows={
          scoutedPlayersQueryResult.data ? scoutedPlayersQueryResult.data : []
        }
        rowThreshold={0}
        // showCellVerticalBorder
        // showColumnVerticalBorder
        slots={{
          loadingOverlay: GridEmptyOverlay,
          noResultsOverlay: GridEmptyOverlay,
          noRowsOverlay: GridEmptyOverlay,
          toolbar: CustomToolbar,
        }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
            groupingSelectEl: props.groupingSelectEl,
            quickFilterProps: { debounceMs: 1000 },
          },
          noResultsOverlay: { content: "No Results Found!" },
          noRowsOverlay: emptyOverlayProps,
        }}
        sx={{
          //   '--DataGrid-cellOffsetMultiplier': 6,
          border: 0,
          "& .MuiButton-text": {
            fontSize: isScreenSmall ? 13 : 16,
          },
          ".foreign-player": {
            backgroundColor: alpha(theme.palette.secondary.main, 0.7),
            ":hover": {
              backgroundColor: alpha(theme.palette.secondary.main, 0.8),
            },
          },
        }}
        ignoreDiacritics
      />
    </Box>
  );
}

export default ScoutingDatabase;
