import "./styles.scss";

import {
  Divider,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { ScoutedPlayer, ScoutingReport } from "../../api/types";

import Rating from "../../components/Rating";
import { formatDateToLocalWithoutTimeYearFirst } from "../../utils/dates";
import { useScoutedPlayerScoutingReports } from "../../api/queries";
import { useScoutingReportDialogContext } from "../../contexts/ScoutingReportDialogContext";

interface ScoutingReportsTableProps {
  scoutedPlayer: ScoutedPlayer;
}

export default function ScoutingReportsTable(props: ScoutingReportsTableProps) {
  const scoutingReportsQuery = useScoutedPlayerScoutingReports(
    props.scoutedPlayer.id
  );
  const { handleReportClick } = useScoutingReportDialogContext();
  const scoutingReportCount = scoutingReportsQuery?.data?.length
    ? scoutingReportsQuery.data.length - 1
    : 0;
  const textAlign = "center";

  return (
    <TableContainer component={Paper}>
      <Table
        stickyHeader
        aria-label="simple table"
        size="small"
        sx={{ border: "0px" }}
        className="scouting-reports-table"
      >
        <TableHead>
          <TableRow>
            <TableCell align={textAlign}>Group</TableCell>
            <TableCell align={textAlign}>Date</TableCell>
            <TableCell align={textAlign}>Rating</TableCell>
            <TableCell align={textAlign}>Projected</TableCell>
            <TableCell align={textAlign}>Scout</TableCell>
          </TableRow>
        </TableHead>

        {scoutingReportsQuery?.data?.map(
          (report: ScoutingReport, i: number) => (
            <TableBody key={`${i}-body`}>
              <TableRow
                key={`${i}-row`}
                hover
                sx={{ cursor: "pointer", border: "0px" }}
                onClick={() => {
                  handleReportClick(props.scoutedPlayer, report);
                }}
              >
                <TableCell align={textAlign} sx={{ border: "0px" }}>
                  {report.grouping}
                </TableCell>
                <TableCell align={textAlign} sx={{ border: "0px" }}>
                  {formatDateToLocalWithoutTimeYearFirst(report.date)}
                </TableCell>
                <TableCell align={textAlign} sx={{ border: "0px" }}>
                  <Rating
                    team={report.team}
                    value={report.rating}
                    textAlign={textAlign}
                    isProjected={false}
                  />
                </TableCell>
                <TableCell align={textAlign} sx={{ border: "0px" }}>
                  <Rating
                    team={report.team}
                    value={report.projected_rating}
                    textAlign={textAlign}
                    isProjected={true}
                  />
                </TableCell>
                <TableCell align={textAlign} sx={{ border: "0px" }}>
                  {report.scout}
                </TableCell>
                {/* {report.notes && (
                  <TableCell
                    colSpan={6}
                    sx={{ textAlign: textAlign, colspan: 3, border: "0px" }}
                  >
                    {report.notes}
                  </TableCell>
                )} */}
              </TableRow>

              {/* <TableRow
                key={`${i}-notes`}
                hover
                sx={{ cursor: "pointer", border: "0px" }}
                onClick={() => {
                  // handleReportClick(props.scoutedPlayer, report);
                }}
              ></TableRow> */}

              {i < scoutingReportCount && (
                <TableRow
                  key={`${i}-divider`}
                  sx={{ border: "0px", padding: "0" }}
                >
                  <TableCell colSpan={5} sx={{ border: "0px", padding: "0" }}>
                    <Divider variant="fullWidth" />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          )
        )}
      </Table>
    </TableContainer>
  );
}
