import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { Dispatch, SetStateAction } from "react";
import { UseMutationResult } from "react-query";

export interface DeleteWeekDialogProps {
  week: any | null;
  deleteWeekMutation: UseMutationResult;
  showDeleteWeekDialog: boolean;
  setShowDeleteWeekDialog: Dispatch<SetStateAction<boolean>>;
  contentMessage?: string;
}

export default function DeleteWeekDialog(props: DeleteWeekDialogProps) {
  const deleteWeek = () => {
    if (props?.week?.id) {
      props.deleteWeekMutation.mutate(props.week.id);
      props.setShowDeleteWeekDialog(false);
    }
  };

  return (
    <Dialog
      open={props.showDeleteWeekDialog}
      onClose={() => props.setShowDeleteWeekDialog(false)}
    >
      <DialogTitle>
        Are you sure you want to delete{" "}
        <span style={{ fontWeight: 600, textDecoration: "underline" }}>
          {props?.week?.label}
        </span>
        ?
      </DialogTitle>
      {props.contentMessage && (
        <DialogContent>{props.contentMessage}</DialogContent>
      )}
      <DialogActions>
        <Button onClick={() => props.setShowDeleteWeekDialog(false)}>
          Cancel
        </Button>
        <Button onClick={deleteWeek}>Delete</Button>
      </DialogActions>
    </Dialog>
  );
}
