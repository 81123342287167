import {
  Avatar,
  SwipeableDrawer,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import {
  CHICAGO_FIRE,
  CHICAGO_FIRE_2,
  FIRST_TEAM,
  LUGANO,
  MLS,
  MLS_NEXT_PRO,
  SECOND_TEAM,
  SSL,
  TEAM_NAME_ABBRS,
} from "../../constants";
import EnhancedTableCell, {
  EnhancedHeadCell,
  TableCellsFunc,
} from "../enhanced_table/EnhancedTableCell";

import Box from "@mui/material/Box";
import CloseButton from "../CloseButton";
import EnhancedTable from "../enhanced_table/EnhancedTable";
import { Order } from "../types";
import { TableEntry } from "../../api/types";
import TeamDashboard from "./TeamDashboard";
import { stickyColumnStyle } from "../enhanced_table/utils";
import { useClubContext } from "../../contexts/ClubContext";
import { useLeagueTable } from "../../api/queries";
import { useState } from "react";
import { useTeamContext } from "../../contexts/TeamContext";
import { useThemeContext } from "../../contexts/CustomThemeContext";

export const headCells : EnhancedHeadCell<TableEntry>[] = [
    {
      id: "points_rank",
      displayOnSmallScreen: true,
      displayOnMediumScreen: true,
      numeric: false,
      label: "Rank",
      elongated: "League Rank",
      explanation: "Team rank in the league",
      sortKey: "points",
    },
    {
      id: "name",
      displayOnSmallScreen: true,
      displayOnMediumScreen: true,
      numeric: false,
      label: "Team",
      elongated: "Team Name",
      explanation: "The name of the team",
      sortKey: "name",
      colSpan: 2,
    },
    {
      id: "goals_for",
      displayOnSmallScreen: false,
      displayOnMediumScreen: true,
      numeric: true,
      label: "GF",
      elongated: "Goals For",
      explanation: "Goals For",
      sortKey: "goals_for",
    },
    {
      id: "goals_against",
      displayOnSmallScreen: false,
      displayOnMediumScreen: true,
      numeric: true,
      label: "GA",
      elongated: "Goals Against",
      explanation: "Goals Against",
      sortKey: "goals_against",
    },
    {
      id: "goal_diff",
      displayOnSmallScreen: false,
      displayOnMediumScreen: true,
      numeric: true,
      label: "GD",
      elongated: "Goals Difference",
      explanation: "Goal Difference",
      sortKey: "goal_diff",
    },
    {
      id: "np_xg_for",
      displayOnSmallScreen: true,
      displayOnMediumScreen: true,
      numeric: true,
      label: "xG",
      elongated: "Expected Goals For",
      explanation: "Cumulative expected goal value of all non-penalty shots",
      sortKey: "np_xg_for",
    },
    {
      id: "np_xg_against",
      displayOnSmallScreen: true,
      displayOnMediumScreen: true,
      numeric: true,
      label: "xGA",
      elongated: "Expected Goals Against",
      explanation:
        "Cumulative expected goal value of all non-penalty shots conceded",
      sortKey: "np_xg_against",
    },
    {
      id: "np_xg_diff",
      displayOnSmallScreen: false,
      displayOnMediumScreen: true,
      numeric: true,
      label: "xGD",
      elongated: "Expected Goal Difference",
      explanation: "xG minus xGa (not including penalties)",
      sortKey: "np_xg_diff",
    },
    {
      id: "ns_xg_for",
      displayOnSmallScreen: false,
      displayOnMediumScreen: false,
      numeric: true,
      label: "nsxG",
      elongated: "Non-shot Expected Goals For",
      explanation:
        "On Ball Value Added (net) from Passes plus On Ball Value Added (net) from Dribbles and Carries",
      sortKey: "ns_xg_for",
    },
    {
      id: "ns_xg_against",
      displayOnSmallScreen: false,
      displayOnMediumScreen: false,
      numeric: true,
      label: "nsxGA",
      elongated: "Non-Shot Expected Goals Against",
      explanation:
        "Opposition On Ball Value Added (net) from Passes plus Opposition On Ball Value Added (net) from Dribbles and Carries",
      sortKey: "ns_xg_against",
    },
    {
      id: "ns_xg_diff",
      displayOnSmallScreen: false,
      displayOnMediumScreen: false,
      numeric: true,
      label: "nsxGD",
      elongated: "Non-Shot Expected Goal Difference",
      explanation: "ns-xGf minus ns-xGa",
      sortKey: "ns_xg_diff",
    },
    {
      id: "yellow_cards_pg",
      displayOnSmallScreen: false,
      displayOnMediumScreen: false,
      numeric: true,
      label: "YC",
      elongated: "Yellow Cards per Game",
      explanation: "How many yellow cards the team obtains",
      sortKey: "yellow_cards_pg",
    },
    {
      id: "red_cards_pg",
      displayOnSmallScreen: false,
      displayOnMediumScreen: false,
      numeric: true,
      label: "RC",
      elongated: "Red Cards per Game",
      explanation: "How many red cards the team obtains",
      sortKey: "red_cards_pg",
    },
    {
      id: "points",
      displayOnSmallScreen: true,
      displayOnMediumScreen: true,
      numeric: true,
      label: "P",
      elongated: "Points",
      explanation: "Total points earned",
      sortKey: "points",
    }
];

export default function LeagueTableDashboard() {
  // CONTEXT
  const { club } = useClubContext();
  const { team } = useTeamContext();
  const { theme } = useThemeContext();

  // console.debug(`club: ${club} | team: ${team}`);

  // STATE
  const [order, setOrder] = useState<Order>("desc");
  const [sortKey, setSortKey] = useState<string>("points");
  const [statsDrawerOpen, setStatsDrawerOpen] = useState(false);
  const [selectedClub, setSelectedClub] = useState(club);

  // QUERIES
  const leagueTableQuery = useLeagueTable();

  // VARIABLES
  const isScreenSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const isScreenMedium = useMediaQuery(theme.breakpoints.down("md"));
  let conferences = [];
  let teamName = "";

  if (club === CHICAGO_FIRE && team === FIRST_TEAM) {
    conferences = [MLS];
    teamName = CHICAGO_FIRE;
  } else if (club === CHICAGO_FIRE && team === SECOND_TEAM) {
    conferences = [MLS_NEXT_PRO]; // this should really be MLS_NEXT
    teamName = CHICAGO_FIRE_2;
  } else {
    conferences = [SSL];
    teamName = LUGANO;
  }

  const tableCells: TableCellsFunc<TableEntry> = (
    teamTableEntry: TableEntry
  ): JSX.Element => {
    const teamNameAbbr =
      teamTableEntry.name in TEAM_NAME_ABBRS
        ? TEAM_NAME_ABBRS[teamTableEntry.name]
        : "?";
    const extension = club === LUGANO ? "svg" : "png";
    const isSecondTeam =
      club === CHICAGO_FIRE && team === SECOND_TEAM ? "2" : "";
    const avatarSrc = `/logos/${teamNameAbbr.toLowerCase()}${isSecondTeam}.${extension}`;
    const maxWidth = club === LUGANO ? "230px" : "210px";

    return (
      <>
        <EnhancedTableCell align="center" scope="row">
          <Typography variant="caption">
            {teamTableEntry.points_rank}
          </Typography>
        </EnhancedTableCell>

        <EnhancedTableCell
          align="right"
          scope="row"
          sx={{
            ...stickyColumnStyle(theme),
          }}
        >
          <Avatar
            src={avatarSrc}
            sx={{ width: 32, height: 32, marginRight: 1 }}
          />
        </EnhancedTableCell>

        <EnhancedTableCell
          align="left"
          scope="row"
          sx={{ maxWidth: maxWidth, cursor: "pointer" }}
          onClick={() => {
            setSelectedClub(teamTableEntry.name);
            setStatsDrawerOpen(true);
          }}
        >
          <Typography
            noWrap
            variant="overline"
            fontWeight={"bold"}
            color={theme.palette.primary.main}
            sx={{ textOverflow: "ellipsis" }}
          >
            {isScreenSmall || isScreenMedium ? teamNameAbbr : teamTableEntry.name}
          </Typography>
        </EnhancedTableCell>

        {!isScreenSmall &&
          <EnhancedTableCell align="center" scope="row">
            <Tooltip
                disableFocusListener
                key={teamTableEntry.goals_for_rank}
                title={teamTableEntry.goals_for_rank}
            >
                <>
                    {teamTableEntry.goals_for_rank}
                </>
            </Tooltip>
          </EnhancedTableCell>
        }

        {!isScreenSmall &&
          <EnhancedTableCell align="center" scope="row">
            <Tooltip
                disableFocusListener
                key={teamTableEntry.goals_against_rank}
                title={teamTableEntry.goals_against_rank}
            >
                <>
                    {teamTableEntry.goals_against_rank}
                </>
            </Tooltip>
          </EnhancedTableCell>
        }

        {!isScreenSmall &&
          <EnhancedTableCell align="center" scope="row">
            <Tooltip
                disableFocusListener
                key={teamTableEntry.goal_diff_rank}
                title={teamTableEntry.goal_diff_rank}
            >
                <>
                    {teamTableEntry.goal_diff_rank}
                </>
            </Tooltip>
          </EnhancedTableCell>
        }

        <EnhancedTableCell align="center" scope="row">
          <Tooltip
            disableFocusListener
            key={teamTableEntry.np_xg_for_rank}
            title={teamTableEntry.np_xg_for_rank}
          >
            <>
                {teamTableEntry.np_xg_for_rank}
            </>
          </Tooltip>
        </EnhancedTableCell>

        <EnhancedTableCell align="center" scope="row">
          <Tooltip
            disableFocusListener
            key={teamTableEntry.np_xg_against_rank}
            title={teamTableEntry.np_xg_against_rank}
          >
            <>
              {teamTableEntry.np_xg_against_rank}
            </>
          </Tooltip>
        </EnhancedTableCell>

        {!isScreenSmall &&
          <EnhancedTableCell align="center" scope="row">
            <Tooltip
                disableFocusListener
                key={teamTableEntry.np_xg_diff_rank}
                title={teamTableEntry.np_xg_diff_rank}
            >
                <>
                    {teamTableEntry.np_xg_diff_rank}
                </>
            </Tooltip>
          </EnhancedTableCell>
        }

        {!isScreenSmall && !isScreenMedium &&
          <EnhancedTableCell align="center" scope="row">
            <Tooltip
                disableFocusListener
                key={teamTableEntry.ns_xg_for_rank}
                title={teamTableEntry.ns_xg_for_rank}
            >
                <>
                    {teamTableEntry.ns_xg_for_rank}
                </>
            </Tooltip>
          </EnhancedTableCell>
        }

        {!isScreenSmall && !isScreenMedium &&
          <EnhancedTableCell align="center" scope="row">
            <Tooltip
                disableFocusListener
                key={teamTableEntry.ns_xg_against_rank}
                title={teamTableEntry.ns_xg_against_rank}
            >
                <>
                    {teamTableEntry.ns_xg_against_rank}
                </>
            </Tooltip>
          </EnhancedTableCell>
        }

        {!isScreenSmall && !isScreenMedium &&
          <EnhancedTableCell align="center" scope="row">
            <Tooltip
                disableFocusListener
                key={teamTableEntry.ns_xg_diff_rank}
                title={teamTableEntry.ns_xg_diff_rank}
            >
                <>
                    {teamTableEntry.ns_xg_diff_rank}
                </>
            </Tooltip>
          </EnhancedTableCell>
        }

        {!isScreenSmall && !isScreenMedium &&
          <EnhancedTableCell align="center" scope="row">
            <Tooltip
                disableFocusListener
                key={teamTableEntry.yellow_cards_pg_rank}
                title={teamTableEntry.yellow_cards_pg_rank}
            >
                <>
                    {teamTableEntry.yellow_cards_pg_rank}
                </>
            </Tooltip>
          </EnhancedTableCell>
        }

        {!isScreenSmall && !isScreenMedium &&
          <EnhancedTableCell align="center" scope="row">
            <Tooltip
                disableFocusListener
                key={teamTableEntry.red_cards_pg_rank}
                title={teamTableEntry.red_cards_pg_rank}
            >
                <>
                    {teamTableEntry.red_cards_pg_rank}
                </>
            </Tooltip>
          </EnhancedTableCell>
        }

        <EnhancedTableCell align="center" scope="row">
          {teamTableEntry.points}
        </EnhancedTableCell>
      </>
    );
  };

  return (
    <Box>
      <SwipeableDrawer
        key={"league-table-stats-drawer"}
        anchor={"bottom"}
        open={statsDrawerOpen}
        onOpen={() => {}}
        onClose={() => setStatsDrawerOpen(false)}
        sx={{
          zIndex: (theme) => theme.zIndex.modal + 1,
        }}
      >
        <Box
          key={"league-table-stats-box"}
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <CloseButton
            key={"league-stats-drawer-box-close-btn"}
            position="absolute"
            top={5}
            left={5}
            onClick={() => setStatsDrawerOpen(false)}
          />
          <TeamDashboard club={selectedClub} />
        </Box>
      </SwipeableDrawer>

      {conferences.map((conference, i) => {
        const entries =
          leagueTableQuery &&
          leagueTableQuery?.data &&
          leagueTableQuery.data[conference];
        
        const selectedRow = entries?.find(
          (entry: TableEntry) => entry.name === teamName
        );

        return (
          <Box key={`box_${i}`}>
            {entries && (
              <EnhancedTable
                data={entries}
                headCells={headCells}
                height={"50vh"}
                tableCells={tableCells}
                order={order}
                setOrder={setOrder}
                sortKey={sortKey}
                setSortKey={setSortKey}
                selectedRow={selectedRow}
              />
            )}
          </Box>
        );
      })}
    </Box>
  );
}
