import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import { DialogContent } from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";

export type AlertDialogProps = {
  open: boolean;
  title: string;
  content?: string;
  yesAction: () => void;
  noAction: () => void;
};

export default function ConfirmationDialog(props: AlertDialogProps) {
  return (
    <Dialog
      open={props.open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      onClick={(event) => {
        event.stopPropagation();
      }}
    >
      <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>

      {props.content && (
        <DialogContent id="alert-dialog-content">{props.content}</DialogContent>
      )}

      <DialogActions>
        <Button
          onClick={() => {
            props.yesAction();
          }}
        >
          Yes
        </Button>
        <Button
          autoFocus
          onClick={() => {
            props.noAction();
          }}
        >
          No
        </Button>
      </DialogActions>
    </Dialog>
  );
}
