import { Dispatch, SetStateAction, useState } from "react";
import {
  ListItem,
  ListItemText,
  Stack,
  Tooltip,
  Typography,
  alpha,
} from "@mui/material";
import { ScoutedPlayer, ScoutingReport } from "../../api/types";

import ClearIcon from "@mui/icons-material/Clear";
import { SCOUTING_REPORT_GROUP_COLLEGE } from "../../constants";
import { useDeleteTotwPlayerMutation } from "../../api/mutations";
import { useThemeContext } from "../../contexts/CustomThemeContext";
import { useTotwContext } from "../../contexts/TotwContext";

const PLAYER_LIST_MAX_WIDTH = 250;

interface ScoutingReportItemProps {
  report: ScoutingReport | null | undefined;
  grouping: string;
  index: number;
  sortedPlayersIndex: number;
  isScreenSmall: boolean;
  playerInfo: string;
  editMode: boolean;
  setScoutedPlayerInContext: Dispatch<
    SetStateAction<ScoutedPlayer | null | undefined>
  >;
  setScoutedPlayerProfileModalOpen: Dispatch<SetStateAction<boolean>>;
}

export default function ScoutingReportItem(props: ScoutingReportItemProps) {
  // CONTEXT
  const { theme } = useThemeContext();
  const { selectedWeek } = useTotwContext();

  // STATE
  const [hoveredOnReport, setHoveredOnReport] = useState<boolean>(false);
  const [hoveredOnRemove, setHoveredOnRemove] = useState<boolean>(false);

  // MUTATIONS
  const deleteTotwPlayer = useDeleteTotwPlayerMutation();

  // VARIABLES
  const BORDER = `1px solid ${theme.palette.primary.main}`;

  // FUNCTIONS
  const handleRemove = () => {
    if (selectedWeek?.id && props.report) {
      let player = {
        scouting_report_id: props.report.id,
        week_id: selectedWeek.id,
      };

      deleteTotwPlayer.mutate({
        weekId: selectedWeek.id,
        players: [player],
      });
    }
  };

  return (
    <ListItem
      dense
      disableGutters
      sx={{
        backgroundColor:
          props.grouping === SCOUTING_REPORT_GROUP_COLLEGE &&
          !props.report?.domestic
            ? alpha(theme.palette.secondary.main, 0.7)
            : "white",
        borderBottom: props.index === props.sortedPlayersIndex - 1 ? 0 : BORDER,
        maxWidth: PLAYER_LIST_MAX_WIDTH,
      }}
    >
      <Tooltip
        disableFocusListener
        describeChild
        open={!hoveredOnRemove && hoveredOnReport}
        placement="right"
        key={`${props.report?.id}-${props.report?.first_name}-${props.report?.last_name}-tooltip`}
        title={"Click to View Player Profile"}
      >
        <ListItemText
          // primary={`${report.first_name} ${report.last_name} | ${Math.round(report.rating * 100) / 100}`}
          // primaryTypographyProps={{ noWrap: true, variant: 'caption', fontSize: isScreenSmall ? 9 : 11 }}
          onClick={() => {
            if (!hoveredOnRemove) {
              props.setScoutedPlayerInContext({} as ScoutedPlayer);
              props.setScoutedPlayerProfileModalOpen(true);
            }
          }}
          onMouseEnter={() => {
            setHoveredOnReport(true);
          }}
          onMouseLeave={() => {
            setHoveredOnReport(false);
          }}
          sx={{
            margin: 0,
            padding: 0,
            paddingLeft: 1,
            paddingRight: 1,
            cursor: "pointer",
          }}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            minWidth={125}
          >
            {props.report && (
              <>
                {props.grouping !== SCOUTING_REPORT_GROUP_COLLEGE && (
                  <>
                    <Typography
                      noWrap
                      variant="caption"
                      sx={{ fontSize: props.isScreenSmall ? 9 : 11 }}
                    >
                      {props.report?.first_name} {props.report?.last_name}
                    </Typography>

                    <Typography
                      variant="caption"
                      sx={{ fontSize: props.isScreenSmall ? 9 : 11 }}
                    >
                      {Math.round(props.report?.rating * 100) / 100}
                    </Typography>
                  </>
                )}

                {props.grouping === SCOUTING_REPORT_GROUP_COLLEGE && (
                  <>
                    <Typography
                      noWrap
                      variant="caption"
                      sx={{
                        fontSize: props.isScreenSmall ? 9 : 11,
                      }}
                    >
                      {props.playerInfo}
                    </Typography>
                    {props.editMode && (
                      <div
                        onClick={() => handleRemove()}
                        onMouseEnter={() => setHoveredOnRemove(true)}
                        onMouseLeave={() => setHoveredOnRemove(false)}
                        style={{ display: "flex" }}
                      >
                        <ClearIcon
                          sx={{
                            height: 16,
                            width: 16,
                          }}
                        />
                      </div>
                    )}
                  </>
                )}
              </>
            )}
          </Stack>
        </ListItemText>
      </Tooltip>
    </ListItem>
  );
}
