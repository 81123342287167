import { Box, List, Menu } from "@mui/material";

import ActionsMenuItem from "../../../components/ActionsMenuItem";
import DeleteIcon from "@mui/icons-material/Delete";
// import EditIcon from "@mui/icons-material/Edit";
import { ScoutingReport } from "../../../api/types";
import { SetStateAction } from "react";

interface ScoutingReportActionsMenuProps {
  anchorEl: Element | null;
  open: boolean;
  report: ScoutingReport | undefined;
  handleActionMenuClose: () => void;
  openMutateDialog: () => void;
  setOpen: (value: SetStateAction<boolean>) => void;
  setDeleteReportModalOpen: () => void;
}

export const ScoutingReportActionsMenu = (
  props: ScoutingReportActionsMenuProps
) => {
  return (
    <Box key={`${props?.report?.id}-list-box`}>
      <Menu
        key={`${props?.report?.id}-menu`}
        aria-labelledby="actions-menu"
        anchorEl={props.anchorEl}
        open={props.open}
        onClose={props.handleActionMenuClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <List key={`${props?.report?.id}-menu-list`}>
          {/* <ActionsMenuItem
            uniqueKey={`${props?.report?.id}-edit-report`}
            icon={
              <EditIcon
                color="primary"
                fontSize="small"
                key={`${props?.report?.id}-edit-scouting-report-button`}
              />
            }
            text="Edit"
            onClick={props.openMutateDialog}
          /> */}

          <ActionsMenuItem
            uniqueKey={`${props?.report?.id}-delete-report`}
            icon={
              <DeleteIcon
                color="primary"
                key={`${props?.report?.id}-delete-scouting-report`}
                fontSize="small"
              />
            }
            text="Delete"
            onClick={props.setDeleteReportModalOpen}
          />
        </List>
      </Menu>
    </Box>
  );
};
