import moment from "moment-timezone";

const TZ = moment.tz.guess();
const MON_DD_YYYY_TIME_AMPM_FORMAT = "lll";
const MMMM_Do_YYYY_FORMAT = "MMMM Do, YYYY";
const MM_DD_YYYY_h_mm_FORMAT = "MM/DD/YYYY h:mm a";
const MM_DD_YYYY_h_mm_ss_FORMAT = "MM/DD/YYYY h:mm:ss a";
const MON_DD_YYYY_FORMAT = "MM/DD/YYYY";
const YYYY_MM_DD_FORMAT = "YYYY-MM-DD";

export function formatDateTimeWithoutSecondsToLocal(date?: string): string {
  let dt;

  if (date) {
    dt = date;
  } else {
    dt = moment().toDate().getTime();
  }

  return moment.utc(dt).tz(TZ).format(MM_DD_YYYY_h_mm_FORMAT);
}

export function formatDateTimeWithSecondsToLocal(
  date?: Date,
  includeSeconds: boolean = true
): string {
  let dt;

  if (date) {
    dt = date;
  } else {
    dt = moment().toDate().getTime();
  }

  const m = moment.utc(dt).tz(TZ);
  return includeSeconds
    ? m.format(MM_DD_YYYY_h_mm_ss_FORMAT)
    : m.format(MM_DD_YYYY_h_mm_FORMAT);
}

export function formatDateToLocal(date: Date): string {
  return moment.utc(date).tz(TZ).format(MON_DD_YYYY_TIME_AMPM_FORMAT);
}

export function formatDateToLocalWithoutTime(date: Date): string {
  return moment.utc(date).tz(TZ).format(MON_DD_YYYY_FORMAT);
}

export function formatDateToLocalWithoutTimeForLineChart(date: Date): string {
  const parts = moment.utc(date).tz(TZ).format(MON_DD_YYYY_FORMAT).split('/');
  return `${parts[0]}/${parts[1]}/${parts[2]}`;
}

export function formatDateToLocalWithoutTimeYearFirst(date: Date): string {
  return moment.utc(date).tz(TZ).format(YYYY_MM_DD_FORMAT);
}

export function formatStringToLocalString(date: string): string {
  return moment.utc(new Date(date)).tz(TZ).format(MMMM_Do_YYYY_FORMAT);
}

export function formatStringToLocalDate(date: string): Date {
  return moment(date).toDate();
}

export function dateOccurredBefore(
  date: Date,
  amount: number = 3,
  unit: string
): boolean {
  const after = moment()
    .subtract(amount, unit === "day" ? "day" : "week")
    .toDate();
  return moment(date).isAfter(after);
}

/**
 * Method to convert a Date object represented as a string to a UTC date
 * (Prevents automatic timezone conversion)
 *
 * @param date
 * @returns
 */
export function getUTCDate(date: Date): Date {
  return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());
}
