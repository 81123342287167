import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
} from "@mui/material";
import { DateValidationError, PickerChangeHandlerContext } from "@mui/x-date-pickers";
import { Dispatch, SetStateAction, useRef, useState } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";

import DefaultDatePicker from "../../../components/DefaultDatePicker";
import React from "react";
import { TotwWeek } from "../../../api/types";
import { useClubContext } from "../../../contexts/ClubContext";
import { useCreateTotwWeekMutation } from "../../../api/mutations";
import { useTeamContext } from "../../../contexts/TeamContext";

export interface AddTotwWeekDialogProps {
  grouping: string;
  showAddTotwWeekDialog: boolean;
  setShowAddTotwWeekDialog: Dispatch<SetStateAction<boolean>>;
}

export default function AddTotwWeekDialog(props: AddTotwWeekDialogProps) {
  // CONTEXTS
  const { club } = useClubContext();
  const { team } = useTeamContext();

  // MUTATIONS
  const createTotwWeek = useCreateTotwWeekMutation();

  // REFS
  const formRef = useRef();

  // STATES
  const [label, setLabel] = useState<string>("");
  const [startDate, setStartDate] = useState<Date | undefined>(undefined);

  // FUNCTIONS
  const closeDialog = () => {
    clearStates();
    props.setShowAddTotwWeekDialog(false);
  };

  const clearStates = () => {
    setLabel("");
    setStartDate(undefined);
  };

  const handleSubmit = () => {
    const obj = {
      club: club,
      team: team,
      group: props.grouping,
      label,
      date: startDate,
    };

    createTotwWeek.mutate(obj as TotwWeek);
    closeDialog();
  };

  return (
    <Dialog
      open={props.showAddTotwWeekDialog}
      onClose={() => {
        closeDialog();
      }}
    >
      <DialogTitle>{`New week for ${props.grouping} Team of the Week`}</DialogTitle>
      <ValidatorForm
        ref={formRef.current}
        onSubmit={() => {
          handleSubmit();
        }}
      >
        <DialogContent>
          <Stack spacing={2}>
            <TextValidator
              fullWidth
              id="week-dialog-label-select"
              label="Week Label"
              name="label"
              value={label}
              validators={["required"]}
              errorMessages={["this field is required"]}
              variant="outlined"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setLabel(event.target.value)
              }
            />

            <DefaultDatePicker
              key="week-dialog-start-date"
              label="Start Date"
              value={startDate}
              onChange={(
                value: Date | null,
                context: PickerChangeHandlerContext<DateValidationError>
              ) => value && setStartDate(value)}
              disableFuture={false}
            />
          </Stack>
        </DialogContent>
        <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
          <Button onClick={closeDialog}>CANCEL</Button>
          <Button
            type="submit"
            disabled={
              label === "" || startDate === undefined || isNaN(+startDate)
            }
          >
            CREATE WEEK
          </Button>
        </DialogActions>
      </ValidatorForm>
    </Dialog>
  );
}
