import { SchedulingGame, SchedulingWeek } from "../api/types";
import { createContext, useContext, useState } from "react";

const defaultState = {
  selectedWeek: {
    id: -1,
    club: "",
    team: "",
    label: "",
    date: new Date(),
    week_games: [],
  },
  setSelectedWeek: (week: SchedulingWeek) => {},
  selectedGame: {
    id: -1,
    date_time: new Date(),
    home_team: "",
    home_team_rank: 0,
    away_team: "",
    away_team_rank: 0,
    scout: "",
    tv_network: "",
  },
  setSelectedGame: (game: SchedulingGame | undefined | null) => {},
};

interface SchedulingContextInterface {
  selectedWeek: SchedulingWeek | undefined;
  setSelectedWeek: (week: SchedulingWeek) => void;
  selectedGame: SchedulingGame | undefined | null;
  setSelectedGame: (game: SchedulingGame | undefined | null) => void;
}

export const SchedulingContext =
  createContext<SchedulingContextInterface>(defaultState);

export const SchedulingProvider = ({children}: {children: React.ReactNode}) => {
  const [selectedWeek, setSelectedWeek] = useState<SchedulingWeek>();
  const [selectedGame, setSelectedGame] = useState<
    SchedulingGame | undefined | null
  >();

  const value = {
    selectedWeek,
    setSelectedWeek,
    selectedGame,
    setSelectedGame,
  };

  return (
    <SchedulingContext.Provider value={value}>
      {children}
    </SchedulingContext.Provider>
  );
};

export const useSchedulingContext = () => useContext(SchedulingContext);
