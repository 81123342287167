import { Box, Grid, Typography } from "@mui/material";
import {
  useKitManagerActionLogs,
  useKitManagerActions,
  useKitManagerPlayers,
} from "../../api/queries";

import { APPBAR_HEIGHT_AS_NUM } from "../../app_bar/Toolbar";
import ActionsDialog from "./Dialogs/ActionsDialog";
import AddIcon from "@mui/icons-material/Add";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { KitManagerPlayer } from "../../api/types";
import PlayerDialog from "./Dialogs/PlayerDialog";
import autoTable from "jspdf-autotable"
import { formatDateToLocalWithoutTime } from "../../utils/dates";
import {jsPDF} from "jspdf"
import { useState } from "react";
import { useTeamContext } from "../../contexts/TeamContext";
import { useThemeContext } from "../../contexts/CustomThemeContext";
import { useWindowSizeContext } from "../../contexts/WindowSizeContext";

const KitManager = () => {
  // CONTEXTS
  const { height } = useWindowSizeContext();
  const { team } = useTeamContext();
  const { theme } = useThemeContext();

  // QUERIES
  const allPlayers = useKitManagerPlayers(team).data;
  const allActionLogs = useKitManagerActionLogs(team).data;
  const allActions = useKitManagerActions().data;

  // STATES
  const [showActionDialog, setShowActionDialog] = useState(false);
  const [selectedPlayer, setSelectedPlayer] = useState<KitManagerPlayer | null>(
    null
  );
  const [showPlayerDialog, setShowPlayerDialog] = useState(false);

  // FUNCTIONS
  const downloadPDF = () => {
    if (allPlayers) {
      let doc = new jsPDF();

      let data: any[] = []
      allPlayers.forEach((player, i) => {
        data.push([
          player.player_name,
          player.current_credits.toString()
        ])
      })

      autoTable(doc, {
        headStyles: { fillColor: "#ff0000" },
        head: [['player', 'credits']],
        margin: {top: 35},
        body: data
      })

      const image = new Image()
      image.src = require("../../assets/logos/cffc.png")
      image.onload = () => {
        doc.addImage(image, "png", 90, 5, 25, 25)

        let date = formatDateToLocalWithoutTime(new Date())
        doc.save('kit_manager_' + date)
      }

      
    }
  }

  return (
    <Box sx={{ color: theme.palette.primary.main }}>
      <PlayerDialog
        showPlayerDialog={showPlayerDialog}
        setShowPlayerDialog={setShowPlayerDialog}
        player={selectedPlayer}
        actionLogs={allActionLogs?.filter(
          (log) => log.kit_manager_player_id === selectedPlayer?.id
        )}
        actions={allActions}
        setPlayer={setSelectedPlayer}
      />
      <ActionsDialog
        showActionDialog={showActionDialog}
        setShowActionDialog={setShowActionDialog}
        player={selectedPlayer}
        setPlayer={setSelectedPlayer}
      />
      <Box sx={{ padding: "40px"}}>
        <Box onClick={downloadPDF} sx={{position: "absolute", right: 0, paddingRight: "40px", cursor: "pointer" }}>
          <FileDownloadIcon sx={{height: "40px", width: "40px"}}/>
        </Box>
        <Box
          sx={{
            textAlign: "center",
            paddingBottom: "40px",
          }}
        >
          <Typography variant="h3" sx={{ fontWeight: "bold" }}>
            Kit Manager
          </Typography>
        </Box>
        <Grid container sx={{ paddingBottom: "20px", fontWeight: "bold", fontSize: "12px" }}>
          <Grid item xs={5}>
            PLAYER
          </Grid>
          <Grid item xs={3} sx={{textAlign: "center"}}>
            CURRENT
          </Grid>
          <Grid item xs={2} sx={{textAlign: "center"}}>
            TOTAL
          </Grid>
          <Grid item xs={2} sx={{textAlign: "center"}}>
            ADD
          </Grid>
        </Grid>
        <Box
          sx={{
            height: height - APPBAR_HEIGHT_AS_NUM - 240,
            overflow: "scroll",
          }}
        >
          {allPlayers?.map((player) => {
            return (
              <Grid
                key={player.id}
                container
                alignItems="center"
                sx={{
                  paddingTop: "10px",
                  paddingBottom: "10px",
                  borderBottom: "1px solid lightgrey",
                }}
              >
                <Grid item xs={5}  sx={{ textDecoration: "underline"}}>
                  <Box
                    onClick={() => {
                      setSelectedPlayer(player);
                      setShowPlayerDialog(true);
                    }}
                    sx={{ cursor: "pointer" }}
                  >
                    <Typography>{player.player_name}</Typography>
                  </Box>
                </Grid>
                <Grid item xs={3} sx={{textAlign: "center"}}>
                  <Typography>{player.current_credits}</Typography>
                </Grid>
                <Grid item xs={2} sx={{textAlign: "center"}}>
                  <Typography>{player.total_credits}</Typography>
                </Grid>
                <Grid item xs={2} sx={{textAlign: "center"}}>
                  <Box
                    sx={{ cursor: "pointer" }}
                    onClick={() => {
                      setSelectedPlayer(player);
                      setShowActionDialog(true);
                    }}
                  >
                    <AddIcon sx={{ height: "25px", width: "25x" }} />
                  </Box>
                </Grid>
              </Grid>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};

export default KitManager;
