import {
  ADMIN_ROUTE,
  COACHES_CORNER_ROUTE,
  DATABASE_ROUTE,
  EQUIPMENT_ROUTE,
  IDP_ROUTE,
  LOGIN_CALLBACK_ROUTE,
  OPERATIONS_ROOT_ROUTE,
  PERFORMANCE_ROUTE,
  ROOT_ROUTE,
  SCOUTING_ROOT_ROUTE,
  SEARCH_ROUTE,
} from "./routes";
import { Route, Routes } from "react-router-dom";

import { LoginCallback } from "@okta/okta-react";
import {
  SUPERUSER,
} from "./constants";
import SecureRoute from "./components/SecureRoute";
import { lazy } from "react";
import { useUserContext } from "./contexts/UserContext";

const AdminPage = lazy(() => import("./pages/admin"));
const CoachesCornerPage = lazy(() => import("./pages/coaches_corner"));
const IDPPage = lazy(() => import("./pages/idp"));
const Database = lazy(() => import("./pages/database"));
const Equipment = lazy(() => import("./pages/equipment"));
const Operations = lazy(() => import("./pages/operations"));
const Performance = lazy(() => import("./pages/performance"));
const Scouting = lazy(() => import("./pages/scouting/"));
const Search = lazy(() => import("./pages/search"));

export default function AppRoutes() {
  const { HomePage, roles } = useUserContext();

  //   console.debug(roles);
  //   console.debug(permissions);

  return (
    <Routes>
      <Route path={LOGIN_CALLBACK_ROUTE} element={<LoginCallback />} />

      <Route path={ROOT_ROUTE} element={<SecureRoute />}>
        <Route path="" element={HomePage} />
      </Route>

      {roles.includes(SUPERUSER) && (
        <Route path={ADMIN_ROUTE}>
          <Route path="" element={<SecureRoute />}>
            <Route path="" element={<AdminPage />} />
          </Route>
        </Route>
      )}

      <Route path={COACHES_CORNER_ROUTE} element={<SecureRoute />}>
        <Route path="" element={<CoachesCornerPage />} />
      </Route>

      <Route path={IDP_ROUTE} element={<SecureRoute />}>
        <Route path="" element={<IDPPage />} />
      </Route>

      <Route path={DATABASE_ROUTE} element={<SecureRoute />}>
        <Route path="" element={<Database />} />
      </Route>

      <Route path={OPERATIONS_ROOT_ROUTE} element={<SecureRoute />}>
        <Route path="" element={<Operations />} />
      </Route>

      <Route path={SEARCH_ROUTE} element={<SecureRoute />}>
        <Route path="" element={<Search />} />
      </Route>
      
      <Route path={PERFORMANCE_ROUTE}>
        <Route path="" element={<SecureRoute />}>
          <Route path="" element={<Performance />} />
        </Route>
      </Route>
      
      <Route path={SCOUTING_ROOT_ROUTE} element={<SecureRoute />}>
        <Route path="" element={<Scouting />} />
        <Route path="depth" element={<Scouting />} />
        <Route path="reports" element={<Scouting />} />
        <Route path="shortlist" element={<Scouting />} />
        <Route path="totw" element={<Scouting />} />
        <Route path="scheduling" element={<Scouting />} />
      </Route>
    
      <Route path={EQUIPMENT_ROUTE} element={<SecureRoute />}>
        <Route path="" element={<Equipment />}></Route>
      </Route>

    </Routes>
  );
}
