import { CHICAGO_FIRE, LUGANO } from "../constants";
import { Theme, responsiveFontSizes } from "@mui/material/styles";
import { ThemeProvider, createTheme } from "@mui/material";
// Taken from https://techinscribed.com/building-react-app-using-material-ui-with-support-for-multiple-switchable-themes/
import { createContext, useContext } from "react";

import { cffcTheme } from "../themes/cffc";
import { luganoTheme } from "../themes/lugano";
import { useClubContext } from "./ClubContext";
import { useMemo } from "react";

interface iThemeContext {
  theme: Theme;
}

const themes: { [key: string]: Theme } = {
  [CHICAGO_FIRE]: cffcTheme,
  [LUGANO]: luganoTheme,
};

const defaultState = {
  theme: themes[CHICAGO_FIRE],
};

export const ThemeContext = createContext<iThemeContext>(defaultState);

export const CustomThemeProvider = ({children}: {children: React.ReactNode}) => {
  const { club } = useClubContext();

  const memoCffcTheme: Theme = useMemo(
    () => responsiveFontSizes(createTheme(cffcTheme)),
    []
  );

  const memoLuganoTheme: Theme = useMemo(
    () => responsiveFontSizes(createTheme(luganoTheme)),
    []
  );

  const theme = club === CHICAGO_FIRE ? memoCffcTheme : memoLuganoTheme;

  return (
    <ThemeProvider theme={theme}>
      <ThemeContext.Provider value={{ theme: theme }}>
        {children}
      </ThemeContext.Provider>
    </ThemeProvider>
  );
};

export const useThemeContext = () => useContext(ThemeContext);
