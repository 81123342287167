import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { Dispatch, SetStateAction, useState } from "react";
import {
  KitManagerAction,
  KitManagerActionLog,
  KitManagerPlayer,
} from "../../../api/types";

import CheckBoxAction from "../CheckboxAction";
import NumberAction from "../NumberAction";
import { useCreateKitManagerActionLogsMutation } from "../../../api/mutations";
import { useKitManagerActions } from "../../../api/queries";
import { useOktaAuthContext } from "../../../contexts/OktaAuthContext";
import { useTeamContext } from "../../../contexts/TeamContext";
import { useThemeContext } from "../../../contexts/CustomThemeContext";

export interface ActionsDialogProps {
  showActionDialog: boolean;
  setShowActionDialog: Dispatch<SetStateAction<boolean>>;
  player: KitManagerPlayer | null;
  setPlayer: Dispatch<SetStateAction<KitManagerPlayer | null>>;
}

export default function ActionsDialog(props: ActionsDialogProps) {
  // CONTEXTS
  const { email } = useOktaAuthContext();
  const { team } = useTeamContext();
  const { theme } = useThemeContext();

  // QUERIES AND MUTATIONS
  const allActions = useKitManagerActions().data;
  const createActionLog = useCreateKitManagerActionLogsMutation();

  // STATES
  const [actions, setActions] = useState<KitManagerAction[]>([]);
  const [totalCredits, setTotalCredits] = useState(0);

  // FUNCTIONS
  const closeDialog = () => {
    setTotalCredits(0);
    setActions([]);
    props.setShowActionDialog(false);
    props.setPlayer(null);
  };

  const submitActions = () => {
    // list of action_logs
    // eslint-disable-next-line array-callback-return
    let actionLogs = actions.map((action: KitManagerAction) => {
      if (props.player !== null) {
        let actionLog: KitManagerActionLog = {
          team: team,
          kit_manager_player_id: props.player.id,
          manager_action: action.action,
          recorded_by: email,
          action_worth: action.credits,
        };

        return actionLog;
      }
    });
    if (actionLogs.length > 0) {
      createActionLog.mutate(actionLogs);
    }

    closeDialog();
  };

  return (
    <Dialog
      open={props.showActionDialog}
      onClose={() => {
        closeDialog();
      }}
      fullWidth
    >
      <DialogTitle sx={{ color: theme.palette.primary.main }}>
        <Stack
          direction="row"
          justifyContent={"center"}
          sx={{ padding: "10px" }}
        >
          <Typography variant="h5">Add Action for&nbsp;</Typography>
          <Typography variant="h5" sx={{ fontWeight: "bold" }}>
            {props.player?.player_name}
          </Typography>
        </Stack>
      </DialogTitle>
      <DialogContent sx={{ color: theme.palette.primary.main }}>
        <Stack>
          {allActions?.map((action) => {
            return (
              (action.type === "checkbox" && (
                <CheckBoxAction
                  key={action.action}
                  action={action}
                  setTotalCredits={setTotalCredits}
                  player={props.player}
                  actions={actions}
                  setActions={setActions}
                />
              )) || (
                <NumberAction
                  key={action.action}
                  action={action}
                  setTotalCredits={setTotalCredits}
                  player={props.player}
                  actions={actions}
                  setActions={setActions}
                />
              )
            );
          })}
        </Stack>
      </DialogContent>
      <DialogActions
        sx={{
          color: theme.palette.primary.main,
        }}
      >
        <Stack width="550px">
          <Grid
            container
            sx={{
              borderTop: "1px solid lightgrey",
              paddingTop: "5px",
              paddingBottom: "5px",
              fontWeight: "bold",
            }}
          >
            <Grid item xs={6}>
              <Typography variant="h6" textAlign="right">
                Total Credits
              </Typography>
            </Grid>
            <Grid item xs={4}></Grid>
            <Grid item xs={2}>
              <Typography sx={{ fontWeight: "bold" }}>
                {totalCredits}
              </Typography>
            </Grid>
          </Grid>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              paddingTop: "20px",
            }}
          >
            <Button onClick={closeDialog}>CANCEL</Button>
            <Button onClick={submitActions} disabled={actions.length === 0}>
              SUBMIT
            </Button>
          </Box>
        </Stack>
      </DialogActions>
    </Dialog>
  );
}
