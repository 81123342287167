import { Dispatch, SetStateAction } from "react";

import { Grid } from "@mui/material";
import Pitch from "./Pitch";
import { ScoutedPlayer } from "../../api/types";

interface PitchShortListProps {
  grouping: string;
  groupingSelectEl: JSX.Element;
  setScoutedPlayerInContext: Dispatch<
    SetStateAction<ScoutedPlayer | null | undefined>
  >;
  setScoutedPlayerProfileModalOpen: Dispatch<SetStateAction<boolean>>;
}

function PitchShortList(props: PitchShortListProps) {
  return (
    <Grid container justifyContent="center">
      <Pitch
        editMode={false}
        grouping={props.grouping}
        groupingSelectEl={props.groupingSelectEl}
        setScoutedPlayerInContext={props.setScoutedPlayerInContext}
        setScoutedPlayerProfileModalOpen={
          props.setScoutedPlayerProfileModalOpen
        }
      />
    </Grid>
  );
}

export default PitchShortList;
