import { CHICAGO_FIRE_2, LUGANO } from "../../constants";

import TableauDashboard from "../../components/TableauDashboard";
import { useClubContext } from "../../contexts/ClubContext";

const WORKBOOK_URL = "https://prod-useast-b.online.tableau.com/t/waldenanalytics/views/CoachesCorner2_0/Team";

const CoachesCornerPage = () => {
    const { club } = useClubContext();

    let competition = "Major League Soccer";
    let season = "2024";

    if (club === CHICAGO_FIRE_2) {
        competition = "MLS Next Pro";
    } else if (club === LUGANO) {
        competition = "Super League"
        season = "2023/2024"
    }

    return (
        <TableauDashboard url={WORKBOOK_URL} params={{ Team: club, Competition: competition, Season: season }}/>
    );
}

export default CoachesCornerPage;
