import { createContext, useContext } from "react";

import { Notification } from "../api/types";
import { useNotifications } from "../api/queries";

export interface NotificationContextInterface {
  notifications: Notification[];
}

const defaultState = {
  notifications: [],
};

const NotificationsContext =
  createContext<NotificationContextInterface>(defaultState);

export const NotificationsProvider = ({children}: {children: React.ReactNode}) => {
  const notificationsResult = useNotifications();
  const notifications = notificationsResult?.data || ([] as Notification[]);

  return (
    <NotificationsContext.Provider value={{ notifications }}>
      {children}
    </NotificationsContext.Provider>
  );
};

export const useNotificationsContext = () => useContext(NotificationsContext);
