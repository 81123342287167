import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";
import { Dispatch, SetStateAction } from "react";

// import QueryScoutingReportDialog from "./QueryScoutingReportDialog";
import { SCOUTING_POSITIONS } from "../../../constants";
import { ScoutingReport } from "../../../api/types";

export interface TeamPlayersDialogProps {
  selectedTeam: string;
  setSelectedTeam: Dispatch<SetStateAction<string>>;
  scoutingReports: ScoutingReport[] | null | undefined;
}

export default function TeamPlayersDialog(props: TeamPlayersDialogProps) {
  // STATES
  // const [showScoutingReportDialog, setShowScoutingReportDialog] =
  //   useState<boolean>(false);
  // const [selectedScoutingReport, setSelectedScoutingReport] =
  //   useState<ScoutingReport>();

  // FUNCTIONS
  const closeDialog = () => {
    props.setSelectedTeam("");
  };

  return (
    <>
      {/* <QueryScoutingReportDialog
        open={showScoutingReportDialog}
        report={selectedScoutingReport}
        setOpen={setShowScoutingReportDialog}
        openMutateDialog={() => {}}
        readOnly={true}
      /> */}
      <Dialog
        open={props.selectedTeam !== ""}
        onClose={() => {
          closeDialog();
        }}
      >
        <DialogTitle>
          Players for{" "}
          <span style={{ textDecoration: "underline" }}>
            {props.selectedTeam}
          </span>
        </DialogTitle>
        <DialogContent>
          <Stack spacing={1}>
            {/* eslint-disable-next-line array-callback-return */}
            {props.scoutingReports?.map((sr) => {
              if (sr.club === props.selectedTeam) {
                return (
                  <Stack direction="row">
                    <Box
                      onClick={() => {
                        // setSelectedScoutingReport(sr);
                        // setShowScoutingReportDialog(true);
                      }}
                      sx={{ cursor: "pointer" }}
                    >
                      <Typography
                        style={{ fontWeight: 600, textDecoration: "underline" }}
                      >
                        {sr.first_name + " " + sr.last_name}
                      </Typography>
                    </Box>
                    <Typography style={{ paddingLeft: 5 }}>
                      {" | "}
                      {SCOUTING_POSITIONS[Number(sr.position) - 1]}
                    </Typography>
                  </Stack>
                );
              }
            })}
          </Stack>
        </DialogContent>
      </Dialog>
    </>
  );
}
