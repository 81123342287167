import { createContext, useContext, useState } from "react";

const defaultState = {
  group: "None",
  setGroup: (group: string) => {},
};

interface GroupContextInterface {
  group: string;
  setGroup: (group: string) => void;
}

export const GroupContext = createContext<GroupContextInterface>(defaultState);

export const GroupProvider = ({children}: {children: React.ReactNode}) => {
  const [group, setGroup] = useState("None");

  const value = { group, setGroup };

  return <GroupContext.Provider value={value}>{children}</GroupContext.Provider>;
};

export const useGroupContext = () => useContext(GroupContext);
