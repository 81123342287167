import { Dialog, DialogContent, useMediaQuery } from "@mui/material";
import { Dispatch, SetStateAction } from "react";

import CloseButton from "../../../components/CloseButton";
import { ScoutedPlayer } from "../../../api/types";
import ScoutedPlayerProfile from "../ScoutedPlayerProfile";
import { useTheme } from "@mui/material/styles";

export interface ScoutedPlayerProfileModalProps {
  handleClose: () => void;
  open: boolean;
  scoutedPlayer: ScoutedPlayer;
  setScoutedPlayerInContext?: Dispatch<
    SetStateAction<ScoutedPlayer | null | undefined>
  >;
}

export default function ScoutedPlayerProfileDialog(
  props: ScoutedPlayerProfileModalProps
) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Dialog
      onClose={props.handleClose}
      open={props.open}
      PaperProps={{
        style: {
          borderRadius: fullScreen ? 0 : 5,
          maxWidth: "100%",
        },
      }}
      fullScreen={fullScreen}
      sx={{
        zIndex: theme.zIndex.appBar + 1,
      }}
      fullWidth
    >
      <CloseButton
        position="absolute"
        top={5}
        left={5}
        onClick={() => props.handleClose()}
      />

      <DialogContent sx={{ padding: fullScreen ? 1 : 2 }}>
        {props.scoutedPlayer && (
          <ScoutedPlayerProfile
            key={`${props.scoutedPlayer.id}-scouted-player-profile`}
            scoutedPlayer={props.scoutedPlayer}
            setScoutedPlayerInContext={props.setScoutedPlayerInContext}
          />
        )}
      </DialogContent>
    </Dialog>
  );
}
