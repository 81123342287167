import { Box, List, Menu } from "@mui/material";
import { Dispatch, SetStateAction } from "react";

import ActionsMenuItem from "../../components/ActionsMenuItem";
import { ScoutedPlayer } from "../../api/types";
import { useScoutingReportDialogContext } from "../../contexts/ScoutingReportDialogContext";

export interface ScoutedPlayerProfileActionsMenuProps {
  anchorEl: Element | null;
  open: boolean;
  scoutedPlayer: ScoutedPlayer;
  scoutingReportsDrawerOpen: boolean;
  handleActionMenuClose: (event: React.MouseEvent<HTMLButtonElement>) => void;
  // setCreateListDialogOpen: Dispatch<SetStateAction<boolean>>;
  // setCreateNoteModalOpen: Dispatch<SetStateAction<boolean>>;
  // setNotesDrawerOpen: Dispatch<SetStateAction<boolean>>;
  setScoutingReportsDrawerOpen: Dispatch<SetStateAction<boolean>>;
  // toggleNotesDrawer: (open: boolean) => Promise<void>;
  toggleScoutingReportsDrawer: (open: boolean) => Promise<void>;
}

const ScoutedPlayerProfileActionsMenu = (
  props: ScoutedPlayerProfileActionsMenuProps
) => {
  const { handleCreateReportClick } = useScoutingReportDialogContext();

  return (
    <Box key={`${props.scoutedPlayer.id}-list-box`}>
      <Menu
        key={`${props.scoutedPlayer.id}-menu`}
        aria-labelledby="player-actions-menu"
        anchorEl={props.anchorEl}
        open={props.open}
        onClose={props.handleActionMenuClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <List key={`${props.scoutedPlayer.id}-menu-list`}>
          {/* CREATE SCOUTING REPORT */}
          {props.scoutedPlayer.id && (
            <ActionsMenuItem
              uniqueKey={`${props.scoutedPlayer.id}-create-scouting-report`}
              text="Create Scouting Report"
              onClick={(event: any) => {
                props.handleActionMenuClose(event);
                handleCreateReportClick(props.scoutedPlayer);
              }}
            />
          )}

          {/* TOGGLE SCOUTING REPORTS DRAWER */}
          {/* {props.scoutedPlayer.id &&
            props.scoutedPlayer.scouting_report_ids && (
              <ActionsMenuItem
                uniqueKey={`${props.scoutedPlayer.id}-scouting-reports`}
                text="View Scouting Reports"
                onClick={(event: any) => {
                  props.handleActionMenuClose(event);
                  props.toggleScoutingReportsDrawer(
                    !props.scoutingReportsDrawerOpen
                  );
                }}
              />
            )} */}

          {/* <ActionsMenuItem
            uniqueKey={`${props.player.id}-create-note`}
            text="Create Note"
            onClick={(event: any) => {
              props.handleActionMenuClose(event);
              props.setCreateNoteModalOpen(true);
            }}
          /> */}

          {/* <ActionsMenuItem
            uniqueKey={`${props.player.id}-view-notes`}
            text="View Notes"
            onClick={(event: any) => {
              props.handleActionMenuClose(event);
              props.toggleNotesDrawer(true);
            }}
          /> */}
        </List>
      </Menu>
    </Box>
  );
};

export default ScoutedPlayerProfileActionsMenu;
