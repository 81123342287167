import { TreeItem } from "./TreeItem";
import {
  useNavigate,
} from "react-router-dom";

export interface ListItemLinkProps {
  label: string;
  to: string;
  nested?: boolean;
}

export default function TreeItemLink(props: ListItemLinkProps) {
  const navigate = useNavigate();

  return (
    <TreeItem itemId={props.label} label={props.label} onClick={() => navigate(props.to)} />
  );
}
