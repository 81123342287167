import { Dialog, DialogContent, Stack } from "@mui/material";
import { useEffect, useState } from "react";

import moment from "moment";
import { useOktaAuthContext } from "../contexts/OktaAuthContext";

export default function GifDialog() {
  const { email, name } = useOktaAuthContext();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [message, setMessage] = useState<string>();
  const [displayGif, setDisplayGif] = useState<boolean>(false);
  const displayCutoff = "20231114";

  useEffect(() => {
    const displayGifFor = [
        "kminkus@chicagofirefc.com",
        "dportugal@chicagofirefc.com",
        "rmadden@chicagofirefc.com",
        "ggreenberg@chicagofirefc.com",
    ];
    const today = new Date();
    // console.debug(today);
    // console.debug(moment(today).isSameOrBefore(displayCutoff));
    // console.debug(displayBirthdayGif);

    if (
      displayGifFor.includes(email) &&
      moment(today).isSame(displayCutoff, "day") &&
      sessionStorage.getItem("display-gif") === null
    ) {
      setDisplayGif(true);
      // setMessage(`TO ${name.split(" ")[0].toUpperCase()}`);
      sessionStorage.setItem("display-gif", "false");
    }
  }, [email, name]);

  return (
    <>
      <Dialog
        fullWidth
        open={displayGif}
        onClose={() => {
          setDisplayGif(false);
        }}
      >
        {/* <Fireworks
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
          }}
        /> */}
        {/* <DialogTitle
          sx={{ textAlign: "center", fontWeight: "bold", padding: 5 }}
          variant="h4"
        >
          {message}
        </DialogTitle> */}
        <DialogContent>
          <Stack direction="row" justifyContent="center">
            <img src="/logos/lewa-gif.gif" alt={message} />
          </Stack>
        </DialogContent>
      </Dialog>
    </>
  );
}
